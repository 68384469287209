import React from 'react'

const SearchIcon = (props) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    {...props}
  >
    <path
      stroke="#9C9FAA"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.883 10.378a4.495 4.495 0 1 0 0-8.99 4.495 4.495 0 0 0 0 8.99ZM9.008 9.242 10.77 11"
    />
  </svg>
  )
}

export default SearchIcon