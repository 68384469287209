import React, { useEffect, useState, useCallback } from "react";
import "./DetailPagesStepper.scss";

const DetailPagesStepper = ({ steps }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = useCallback(() => {
    const header = document.querySelector(".progress-container");
    const sections = document.querySelectorAll("section");
    const sticky = header.offsetTop;

    setIsSticky(window.scrollY > sticky);

    let currentStep = 1;
    sections.forEach((section, index) => {
      if (section.getBoundingClientRect().top <= window.innerHeight / 2) {
        currentStep = index + 1;
      }
    });

    setActiveStep(currentStep);
  }, []);

  useEffect(() => {
    const throttledHandleScroll = () => {
      requestAnimationFrame(handleScroll);
    };

    window.addEventListener("scroll", throttledHandleScroll);
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, [handleScroll]);

  return (
    <div className={`progress-container ${isSticky ? "sticky" : ""}`}>
      <div className={`progress-line ${isSticky ? "sticky-i" : ""}`}></div>
      <div className="steps">
        {steps.map((step, index) => (
          <div key={index} className={`step ${activeStep > index ? "active-i" : ""}`}>
            <div className={`step-outline ${activeStep > index ? "active-i" : ""}`}>
              <div className={`step-inner ${activeStep > index ? "active-i" : ""}`}></div>
            </div>
            {step}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DetailPagesStepper;
