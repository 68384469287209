import React from 'react'
import ReadableTable from '../readableTable/ReadableTable'
import { formatDate } from '../../utils/util'
import { formateDate } from '../../Utility'

const ExtensionRevisedWorkPlan = ({ workPlan }) => {
  return (
    <div className='col-md-12 bg-white shadow-sm mb-2 p-4 mt-3 rounded extension-detail'>
      <ReadableTable
        headers={[
          "Work Type",
          "Description",
          "Start Date",
          "End Date",
          "Responsibility",
          "Means Of Verification",
         
        ]}
        data={(workPlan ?? [])?.map(({workType, description, startDate, endDate, responsible, verificationMean }) => ({
            workType,
            description,
            startDate: formatDate(startDate),
            endDate :formatDate(endDate),
            responsible,
            verificationMean,
         
        }))}
        label={"Revised Work Plan"}
      />

      {/* id: 'abf91635-83b9-4d8a-b729-ec34eb7c4e37',
      workType: '1',
      description: 'teste',
      startDate: '2024-05-21T00:00:00',
      endDate: '2024-09-21T00:00:00',
      responsible: 'test',
      verificationMeanId: '4f36506a-e9b1-4e69-ad00-c6edfdf76d2b',
      verificationMean: 'Test',
      workPlanSubActivities: [] */}
    </div>
  )
}

export default ExtensionRevisedWorkPlan