import React from "react";

const SupportingIcons = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path
        fill="#231f20"
        {...props}
        d="M103.7 202.3c0 5.6 4.6 10.2 10.2 10.2h211.8c5.6 0 10.2-4.6 10.2-10.2 0-5.7-4.6-10.2-10.2-10.2H113.9c-5.6 0-10.2 4.6-10.2 10.2zm222 47.3H113.9c-5.6 0-10.2 4.6-10.2 10.2s4.6 10.2 10.2 10.2H325.7c5.6 0 10.2-4.6 10.2-10.2s-4.6-10.2-10.2-10.2zm0 57.6H113.9c-5.6 0-10.2 4.6-10.2 10.2s4.6 10.2 10.2 10.2h211.8c5.6 0 10.2-4.6 10.2-10.2s-4.6-10.2-10.2-10.2zm0 57.6H113.9c-5.6 0-10.2 4.6-10.2 10.2s4.6 10.2 10.2 10.2h211.8c5.6 0 10.2-4.6 10.2-10.2 0-5.7-4.6-10.2-10.2-10.2zm0 57.5H113.9c-5.6 0-10.2 4.6-10.2 10.2s4.6 10.2 10.2 10.2h211.8c5.6 0 10.2-4.6 10.2-10.2s-4.6-10.2-10.2-10.2zM113.9 150h144.6c5.6 0 10.2-4.6 10.2-10.2s-4.6-10.2-10.2-10.2H113.9c-5.6 0-10.2 4.6-10.2 10.2s4.6 10.2 10.2 10.2z"
      />
      <path
        fill="#231f20"
        {...props}
        d="m391.4 136.6-.3-.9c-.4-.8-.8-1.6-1.3-2.4-.1-.2-.2-.3-.3-.5-.6-.9-1.3-1.8-2.1-2.6l-59.3-59.3c-.8-.8-1.6-1.5-2.6-2.1-.2-.2-.5-.3-.8-.4-.7-.4-1.4-.8-2.1-1.1-.4-.1-.7-.2-1.1-.3-.7-.2-1.3-.5-2.1-.6-1.1-.2-2.2-.3-3.4-.3H68.8c-9.4 0-16.9 7.6-16.9 16.9v411.9c0 9.4 7.6 16.9 16.9 16.9l306.7.1c4.5 0 8.8-1.8 12-5s5-7.5 5-12V142.2c0-1.1-.1-2.2-.3-3.3-.3-.8-.6-1.6-.8-2.3zm-33 341.5L85.7 478V100l213.4-.1v42.2c0 9.3 7.6 16.9 16.9 16.9l42.4.1v319z"
      />
      <path
        fill="#231f20"
        {...props}
        d="M459.8 72.9c-.2-.8-.4-1.5-.7-2.3l-.3-.9c-.4-.8-.8-1.6-1.3-2.4-.1-.2-.2-.3-.3-.5-.6-.9-1.3-1.8-2.1-2.6L395.9 5c-.8-.8-1.6-1.5-2.6-2.1-.2-.2-.5-.3-.8-.4-.7-.4-1.4-.8-2.1-1.1-.4-.1-.7-.2-1.1-.3-.7-.2-1.3-.5-2.1-.6-1-.4-2.2-.5-3.3-.5L136.6.1c-9.4 0-16.9 7.6-16.9 16.9v33.8h33.9V34l213.4-.1v42.2c0 9.3 7.6 16.9 16.9 16.9l42.4.1v319h-18.9V446h35.8c4.5 0 8.8-1.8 12-5s5-7.5 5-12V76.3c0-1.2-.1-2.3-.4-3.4z"
      />
    </svg>
  );
};

export default SupportingIcons;
