import React from "react";
import "./dashboardcard.scss";
import { formatCurrency } from "../../Utility";

const DashboardCard = ({title,value,icon,percentage,bgColor="white",...props}) => {
  return (
    <div className="container-fluid  p-0">
     <div className="row-container">
     <div className={`dashboard-card col-md-12 `} style={{backgroundColor:bgColor}}>
      <div className="heading fw-6">{title}</div>
      <div className="data">
        <div className="data-value col-md-9">{formatCurrency(value)}</div>
        <div className="data-icon col-md-2 "><img src={icon} alt="vector" /></div>
      </div>
      {/* <div className="footer">
        <span className="text">Since Last Week</span> <span className="value">{percentage}</span>
      </div> */}
    </div>
     </div>
    </div>
  );
};

export default DashboardCard;
