import { useDispatch } from "react-redux";
import {
  ACTIVITY_STATUS_TYPE,
  COMPLAINT_STATUS,
  APPLICATIONS_STATUS,
} from "./Constants";
import { getRecords } from "./redux/reducers";
import { cancelled } from "redux-saga/effects";
import { baseUrl } from "./redux";
import axios from "./redux/sagas/api-client";
import { loadUserState } from "./utils/localStorage";
import { getFieldsByCategory } from "./utils/util";
import { applicationFields } from "./assets/fields/applicationFields";
import { signupAndOrganizationFields } from "./assets/fields/signupAndOrganizationFields";

export function getStatusStyle(status = "") {
  switch (status.toLowerCase()) {
    case COMPLAINT_STATUS.PENDING.toLowerCase() || COMPLAINT_STATUS.FALSE:
      return "text-warning";
      break;

    case COMPLAINT_STATUS.CLOSED.toLowerCase() || COMPLAINT_STATUS.TRUE:
      return "text-success";
      break;

    default:
      return "text-danger";
      break;
  }
}

export function getActivityStatusStyle(status) {
  switch (status) {
    case ACTIVITY_STATUS_TYPE.PENDING:
      return "text-info";
      break;

    case ACTIVITY_STATUS_TYPE.COMPLETED:
      return "text-success";
      break;

    default:
      return "text-warning";
      break;
  }
}

export const getFormatedDate = (date = "") => {
  return new Date(date)?.toLocaleDateString();
};

export const formateDate = (date = "") => {
  return date;
  // return new Date(date)?.toISOString()?.split('T')[0]
};

export const getFacebookFormattedDate = (date = "") => {
  const dateX = new Date(date);
  const option = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  const formattedDate = dateX.toLocaleDateString("en-US", option);
  return formattedDate;
};

// export const getInputFormatedDate = (date = '') => {
//   let _date = new Date(date);
//   let compiledDate = new Date(_date.getFullYear(), _date.getMonth(), _date.getDate() - 0);
//   return `${compiledDate.getDate()}-${compiledDate.getMonth() + 1}-${compiledDate.getFullYear()}`
//   // return `${compiledDate.getFullYear()}-${compiledDate.getMonth() + 1}-${compiledDate.getDate()}`
// }

export function shortId() {
  return "_" + Math.random().toString(36).substr(2, 9);
}

export function randomColor() {
  return `hsl(${Math.floor(Math.random() * 360)}, 95%, 90%)`;
}

export const getStatusBadge = (status = "") => {
  const color = {
    Ongoing: "#FFF9E3",
    Pending: "#FFF9E3",
    Under_Review: "#FFF9E3",
    Approved: "#EDF6FF",
    ADC_Approved: "#00ff00",
    Active: "#EDF6FF",
    Rejected: "#FDF1F0",
    Blocked: "#FDF1F0",

    // ONGOING: "Ongoing",
    // ACTIVE: "Active",
    // APPROVED: "Approved",
    // BLOCKED: "Blocked",
    // PENDING: "Pending",
    // REJECTED: "Rejected",
    // UNDER_REVIEW: "Under Review",
    // REVISION_REQUIRED: "Revision Required",
  };
  switch (status) {
    case APPLICATIONS_STATUS.ONGOING:
      return (
        <div
          style={{
            backgroundColor: color[status],
            fontSize: "13px",
            gap: "4px",
          }}
          className="text-warning px-2 py-1 rounded-pill d-inline-flex align-items-center"
        >
          <div
            style={{
              width: "4px",
              height: "4px",
            }}
            className="rounded-pill bg-warning"
          ></div>
          {status}
        </div>
      );
      break;

    case APPLICATIONS_STATUS.PENDING:
      return (
        <div
          style={{
            backgroundColor: color[status],
            fontSize: "13px",
            gap: "4px",
          }}
          className="text-warning px-2 py-1 rounded-pill d-inline-flex align-items-center"
        >
          <div
            style={{
              width: "4px",
              height: "4px",
            }}
            className="rounded-pill bg-warning"
          ></div>
          {status}
        </div>
      );
      break;

    case APPLICATIONS_STATUS.ACTIVE:
      return (
        <div
          style={{
            backgroundColor: color[status],
            fontSize: "13px",
            gap: "4px",
          }}
          className="px-2 py-1 rounded-pill text-success d-inline-flex align-items-center"
        >
          <div
            style={{
              width: "4px",
              height: "4px",
            }}
            className="rounded-pill bg-success"
          ></div>
          {status}
        </div>
      );
      break;

    case APPLICATIONS_STATUS.APPROVED:
      return (
        <div
          style={{
            backgroundColor: color[status],
            fontSize: "13px",
            gap: "4px",
          }}
          className="px-2 py-1 rounded-pill text-success d-inline-flex align-items-center"
        >
          <div
            style={{
              width: "4px",
              height: "4px",
            }}
            className="rounded-pill bg-success"
          ></div>
          {status}
        </div>
      );
      break;

    case APPLICATIONS_STATUS.ADC_APPROVED:
      return (
        <div
          style={{
            backgroundColor: color[status],
            fontSize: "13px",
            gap: "4px",
          }}
          className="px-2 py-1 rounded-pill text-success d-inline-flex align-items-center"
        >
          <div
            style={{
              width: "4px",
              height: "4px",
            }}
            className="rounded-pill bg-success"
          ></div>
          {status}
        </div>
      );
      break;

    case APPLICATIONS_STATUS.REJECTED:
      return (
        <div
          style={{
            backgroundColor: color[status],
            fontSize: "13px",
            gap: "4px",
          }}
          className="px-2 py-1 rounded-pill text-danger d-inline-flex align-items-center"
        >
          <div
            style={{
              width: "4px",
              height: "4px",
            }}
            className="rounded-pill bg-danger"
          ></div>
          {status}
        </div>
      );
      break;

    case APPLICATIONS_STATUS.BLOCKED:
      return (
        <div
          style={{
            backgroundColor: color[status],
            fontSize: "13px",
            gap: "4px",
          }}
          className="px-2 py-1 rounded-pill text-danger d-inline-flex align-items-center"
        >
          <div
            style={{
              width: "4px",
              height: "4px",
            }}
            className="rounded-pill bg-danger"
          ></div>
          {status}
        </div>
      );
      break;
  }
};

export const getNocProjecyStatusBadge = (status = "") => {
  const color = {
    Ongoing: "yellow",
    Completed: "green",
    cancelled: "red",
  };

  const STATUS = {
    ONGOING: "On Going",
    COMPLETED: "Completed",
    CANCELLED: "Cancelled",
  };
  switch (status) {
    case STATUS.ONGOING:
      return (
        <div
          style={{
            backgroundColor: color[status],
            fontSize: "13px",
            gap: "4px",
          }}
          className="text-warning px-2 py-1 rounded-pill d-inline-flex align-items-center"
        >
          <div
            style={{
              width: "4px",
              height: "4px",
            }}
            className="rounded-pill bg-warning"
          ></div>
          {status}
        </div>
      );
      break;

    case STATUS.CANCELLED:
      return (
        <div
          style={{
            backgroundColor: color[status],
            fontSize: "13px",
            gap: "4px",
          }}
          className="text-warning px-2 py-1 rounded-pill d-inline-flex align-items-center"
        >
          <div
            style={{
              width: "4px",
              height: "4px",
            }}
            className="rounded-pill bg-warning"
          ></div>
          {status}
        </div>
      );
      break;

    case STATUS.COMPLETED:
      return (
        <div
          style={{
            backgroundColor: color[status],
            fontSize: "13px",
            gap: "4px",
          }}
          className="px-2 py-1 rounded-pill text-success d-inline-flex align-items-center"
        >
          <div
            style={{
              width: "4px",
              height: "4px",
            }}
            className="rounded-pill bg-success"
          ></div>
          {status}
        </div>
      );
      break;
  }
};

export const getStatusText = (status = "") => {
  switch (status) {
    case APPLICATIONS_STATUS.UNDER_REVIEW:
      return <div style={{ color: "#1B7ED9" }}>{status}</div>;
    case APPLICATIONS_STATUS.REVISION_REQUIRED:
      return <div className="text-warning">{status}</div>;
    case APPLICATIONS_STATUS.REJECTED:
      return <div className="text-danger">{status}</div>;
    case APPLICATIONS_STATUS.APPROVED:
      return <div className="text-success">{status}</div>;
    case APPLICATIONS_STATUS.ADC_APPROVED:
      return <div className="text-success">{status}</div>;
    case APPLICATIONS_STATUS.ONGOING:
      return <div className="text-warning">{status}</div>;
    case APPLICATIONS_STATUS.ACTIVE:
      return <div className="text-success">{status}</div>;
    case APPLICATIONS_STATUS.BLOCKED:
      return <div className="text-danger">{status}</div>;
    default:
      return <div className="text-danger">{status}</div>;
  }
};

export const truncateFileName = (fileName, maxLength = 20) => {
  if (fileName.length <= maxLength) {
    return fileName;
  }
  const extension = fileName.slice(fileName.lastIndexOf("."));
  const truncatedName =
    fileName.slice(0, maxLength - extension.length - 3) + "...";
  return truncatedName + extension;
};

function setNestedValue(obj, path, value) {
  let keys = path.split(".");
  while (keys.length > 1) {
    let key = keys.shift();
    // Check for array index notation, e.g., "userOrganizationContacts[0]"
    let match = key.match(/(\w+)\[(\d+)\]/);
    if (match) {
      let arrayKey = match[1];
      let index = parseInt(match[2], 10);
      if (!obj[arrayKey]) obj[arrayKey] = [];
      if (!obj[arrayKey][index]) obj[arrayKey][index] = {};
      obj = obj[arrayKey][index];
    } else {
      if (!obj[key]) obj[key] = {};
      obj = obj[key];
    }
  }
  obj[keys[0]] = value;
}

export function transformInput(inputObject) {
  let outputObject = {};

  for (let key in inputObject) {
    if (inputObject.hasOwnProperty(key)) {
      let item = inputObject[key];

      // Preserve direct string or boolean values
      if (typeof item === "string" || typeof item === "boolean") {
        setNestedValue(outputObject, key, item);
        continue;
      }

      let value = item.value;

      // Special handling for "async-dropdown"
      if (
        item.inputType === "async-dropdown" &&
        item.value &&
        typeof item.value === "object"
      ) {
        value = item.value.value;
      }

      // Special handling for "file"
      if (item.inputType === "file" && item.fileObject) {
        value = item.fileObject;
      }

      // Special handling for specific structure with label and value
      if (
        item.value &&
        typeof item.value === "object" &&
        "value" in item.value &&
        "label" in item.value
      ) {
        value = item.value.value;
      }

      // Special handling for arrays of objects
      if (Array.isArray(item.value)) {
        value = item.value.map((subItem) => {
          let transformedSubItem = {};
          for (let subKey in subItem) {
            if (subItem.hasOwnProperty(subKey)) {
              let subValue = subItem[subKey].value;

              // Preserve direct string or boolean values in sub-items
              if (
                typeof subItem[subKey] === "string" ||
                typeof subItem[subKey] === "boolean"
              ) {
                transformedSubItem[subKey] = subItem[subKey];
                continue;
              }

              // Special handling for "async-dropdown" in sub-items
              if (
                subItem[subKey].inputType === "async-dropdown" &&
                subItem[subKey].value &&
                typeof subItem[subKey].value === "object"
              ) {
                subValue = subItem[subKey].value.value;
              }

              // Special handling for "file" in sub-items
              if (
                subItem[subKey].inputType === "file" &&
                subItem[subKey].fileObject
              ) {
                subValue = subItem[subKey].fileObject;
              }

              // Special handling for specific structure with label and value in sub-items
              if (
                subItem[subKey].value &&
                typeof subItem[subKey].value === "object" &&
                "value" in subItem[subKey].value &&
                "label" in subItem[subKey].value
              ) {
                subValue = subItem[subKey].value.value;
              }

              transformedSubItem[subKey] = subValue;
            }
          }
          return transformedSubItem;
        });
      }

      setNestedValue(outputObject, key, value);
    }
  }

  return outputObject;
}

// export const handleDownloadFile = (dispatch, filepath) => {
//   dispatch({
//     type: getRecords.type,
//     payload: {
//       url: `/FileUpload/download`,
//       params: { filePath: filepath },
//     },
//   });
// };

export const handleDownloadFile = async (e, filepath) => {
  e.preventDefault();
  let userState = loadUserState();
  const token = userState?.token;

  try {
    const response = await axios.get(
      `${baseUrl}/api/FileUpload/download?filePath=${encodeURIComponent(
        filepath
      )}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob", // Ensure the response is treated as a blob
      }
    );

    const url = URL.createObjectURL(response.data);
    const a = document.createElement("a");
    a.href = url;
    a.download = filepath.split("/").pop() || "Kpk-Noc-Document"; // Default to 'download' if no documentName is provided
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  } catch (error) {
    // console.error("Error downloading file:", error);
  }
};

export const fixFloatWidth = (value) => {
  return parseFloat(value).toFixed(4);
};

// src/utils/formatCurrency.js
export const formatCurrency = (value) => {
  // Ensure the value is a number
  const numberValue = parseFloat(value);
  if (isNaN(numberValue)) return "";

  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(numberValue);
};

const projectSummary = [
  ...getFieldsByCategory(applicationFields, "projectInformation"),
  ...getFieldsByCategory(applicationFields, "fundDetailsInNGO"),
  ...getFieldsByCategory(applicationFields, "proposedTimeFrame"),
  ...getFieldsByCategory(applicationFields, "summaryOfProjectCost"),
];

const projectDetail = [
  ...getFieldsByCategory(applicationFields, "projectDetail"),
];

const projectSupportingDoc = [
  ...getFieldsByCategory(applicationFields, "agreementDates"),
];
export const getStepFields = {
  0: projectSummary,
  2: projectDetail,
  3: projectSupportingDoc
};

export const signupValidationFields = [
  ...getFieldsByCategory(signupAndOrganizationFields, "personalInformation"),
  ...getFieldsByCategory(
    signupAndOrganizationFields,
    "organizationInformation"
  ),
  ...getFieldsByCategory(signupAndOrganizationFields, "essentialContacts"),
  ...getFieldsByCategory(
    signupAndOrganizationFields,
    "organizationRegistrationDetails"
  ),
  ...getFieldsByCategory(signupAndOrganizationFields, "informationOfOffice"),
];
