import React from "react";
import "./HeaderProfile.scss";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { saveUserState } from "../../utils/localStorage";

import { OverlayTrigger, Popover } from "react-bootstrap";
import { baseUrl } from "../../redux";

const HeaderProfile = ({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { name, profilePicture = "", ...data } = useSelector((state) => state.setting.user);
  function handleChangeValue(value) {
    if (value == "logout") {
      dispatch({ type: "setting/setUser", payload: { logout: true } });
      saveUserState({});
      navigate("/");
    } else {
      navigate(`user/${value}`);
    }
  }
  return (
    <>
      <div className="notif-wrapper">
        <div className="icon cursor-pointer">
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom"
            overlay={
              <Popover
                id={`popover-contained popover-positioned-bottom`}
                style={{ minWidth: 280 }}
              >
                <Popover.Body className="h-25">
                  <div
                    className="link-with-icon d-flex align-items-center"
                    onClick={() => handleChangeValue("Detail")}
                  >
                    <span>
                      <Icon.Person size={15} />
                    </span>
                    <span className="ps-2">Profile</span>
                  </div>
                  {/* <div
                    className="link-with-icon d-flex align-items-center"
                    onClick={() => handleChangeValue(`${id}`)}
                  >
                    <span>
                      <Icon.People size={15} />
                    </span>
                    <span className="ps-2">Account</span>
                  </div> */}
                  <div
                    className="link-with-icon d-flex align-items-center"
                    onClick={() => handleChangeValue("changePassword")}
                  >
                    <span>
                      <Icon.Key size={15} />
                    </span>
                    <span className="ps-2">Change Password</span>
                  </div>
                  <div
                    className="link-with-icon d-flex align-items-center"
                    onClick={() => handleChangeValue("logout")}
                  >
                    <span>
                      <Icon.Power size={15} />
                    </span>
                    <span className="ps-2">Logout</span>
                  </div>
                </Popover.Body>
              </Popover>
            }
          >
            <div className="header-profile">
              <div className="profile-img-bg">
                <img
                  src={
                    profilePicture
                      ? `${baseUrl}/${profilePicture}`
                      : require("../../assets/profile.png")
                  }
                />
                <div className="d-flex flex-row gap-2 justify-content-center"  >
                <p>
                  {name ?? "Loading"}
                </p>
                <p style={{fontSize:"12px"}}>{`(${data?.role ?? "Loading..."})` }</p>
                </div>
              </div>
            </div>
          </OverlayTrigger>
          {/* <span className="badge badge-pill bg-danger text-light">New</span> */}
        </div>
      </div>
    </>
  );
};

export default HeaderProfile;
