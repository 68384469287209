import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import WithPageHeader from "../../components/ui/withPageHeader";
import { applicationsColumns } from "../../assets/columns/ApplicationsColumns";
import Table from "../../components/table/Table";
import * as Icon from "react-bootstrap-icons";

import { customStyles } from "../../assets/styles/CustomStyle";
import FilterIcon from "../../assets/images/Filter.svg";
import application_btn from "../../assets/images/plus.svg";
import Modal from "react-modal";
import "./Applications.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  clearForm,
  createRecord,
  getRecords,
  resetData,
} from "../../redux/reducers";
import DropdownButton from "../../components/dropdown-button/DropdownButton";
import { CheckLg, PencilSquare, Trash, Trash3 } from "react-bootstrap-icons";
import Loader from "../../components/loader/loader";
import { FaBan, FaCheck } from "react-icons/fa";
import { Alert, canView, getRoleAsArray } from "../../utils/util";
import { deleteRecord } from "../../redux/reducers/recordSlice";
import { confirmAlert } from "react-confirm-alert";
import ToolTip from "../../components/tooltip/Tooltip";

const Applications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { applicationList } = useSelector((state) => state.records?.current);
  const { loading, created, searchText, creating, error } = useSelector(
    (state) => state.records
  );
  console.log("🚀 ~ Applications ~ error:", error);
  const { lookups } = useSelector((state) => state.form);
  const { applicationStatus = [] } = lookups;
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [filteredData, setFilteredData] = useState(applicationList?.data ?? []);
  const [perPage, setPerPage] = useState(applicationList?.pageSize ?? 10);
  const [page, setPage] = useState(applicationList?.pageNumber ?? 1);
  const [modalShow, setModalShow] = useState(false);
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const [errors, setErrors] = useState({});
  const [selectedRow, setSelectedRow] = useState({});

  const { role } = useSelector((state) => state.setting?.user);
  const roles = role && getRoleAsArray(role);

  useEffect(() => {
    if (error?.title) {
      setModalShow(false);
    }
  }, [error]);

  useEffect(() => {
    fetchDataFromSearch();
  }, [searchText?.generalSearch, role]);

  useEffect(() => {
    handlePaginationPerpage();
  }, [perPage, page, role]);

  useEffect(() => {
    if (created) {
      handlePaginationPerpage();
      setModalShow(false);
      setSelectedRow({});
      setErrors({});
      setDate({ startDate: "", endDate: "" });
    }
  }, [created]);

  useEffect(() => {
    getData();
    dispatch({ type: resetData.type });
    dispatch({ type: clearForm.type });
  }, []);

  useEffect(() => {
    setFilteredData(applicationList?.data ?? []);
    setSelectedStatus("All");
  }, [applicationList?.data]);

  const fetchDataFromSearch = () => {
    const params = {
      pageNumber: page,
      pageSize: perPage,
    };
    if (searchText?.generalSearch) {
      params.search = searchText.generalSearch;
    }

    dispatch({
      type: getRecords.type,
      payload: {
        url: `/NocApplication/GetAllNOCApplication`,
        params,
        appendCurrentReducerKey: "applicationList",
      },
    });
  };

  const handlePaginationPerpage = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/NocApplication/GetAllNOCApplication?pageNumber=${page}&pageSize=${perPage}`,
        appendCurrentReducerKey: "applicationList",
      },
    });
  };

  const getData = () => {
    dispatch({
      type: "form/fetchLookup",
      payload: { key: "applicationStatus", url: "/Lookup/ApplicationStatus" },
    });
  };

  const filterByStatus = (type) => {
    setSelectedStatus(type);
    if (type === "All") {
      setFilteredData(applicationList?.data ?? []);
    } else {
      const filtered = applicationList?.data?.filter(
        (item) => item?.applicationStatus === type
      );
      setFilteredData(filtered ?? []);
    }
  };

  const getStatusClass = (type) => {
    return selectedStatus === type ? "active" : "";
  };

  const statusList = applicationStatus?.map((item, key) => {
    if (item?.name !== "Approved") {
      return {
        text: item?.description,
        className: `${getStatusClass(item?.name)}`,
        onClick: () => filterByStatus(item?.description),
      };
    }
  });

  const All = {
    text: "All",
    className: `${getStatusClass("All")}`,
    onClick: () => filterByStatus("All"),
  };

  const handleEdit = (rowId, isExtension) => {
    navigate(
      isExtension
        ? `/extension/update/${rowId}?Edit=true`
        : `/applications/update/${rowId}?isExtension=${isExtension}`
    );
  };

  const handleDelete = (id) => {
    const buttons = [
      {
        label: "Yes",
        onClick: () => {
          dispatch({
            type: deleteRecord.type,
            payload: {
              url: `/NocApplication`,
              id: id,
            },
          });
        },
      },
      {
        label: "No",
        onClick: () => {},
      },
    ];

    Alert("Are you sure wan to delete?", buttons);
  };
  function validateForm() {
    let formErrors = {};

    if (!date?.startDate) {
      formErrors.startDate = "Start Date is required";
    }
    if (!date?.endDate) {
      formErrors.endDate = "End Date is required";
    }

    const startDate = new Date(date?.startDate);
    const endDate = new Date(date?.endDate);

    if (startDate > endDate) {
      formErrors.endDate = "End Date should be greater than Start Date";
    }
    return formErrors;
  }

  const handleApprove = (id, isExtension) => {
    const formErrors = validateForm();
    if (Object.keys(formErrors)?.length > 0) {
      setErrors(formErrors);
      return;
    }

    const url = isExtension
      ? `/NocApplication/${id}/Extension/approve?startDate=${date?.startDate}&endDate=${date?.endDate}`
      : `/NocApplication/${id}/approve?startDate=${date?.startDate}&endDate=${date?.endDate}`;
    dispatch({
      type: createRecord.type,
      payload: {
        url: url,
      },
    });
  };

  const handleReject = (id, isExtension) => {
    const url = isExtension
      ? `/NocApplication/${id}/Extension/reject`
      : `/NocApplication/${id}/reject`;
    dispatch({
      type: createRecord.type,
      payload: {
        url: url,
      },
    });
  };

  // const actions = [
  //   {
  //     label: "Delete",
  //     icon: Trash,
  //     onClick: handleDelete,
  //   },
  // ];
  // const underProcessActions = [
  //   {
  //     label: "Edit",
  //     icon: PencilSquare,
  //     onClick: handleEdit,
  //   },
  //   {
  //     label: "Delete",
  //     icon: Trash,
  //     onClick: handleDelete,
  //   },
  // ];

  function onBtnPressAction(row, action) {
    let approveMessage = "Are you sure to Approve this?";
    let deleteMessage = "Are you sure to Reject this?";
    let showMessage = action === "approve" ? approveMessage : deleteMessage;
    if (action == "approve") {
      setSelectedRow(row);
      setModalShow(true);
    } else {
      confirmAlert({
        message: showMessage,
        buttons: [
          {
            label: "Yes",
            onClick: () => handleReject(row?.id, row?.isExtension),
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        willUnmount: () => {},
        afterClose: () => {},
        onClickOutside: () => {},
        onKeypressEscape: () => {},
        overlayClassName: "overlay-custom-class-name",
      });
    }
  }

  const columns = [
    ...applicationsColumns,
    ...(canView(["Secretary", "NGO User"], role)
      ? [
          {
            name: "Action",
            width: "200px",
            cell: (row) => (
              <>
                {role == "Secretary" && (
                  <div className=" d-flex gap-2">
                    <ToolTip
                      label={`${
                        !row?.canApprove
                          ? "Please contact system admin"
                          : "Approve"
                      }`}
                    >
                      <span>
                        <button
                          type="button"
                          disabled={!row?.canApprove}
                          onClick={() => onBtnPressAction(row, "approve")}
                          class="btn btn-success btn-sm"
                        >
                          Approve
                        </button>
                      </span>
                    </ToolTip>

                    <ToolTip
                      label={`
                      ${
                        !row?.canReject
                          ? "Please contact system admin"
                          : "Reject"
                      }
                      `}
                    >
                      <span>
                        <button
                          type="button"
                          disabled={!row?.canReject}
                          onClick={() => onBtnPressAction(row, "reject")}
                          class="btn btn-danger btn-sm"
                        >
                          Reject
                        </button>
                      </span>
                    </ToolTip>
                  </div>
                )}
                {role == "NGO User" && (
                  <>
                    {row?.canUpdate ? (
                      <div className="d-flex gap-2">
                        <PencilSquare
                          className="cursor-pointer"
                          size={24}
                          onClick={() =>
                            handleEdit(
                              row?.id,
                              row?.isExtension,
                              row?.applicationStatus
                            )
                          }
                        />
                        <Trash
                          className="cursor-pointer"
                          size={24}
                          onClick={() =>
                            handleDelete(
                              row?.id,
                              row?.isExtension,
                              row?.applicationStatus
                            )
                          }
                        />
                      </div>
                    ) : (
                      <>
                        <Trash
                          className="cursor-pointer"
                          size={24}
                          onClick={() =>
                            handleDelete(
                              row?.id,
                              row?.isExtension,
                              row?.applicationStatus
                            )
                          }
                        />
                      </>
                    )}
                  </>
                  // <DropdownButton
                  //   rowId={row?.id}
                  //   actions={row?.canUpdate ? underProcessActions : actions}
                  //   {...row}
                  // />
                )}
              </>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      <div className="px-4 py-2 mt-5">
        <Loader loading={loading} />
        <WithPageHeader
          heading="Applications"
          text="View & Manage Your Applications"
          buttons={
            canView(["NGO User"], role)
              ? [
                  {
                    icon: <img src={application_btn} alt="application_btn" />,
                    text: "New NOC Application",
                    onClick: () => navigate("/applications/add"),
                    className: "regularButton",
                    style: { gap: "8px" },
                  },
                ]
              : []
          }
        />

        <div
          className={`bg-white shadow-sm rounded p-3 table-container-anim loaded`}
        >
          <WithPageHeader
            heading="NOC Applications List"
            buttons={[All, ...statusList]}
          />

          <div className="mt-3">
            <Table
              data={filteredData ?? []}
              columns={columns}
              customStyles={customStyles}
              pagination
              paginationServer
              paginationTotalRows={applicationList?.totalRecords}
              onChangeRowsPerPage={(pP) => {
                setPerPage(pP);
              }}
              onChangePage={(p) => {
                setPage(p);
              }}
              dense
            />
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalShow}
        onRequestClose={() => setModalShow(false)}
        contentLabel={`Approve Application`}
        overlayClassName="modal-xs"
        ariaHideApp={false}
      >
        <div className="d-flex align-items-center justify-content-between">
          <p className="heading1 m-0">{`Approve Application`}</p>
          <Icon.X
            size={32}
            color="black"
            onClick={() => {
              setModalShow(!modalShow);
              setErrors({});
              setSelectedRow({});
              setDate({ startDate: "", endDate: "" });
            }}
          />
        </div>

        <div className="seprator"></div>

        <div className="mt-4">
          <p className="m-1">Start Date</p>
          <input
            type="date"
            name="startDate"
            className="form-control"
            value={date?.startDate}
            onChange={(e) => setDate({ ...date, startDate: e.target.value })}
          />
          {errors && <p className="text-danger">{errors?.startDate}</p>}
        </div>
        <div className="mt-4">
          <p className="m-1">End Date</p>
          <input
            name="endDate"
            value={date?.endDate}
            className="form-control"
            type="date"
            onChange={(e) => setDate({ ...date, endDate: e.target.value })}
          />
          {errors && <p className="text-danger">{errors?.endDate}</p>}
        </div>

        <div className="d-flex justify-content-end mt-5">
          <button
            onClick={() =>
              handleApprove(selectedRow?.id, selectedRow?.isExtension)
            }
            className="btn-forward"
            disabled={creating}
          >
            {creating || loading ? (
              <div class="spinner-border text-white" role="status"></div>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Applications;

// function approveAlert(
//   handleApprove,
//   row,
//   modalShow,
//   setModalShow,
//   date,
//   setDate,
//   errors,
//   setErrors,
//   creating
// ) {
// function validateForm() {
//   let formErrors = {};

//   if (!date?.startDate) {
//     formErrors.startDate = "Start Date is required";
//   }
//   if (!date?.endDate) {
//     formErrors.endDate = "End Date is required";
//   }

//   return setErrors(formErrors);
// }

// function handleSubmit() {
//   const formErrors = validateForm();
//   if (Object.keys(formErrors).length > 0) {
//     setErrors(formErrors);
//     return;
//   }

//   dispatch({
//     type: createRecord.type,
//     payload: {
//       url: `/NocApplication/${nocId}/approve?startDate=${date?.startDate}&endDate=${date?.endDate}`,
//       isFormatted: true,
//       message: "Application approved successfully",
//     },
//   });
// }

//   return (
//     <Modal
//       isOpen={modalShow}
//       onRequestClose={() => setModalShow(false)}
//       contentLabel={`Approve Application`}
//       overlayClassName="modal-xs"
//       ariaHideApp={false}
//     >
//       <div className="d-flex align-items-center justify-content-between">
//         <p className="heading1 m-0">{`Approve Application`}</p>
//         <Icon.X
//           size={32}
//           color="black"
//           onClick={() => {
//             setModalShow(!modalShow);
//             setErrors({});
//           }}
//         />
//       </div>

//       <div className="seprator"></div>

//       <div className="mt-4">
//         <p className="m-1">Start Date</p>
//         <input
//           type="date"
//           name="startDate"
//           value={date?.startDate}
//           onChange={(e) => setDate({ ...date, startDate: e.target.value })}
//         />
//         {errors && <p className="text-danger">{errors?.startDate}</p>}
//       </div>
//       <div className="mt-4">
//         <p className="m-1">End Date</p>
//         <input
//           name="endDate"
//           value={date?.endDate}
//           type="date"
//           onChange={(e) => setDate({ ...date, endDate: e.target.value })}
//         />
//         {errors && <p className="text-danger">{errors?.endDate}</p>}
//       </div>

//       <div className="d-flex justify-content-end">
//         <button
//           onClick={handleApprove(row)}
//           className="btn-forward"
//           disabled={creating}
//         >
//           {creating ? (
//             <div class="spinner-border text-white" role="status"></div>
//           ) : (
//             "Submit"
//           )}
//         </button>
//       </div>
//     </Modal>
//   );
// }
