import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { BarChartImpactData } from './ApexSampleData'
import noData from "../../assets/no-data.png";

const BarChartApex = ({data,innerHeight}) => {
  if (data.options.xaxis.categories.length === 0){
    return(
     <div className='text-center p-5 mt-5'>
        <img src={noData} alt="logo" height={60} />
          <p>No Data Found!</p>
     </div>
       )
  } else {
    return (
      <ReactApexChart options={data?.options} series={data?.series} type="bar"  height={innerHeight ? 500 : 300} />
    )
  }
}

export default BarChartApex