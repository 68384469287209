import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import ReadableTable from '../../components/readableTable/ReadableTable'
import WithPageHeader from '../../components/ui/withPageHeader'
import { getRecords } from '../../redux/reducers';
import { ClosureColumns } from '../../assets/columns/ClosureColumns';
import DropdownButton from '../../components/dropdown-button/DropdownButton';
import { customStyles } from '../../assets/styles/CustomStyle';
import Table from '../../components/table/Table';

const Closures = ({ }) => {

  const dispatch = useDispatch();
  const { closures = [] } = useSelector((state) => state?.records?.current);

  useEffect(() => {
    getClosureData();
  }, []);

  const getClosureData = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/NocApplication/GetAllNocClosure`,
        appendCurrentReducerKey: "closures",
      },
    });
  };

  const columns = [
    ...ClosureColumns,
    // {
    //   name: "Action",
    //   width: "100px",
    //   cell: (row) => (
    //     <DropdownButton rowId={row.id} actions={actions} />
    //   )

    // },
  ];

  return (
    <div className='px-4 py-2 mt-5 extension-detail'>

      <WithPageHeader
        heading="NOC Closures"
        text="Review and manage Closure of NOCs"
        buttons={[
          // canView(["User"], userRoles) && {
          //   icon: <img src={application_btn} alt="application_btn" />,
          //   text: "Apply for Extension",
          //   onClick: () => navigate("/extension/add"),
          //   className: "regularButton",
          //   style: { gap: "8px" },
          // },
        ]}
      />

      <div className="bg-white shadow-sm rounded p-3">
        <WithPageHeader
          heading="Closure List"
          text=""
          buttons={[]}
        />
        <div className="mt-3">
          <Table
            columns={columns}
            data={closures ?? []}
            customStyles={customStyles}
            pagination
            dense
          />
        </div>
      </div>
    </div>
  )
}

export default Closures