import React from "react";

const CertificateIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      width={32}
      height={32}
      {...props}
    >
      <path d="M21.86 2.62h-20a.5.5 0 0 0-.5.5v13.7a.51.51 0 0 0 .5.5h9.84l-1 1.66a.5.5 0 0 0 0 .5.5.5 0 0 0 .43.25h1.62l.82 1.4a.5.5 0 0 0 .43.24.47.47 0 0 0 .43-.25l1.39-2.39 1.38 2.39a.47.47 0 0 0 .43.25.5.5 0 0 0 .43-.24l.82-1.4h1.62a.5.5 0 0 0 .43-.25.5.5 0 0 0 0-.5l-1-1.66h1.85a.5.5 0 0 0 .5-.5V3.12a.5.5 0 0 0-.42-.5ZM14 19.89l-.53-.9a.53.53 0 0 0-.43-.25H12l1.26-2.2a.15.15 0 0 1 .23 0 3.68 3.68 0 0 0 1.56 1 .24.24 0 0 1 .14.34Zm1.82-3.26a2 2 0 1 1 2-2 2 2 0 0 1-1.96 2Zm2.77 2.11a.53.53 0 0 0-.43.25l-.53.9-1.17-2a.23.23 0 0 1 .15-.34 3.05 3.05 0 0 0 1.53-1 .14.14 0 0 1 .23 0l1.26 2.2Zm2.73-2.42h-1.89l-.62-1.08a3.18 3.18 0 0 0 .06-.62 3 3 0 1 0-6 0 3.18 3.18 0 0 0 .06.62l-.62 1.08H2.36V3.62h19Z" />
      <path d="M16.33 14.12h-.95a.5.5 0 0 0 0 1h.95a.5.5 0 0 0 0-1zM4.8 15.44h4.59a1.63 1.63 0 1 0 0-3.25H4.8a1.63 1.63 0 1 0 0 3.25zm0-2.25h4.59a.63.63 0 1 1 0 1.25H4.8a.63.63 0 1 1 0-1.25zM10.48 6h2.76a.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5h-2.76a.5.5 0 0 0-.5.5.51.51 0 0 0 .5.5zM8 7.88h7.64a.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5H8a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5zm0 1.93h7.64a.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5H8a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5z" />
    </svg>
  );
};

export default CertificateIcon;
