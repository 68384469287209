import React from 'react'

const NoMessageIcon = (props) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    fill="none"
    {...props}
  >
    <path
      d="M15.101 8.5h8.982A1.417 1.417 0 0 1 25.5 9.917v8.981a1.417 1.417 0 0 0 2.833 0V9.917a4.25 4.25 0 0 0-4.25-4.25H15.1a1.417 1.417 0 0 0 0 2.833Zm15.654 20.244-25.5-25.5a1.423 1.423 0 0 0-2.011 2.012l2.833 2.833a4.25 4.25 0 0 0-.41 1.828v17a1.417 1.417 0 0 0 .878 1.303c.168.08.352.118.538.113a1.416 1.416 0 0 0 1.006-.41l3.825-3.84h10.171l6.659 6.673a1.416 1.416 0 0 0 2.011 0 1.416 1.416 0 0 0 0-2.012ZM11.333 21.25a1.416 1.416 0 0 0-1.006.41L8.5 23.504V10.498L19.252 21.25h-7.92Z"
    />
  </svg>
  )
}

export default NoMessageIcon