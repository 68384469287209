import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormValues } from "../../../redux/reducers";

const CheckboxGrid = ({
  initialChecked = [],
  label = "",
  instruction = "",
  fields = [],
  name = "",
  objectFormate = "",
}) => {
  const dispatch = useDispatch();
  const formValues = useSelector((state) => state.form.values);
  const [otherValue, setOtherValue] = useState("");

  const [checked, setChecked] = useState(
    Array.isArray(initialChecked) ? initialChecked : []
  );

  useEffect(() => {
    const fieldValues = formValues[name]?.value || [];
    const fieldIds = fieldValues?.map((item) => item[objectFormate]);
    setOtherValue(
      fieldValues?.find((item) => item["name"] == "Other")?.clusterValue
    );
    setChecked(fieldIds);
  }, [name, objectFormate, formValues]);

  const handleCheckboxChange = (e, item) => {
    const { id, name: itemName } = item;
    const isChecked = e.target.checked;

    let updatedChecked;
    if (isChecked) {
      updatedChecked = [...checked, id];
    } else {
      updatedChecked = checked.filter((checkedId) => checkedId !== id);
    }

    setChecked(updatedChecked);

    const otherItemIndex = formValues[name]?.value.findIndex(
      (item) => item["name"] == "Other"
    );
    let otherItem = formValues[name]?.value[otherItemIndex] || null;

    if (itemName === "Other" && isChecked) {
      otherItem = {
        [objectFormate]: id,
        name: "Other",
        clusterValue: otherValue,
      };
    } else if (!isChecked && itemName === "Other") {
      otherItem = null;
    }

    const items = updatedChecked.map((checkedId) => {
      const iOtherId = formValues[name]?.value?.find(
        (i) => i["name"] === "Other"
      )?.clusterId;
      if (checkedId === iOtherId && otherItem) {
        return otherItem;
      } else {
        return {
          [objectFormate]: checkedId,
          name: fields.find((field) => field.id === checkedId)?.name,
        };
      }
    });

    dispatch({
      type: setFormValues.type,
      payload: {
        ...formValues,
        [name]: {
          name: name,
          value: items,
        },
      },
    });
  };

  const handleOtherInputChange = (e) => {
    const value = e.target.value;
    setOtherValue(value);

    const otherItemIndex = formValues[name]?.value.findIndex(
      (item) => item["name"] === "Other"
    );
    let otherItem = formValues[name]?.value[otherItemIndex] || null;

    if (otherItem) {
      otherItem = {
        ...otherItem,
        name: "Other",
        clusterValue: value,
      };

      const updatedItems = formValues[name]?.value.map((item) =>
        item["name"] === "Other" ? otherItem : item
      );

      dispatch({
        type: setFormValues.type,
        payload: {
          ...formValues,
          [name]: {
            name: name,
            value: updatedItems,
          },
        },
      });
    }
  };

  return (
    <div className="container-fluid px-0">
      <p className="label-heading" style={{ fontSize: "1rem" }}>{label}</p>
      <p style={{ fontSize: "14px" }}>{instruction}</p>
      <div className="row gx-4">
        {fields?.map((item) => (
          <div className="col-6" key={item.id}>
            <div
              className={`form-check checkbox col-12${
                checked.includes(item.id) ? " checked" : ""
              }`}
            >
              <label
                className="form-check-label" style={{ fontSize: "14px" }}
                htmlFor={`flexCheckChecked ${item?.id}`}
              >
                {item?.name}
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id={`flexCheckChecked ${item?.id}`}
                checked={checked.includes(item?.id)}
                onChange={(e) => handleCheckboxChange(e, item)}
              />
            </div>
            {item.name === "Other" && checked.includes(item.id) && (
              <input
                type="text"
                style={{ padding: "14px" }}
                className="form-control mt-2"
                value={otherValue}
                onChange={handleOtherInputChange}
                placeholder="Please specify"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckboxGrid;
