import React from 'react'

const FilterIcon = (props) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fill="#1E1F23"
      d="M.733 10.4h7.533a.733.733 0 0 0 0-1.467H.733a.733.733 0 0 0 0 1.467Zm11.2 0h2.933a.733.733 0 0 0 0-1.467h-2.933a.733.733 0 0 0 0 1.467Z"
    />
    <path
      fill="#1E1F23"
      d="M10.101 12.233a2.567 2.567 0 1 0 0-5.134 2.567 2.567 0 0 0 0 5.134Zm0-3.666a1.1 1.1 0 1 1 0 2.2 1.1 1.1 0 0 1 0-2.2ZM14.867 3.3H7.333a.733.733 0 0 1 0-1.467h7.534a.733.733 0 1 1 0 1.467Zm-11.2 0H.733a.733.733 0 0 1 0-1.467h2.934a.733.733 0 1 1 0 1.467Z"
    />
    <path
      fill="#1E1F23"
      d="M5.5 5.133A2.567 2.567 0 1 1 5.5 0a2.567 2.567 0 0 1 0 5.133Zm0-3.666a1.1 1.1 0 1 0 0 2.2 1.1 1.1 0 0 0 0-2.2Z"
    />
  </svg>
  )
}

export default FilterIcon