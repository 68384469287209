// CommentSectionContainer.js
import React from "react";
import CommentSection from "../../components/comment-section/CommentSection";
import ToggleCommentSection from "./ToggleCommentSection";
import { ChatDots } from "react-bootstrap-icons";

const CommentSectionContainer = ({ commentsData, ...props }) => {
  return (
    <div
      className={`col-md-${props?.isCommentSectionOpen ? "3" : "1"}`}
      style={{ transition: "all 0.3s" }}
    >
      <CommentSection commentOptions commentsData={commentsData} {...props} />
    </div>
  );
};

export default CommentSectionContainer;
