import React, { useState, useEffect } from "react";
import "./ApplicationCounter.scss";

const Countdown = ({ COUNTDOWN_TARGET }) => {
  const getTimeLeft = () => {
    const targetDate = new Date(COUNTDOWN_TARGET);

    const extendedTargetDate = new Date(
      targetDate.getTime() + 3 * 24 * 60 * 60 * 1000
    );
    const totalTimeLeft = extendedTargetDate - new Date();
    if (totalTimeLeft <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
    const days = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((totalTimeLeft / (1000 * 60)) % 60);
    const seconds = Math.floor((totalTimeLeft / 1000) % 60);
    return { days, hours, minutes, seconds };
  };
  const [timeLeft, setTimeLeft] = useState(() => getTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const isExpired = Object.values(timeLeft).every((value) => value === 0);
  return (
    <div className="countdown">
      {isExpired ? (
        <div className="d-flex align-items-center justify-content-center">
          <span className="text-danger">Expired</span>
        </div>
      ) : (
        <div className="content">
          <div className="seprator-line"></div>
          {Object.entries(timeLeft).map((el) => {
            const label = el[0];
            const value = el[1];
            return (
              <div className="box" key={label}>
                <div className="value">
                  <span>{value}</span>
                </div>
                {/* <span className="label"> {label} </span> */}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Countdown;
