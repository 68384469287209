import React, { useState,useEffect } from "react";
import "./AddEnter.scss";
import { useDispatch, useSelector } from "react-redux";
import Form from "../form/Form";
import { clearForm } from "../../redux/reducers";
const AddEnter = () => {
  const dispatch = useDispatch();
  const {formFields} = useSelector((state) => state.fields);
  
  const postData = (props) => {
    dispatch({ type: "records/createRecord", payload: props });
  };

  useEffect(() => {
    dispatch({ type: "form/clearForm" })
  }, [formFields])
  
  return (
    <div className="add-enter">
      <Form
        formData={formFields ?? []}
        onSubmit={(params) => postData(params)}
        withValidation={false}
        formValidation={[]}
        extraInputClass=""
      />
    </div>
  );
};

export default AddEnter;
