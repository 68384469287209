import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { loginFields } from "../../assets/fields/loginFields";
import {
  clearForm,
  createRecord,
  getRecords,
  setUser,
} from "../../redux/reducers";

import Form from "../form/Form";
import "./Login_Signup.scss";
import LoginTemplate from "./LoginTemplate";

const LoginSignupForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { created } = useSelector((state) => state.records);

  useEffect(() => {
    if (created) navigate("/dashboard");
    return () => {
      dispatch({ type: "records/setRecords", payload: { created: false } });
    };
  }, [created]);

  const handleSubmit = (values) => {
    dispatch({
      type: createRecord.type,
      payload: { ...values, url: "/Auth/SignIn" },
      onSuccess: [
        {
          type: getRecords.type,
          payload: {
            url: "/Auth",
            typeOverride: "setting/setUser",
          },
        },
      ],
    });
  };

  useEffect(() => {
    dispatch({ type: clearForm.type });
    return () => {
      dispatch({ type: "records/resetData" });
    };
  }, [loginFields]);

  const meta = {
    headerTitle: "Login to your account.",
    headerDescription: "Enter your email and password to login.",
    footerText: "Don't you have an account?",
    footerLink: "/signup",
    footerLinkText: "Signup",
  };

  return (
    <>
      <LoginTemplate meta={meta}>
        <div className="container col-12">
          <Form
            formData={loginFields}
            onSubmit={(params) => handleSubmit(params)}
          />
        </div>
      </LoginTemplate>
    </>
  );
};

export default LoginSignupForm;
