import React, { useEffect } from "react";
import WithPageHeader from "../ui/withPageHeader";
import Form from "../form/Form";
import { signupAndOrganizationFields } from "../../assets/fields/signupAndOrganizationFields";
import { getFieldsByCategory } from "../../utils/util";
import { LOGO } from "../../assets/GlobalMediaAssets";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearForm,
  createRecord,
  resetData,
} from "../../redux/reducers";
import { signupValidationFields, transformInput } from "../../Utility";
import Loader from "../loader/loader";
import { validateForm } from "../form/form.helper";

const SingupAndOrganizationForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { created, loading } = useSelector((state) => state.records);
  const { values, validations } = useSelector((state) => state.form);

  useEffect(() => {
    if (created) navigate("/login");
  }, [created]);

  useEffect(() => {
    return () => {
      dispatch({ type: resetData.type });
      dispatch({ type: clearForm.type });
    };
  }, []);

  const SubmitSignupForm = async () => {
    const iVals = {
      "userOrganization.userOrganizationOffices[0].officeType": {
        name: "userOrganization.userOrganizationOffices[0].officeType",
        value: "Project Office",
      },
      "userOrganization.userOrganizationOffices[1].officeType": {
        name: "userOrganization.userOrganizationOffices[0].officeType",
        value: "Provisional Office",
      },
      "userOrganization.userOrganizationOffices[2].officeType": {
        name: "userOrganization.userOrganizationOffices[0].officeType",
        value: "National Head Office",
      },
      "userOrganization.orgTypeName": {
        name: "userOrganization.orgTypeName",
        value: values["userOrganization.orgTypeId"]?.value?.label,
      },
    };
    const mutatedVals = values[
      "userOrganization.userOrganizationProjects"
    ]?.value?.map((i) => {
      return { ...i, projectArea: i?.projectArea?.value?.label };
    });

    const result = await transformInput({
      ...values,
      ...iVals,
      "userOrganization.userOrganizationProjects": {
        name: "userOrganization.userOrganizationProjects",
        value: mutatedVals,
      },
    });

    const iResult = result?.userOrganization["userOrganizationDirectors"]?.map(
      (i) => {
        return { ...i, passportDoc: i?.passportDocTable };
      }
    );
    const updateResult = {
      ...result,
      userOrganizationDirectors: iResult,
    };
    const isValid = validateForm(signupValidationFields, values, dispatch);
    if (isValid) {
      dispatch({
        type: createRecord.type,
        payload: {
          ...updateResult,
          url: "/Auth/RegisterUser",
          isFormatted: true,
          message:
            "Thank you for signing up! Your application is under review and pending approval. We will notify you via email once it is approved",
        },
      });
    }
  };
  return (
    <>
      <Loader loading={loading} />

      <div className="col-md-12 h-100">
        <Link to="/login">
          <div className="col-md-12 d-flex align-items-center gap-2 bg-white py-2 px-5">
            <img src={LOGO} alt="logo" className="logo-img" />
            <h4 className="text-noc">NOC</h4>
          </div>
        </Link>
        <div className="col-md-8 m-0 m-auto mt-4">
          <WithPageHeader
            heading="Sign up to your account."
            text="Enter your details to sign up. "
            buttons={[]}
          />
        </div>

        <div className="col-md-8 m-auto mb-2 overflow-x-hidden">
          <div className="bg-white shadow-sm mb-4 p-4 rounded ">
            <Form
              formData={getFieldsByCategory(
                signupAndOrganizationFields,
                "personalInformation"
              )}
              withValidation={true}
              formValidation={validations ?? []}
              extraInputClass=""
            />
          </div>
          <div className="bg-white shadow-sm mb-4 p-4 rounded ">
            <Form
              formData={getFieldsByCategory(
                signupAndOrganizationFields,
                "organizationInformation"
              )}
              withValidation={true}
              formValidation={validations}
              extraInputClass=""
            />
          </div>
          <div className="bg-white shadow-sm mb-4 p-4 rounded ">
            <Form
              formData={getFieldsByCategory(
                signupAndOrganizationFields,
                "essentialContacts"
              )}
              withValidation={true}
              formValidation={validations}
              extraInputClass=""
            />
          </div>
          <div className="bg-white shadow-sm mb-4 p-4 rounded ">
            <Form
              formData={getFieldsByCategory(
                signupAndOrganizationFields,
                "organizationRegistrationDetails"
              )}
              withValidation={true}
              formValidation={validations}
              extraInputClass=""
            />
          </div>
          <div className="bg-white shadow-sm mb-4 p-4 rounded ">
            <Form
              formData={getFieldsByCategory(
                signupAndOrganizationFields,
                "informationOfOffice"
              )}
              withValidation={true}
              formValidation={validations}
              extraInputClass=""
            />
          </div>
          <div className="bg-white shadow-sm mb-4 p-4 rounded ">
            <Form
              formData={getFieldsByCategory(
                signupAndOrganizationFields,
                "userOrganizationDirectorCommand"
              )}
              withValidation={false}
              formValidation={[]}
              extraInputClass=""
            />
          </div>
          <div className="bg-white shadow-sm mb-4 p-4 rounded ">
            <Form
              formData={getFieldsByCategory(
                signupAndOrganizationFields,
                "detailsOfForeigners"
              )}
              withValidation={false}
              formValidation={[]}
              extraInputClass=""
            />
          </div>
          <div className="bg-white shadow-sm mb-4 p-4 rounded inputTableFieldAuto inputTableOverflow">
            <Form
              formData={getFieldsByCategory(
                signupAndOrganizationFields,
                "keyStaffInformation"
              )}
              withValidation={false}
              formValidation={[]}
              extraInputClass=""
            />
          </div>
          <div className="bg-white shadow-sm mb-4 p-4 rounded ">
            <Form
              formData={getFieldsByCategory(
                signupAndOrganizationFields,
                "detailsOfLocals"
              )}
              withValidation={false}
              formValidation={[]}
              extraInputClass=""
            />
          </div>
          <div className="bg-white shadow-sm mb-4 p-4 rounded inputTableOverflow">
            <Form
              formData={getFieldsByCategory(
                signupAndOrganizationFields,
                "detailsOfProjectUnderTakenNGO"
              )}
              withValidation={true}
              formValidation={validations}
              extraInputClass=""
            />
          </div>
          <div style={{ gap: "32px" }} className="d-flex justify-content-end">
            <button
              className="btn-backward px-5"
              onClick={() => {
                navigate("/login");
              }}
            >
              CANCEL
            </button>
            <button onClick={SubmitSignupForm} className="btn-forward px-5">
              SIGN UP
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingupAndOrganizationForm;
