import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { BarChartTargetData, ActualVsTargetData } from './ApexSampleData'
import noData from "../../assets/no-data.png";

const BarChartHorizontalApex = ({options,series,innerHeight}) => {
  
  if (series[0]?.data.length === 0 && series[1]?.data.length === 0 ) {
    return (
      <div className="text-center p-5 mt-5">
        <img src={noData} alt="logo" height={60} />
        <p>No Data Found!</p>
      </div>
    );
  } else {
  return (
    <ReactApexChart options={options} series={series} type="bar" height={innerHeight ? 500 : 300}  />
  )
  }
}

export default BarChartHorizontalApex