export const leftMenu = [


  {
    label:"Cluster",
    path:"/definitions/cluster"
  },
  {
    label:"Currency",
    path:"/definitions/currency"
  },
  {
    label: "District",
    path: "/definitions/district"
  },


  {
    label: "Fund Type",
    path: "/definitions/fund-type"
  },
  {
    label: "Intervention Type",
    path: "/definitions/intervention-type"
  },
  {
    label: "Project Nature",
    path: "/definitions/project-nature"
  },
  
  {
    label: "Response Plan",
    path: "/definitions/response-plan"
  },
  {
    label:"Staff Type",
    path:"/definitions/staff-type"
  },
  {
    label:"Staff Level",
    path:"/definitions/staff-level"
  },

  {
    label: "Thematic Area",
    path: "/definitions/thematic-area"
  },

  {
    label: "Mean Of Verfication",
    path: "/definitions/mean-of-verification"
  }
 
 
 
];




 
