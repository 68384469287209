import React from "react";
import "./extensiondetails.scss";
import ReadableTable from "../readableTable/ReadableTable";
import { formatDate } from "../../utils/util";

const ExtensionWorkPlan = ({ donorAgreement }) => {
  const sumOfAll = function (arr, param) {
    let sum = 0;
    for (let i in arr) {
      sum += arr[i][param];
    }
    return sum;
  };

  const getTotalMonths = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const totalMonths =
      (end.getFullYear() - start.getFullYear()) * 12 +
      (end.getMonth() - start.getMonth());

    return totalMonths;
  };

  const geographicLocation = donorAgreement?.geographicLocations?.map((i) => {
    return {
      districtName: i?.districtName,
      tehsil: i?.tehsil,
      unionCouncil: i?.unionCouncil,
      village: i?.village,
    };
  });
  return (
    <div className="col-md-12 bg-white shadow-sm mb-2 p-4 mt-3 rounded extension-details">
      <div className="my-3">
        <p className="label-heading">
          Plan under which project falls(if applicable)
        </p>
        <div className="row">
          {donorAgreement?.responsePlans?.map((reponsePlan) => (
            <React.Fragment key={reponsePlan?.id}>
              {
                <div className="col-md-6  my-1 ">
                  <div className="header-name rounded-2 h-100">
                    <p className="mr-2">{reponsePlan?.plan}</p>
                  </div>
                </div>
              }
            </React.Fragment>
          ))}
        </div>
      </div>

      <p className="heading2">Cluster under which project falls</p>
      <div className="container-fluid gx-1">
        <div className="row gx-3">
          {donorAgreement?.applicationClusters?.map((item, key) => {
            return (
              <div className="col-md-6" key={item?.clusterId}>
                <p className="bordered-row">{item?.clusterName}</p>
              </div>
            );
          })}
        </div>
      </div>
      <ReadableTable
        headers={["District", "Tehsil", "Unioun Council", "Village"]}
        data={geographicLocation ?? []}
        label={"Geographic Location & Beneficiaries"}
      />

      <div className="my-3">
        <p className="label-heading">
          Thematic Areas Under which the project falls
        </p>
        <div className="row">
          {donorAgreement?.thematicAreas?.map((projectArea) => (
            <React.Fragment key={projectArea?.id}>
              {
                <div className="col-md-6  my-1 ">
                  <div className="header-name rounded-2 h-100">
                    <p className="mr-2">{projectArea?.areaName}</p>
                  </div>
                </div>
              }
            </React.Fragment>
          ))}
        </div>
      </div>

      <ReadableTable
        headers={[
          "Sector",
          "District",
          "Household",
          "No. of Males",
          "No. of Females",
          "Total Beneficiaries",
        ]}
        data={(donorAgreement?.projectBeneficiaries ?? []).map(
          ({
            districtName,
            houseHolds,
            noOfMales,
            noOfFemales,
            clusterName,
          }) => ({
            clusterName,
            districtName,
            houseHolds,
            noOfMales,
            noOfFemales,
            totalBeneficiaries: noOfMales + noOfFemales,
          })
        )}
        label={"Estimated  number of  direct  beneficiaries"}
      />

      <p className="heading2">Proposed timeframe for project</p>
      <div className="container-fluid gx-1">
        <div className="row gx-3">
          <div className="col">
            <p className="bordered-row">
              Total Months:{" "}
              <span>
                {getTotalMonths(
                  donorAgreement?.projectStartDate,
                  donorAgreement?.projectEndDate
                )}
              </span>
            </p>
          </div>
          <div className="col">
            <p className="bordered-row">
              Start Date:{" "}
              <span>{formatDate(donorAgreement?.projectStartDate ?? "0")}</span>
            </p>
          </div>
          <div className="col">
            <p className="bordered-row">
              End Date:{" "}
              <span>{formatDate(donorAgreement?.projectEndDate ?? "0")}</span>
            </p>
          </div>
        </div>
      </div>

      {/*} ----------------------------*/}
      <p className="heading1">Project Cost</p>
      <p className="heading2">Program Cost</p>
      <div className="container-fluid gx-1">
        <div className="row gx-3">
          <div className="col-md-4">
            <p className="bordered-row">
              Amount in USD:{" "}
              <span>{donorAgreement?.projectCost?.amountinUSD}</span>
            </p>
          </div>
          <div className="col">
            <p className="bordered-row">
              Equivalent PKR Amount:{" "}
              <span>{donorAgreement?.projectCost?.amountInPKR}</span>
            </p>
          </div>
          <div className="col">
            <p className="bordered-row">
              % Age to Total:{" "}
              <span>{donorAgreement?.projectCost?.totalPercentage}</span>
            </p>
          </div>
        </div>
        <div className="row gx-3">
          <div className="col-md-8">
            <p className="bordered-row">
              Remarks: <span>{donorAgreement?.projectCost?.remarks}</span>
            </p>
          </div>
        </div>
      </div>

      <ReadableTable
        headers={[
          "Sector",
          "District",
          "Amount in USD",
          "Equivalent PKR Amount",
          "% Age To Total",
          "Remarks",
        ]}
        data={(donorAgreement?.projectCost?.operationalCost ?? [])?.map(
          ({
            clusterName,
            districtName,
            amountinUSD,
            amountInPKR,
            totalPercentage,
            remarks,
          }) => ({
            clusterName,
            districtName,
            amountinUSD,
            amountInPKR,
            totalPercentage,
            remarks,
          })
        )}
        label={"Operational Cost"}
      />
      <p className="heading2">Amount of Fund Confirmed</p>
      <div className="container-fluid gx-1">
        <div className="row gx-3">
          <div className="col-md-6">
            <p className="bordered-row">
              USD:{" "}
              <span>
                {donorAgreement?.projectCost?.amountinUSD +
                  sumOfAll(
                    donorAgreement?.projectCost?.operationalCost,
                    "amountinUSD"
                  )}
              </span>
            </p>
          </div>
          <div className="col-md-6">
            <p className="bordered-row">
              Amount in PKR:{" "}
              <span>
                {donorAgreement?.projectCost?.amountInPKR +
                  sumOfAll(
                    donorAgreement?.projectCost?.operationalCost,
                    "amountInPKR"
                  )}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtensionWorkPlan;
