import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./tooltip.scss";

const ToolTip = ({ children, label = "", ...otherProps }) => {
  return (
    <OverlayTrigger overlay={<Tooltip {...otherProps}>{label}</Tooltip>}>
      <span>{children}</span>
    </OverlayTrigger>
  );
};

export default ToolTip;
