import React, { useEffect } from "react";
import Loader from "../loader/loader";
import Form from "../form/Form";
import { montlyReportFields } from "../../assets/fields/monthlyReportFields";
import { getFieldsByCategory } from "../../utils/util";
import { useDispatch, useSelector } from "react-redux";
import { formatRecords, getRecords } from "../../redux/reducers";

const ProgressInformationForm = ({ projectId }) => {
  const dispatch = useDispatch();
  const { values, lookups,validations } = useSelector((state) => state.form);
  const formValues = values;
  const nocApplicationId = lookups?.nocApplicationId;
  const { current } = useSelector((state) => state.records);
  const nocReportMonthsData = current?.nocApplicationMonths;

  const [loading, setLoading] = React.useState(false);
  const [formFields, setFormFields] = React.useState(
    getFieldsByCategory(montlyReportFields, "progressInformationForm")
  );

  useEffect(() => {
    if (projectId && nocApplicationId && Array.isArray(nocApplicationId)) {
      initNocProject(projectId);
    }
  }, [nocApplicationId]);

  const initNocProject = () => {
    let nocAppData = nocApplicationId.find((item) => item.id === projectId);
    if (nocAppData) {
      dispatch({
        type: formatRecords.type,
        payload: {
          nocApplicationId: {
            value: nocAppData?.id,
            label: nocAppData?.projectTitle,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (nocReportMonthsData && Array.isArray(nocReportMonthsData)) {
      const updatedFields = formFields.map((field) => {
        if (field.name === "reportMonth") {
          field.options = nocReportMonthsData.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
        }
        return field;
      });
      setFormFields(updatedFields);
    }
    return () => {};
  }, [nocReportMonthsData]);

  useEffect(() => {
    if (formValues?.nocApplicationId) {
      const nocId = formValues?.nocApplicationId?.value?.value;
      if (nocId) {
        getMonthsList(nocId);
        const updatedFields = formFields.map((field) => {
          if (field.name === "targetDistrictId") {
            field.url = `/District/${nocId}`;
          }

          return field;
        });
        setFormFields(updatedFields);
      }
    }
    return () => {};
  }, [formValues?.nocApplicationId]);

  const getMonthsList = (id) => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/NocApplication/${id}/GetListofMonths`,
        appendCurrentReducerKey: "nocApplicationMonths",
      },
    });
  };

  return (
    <>
      <Loader loading={loading} />
      <Form
        formData={formFields}
        // onSubmit={(params) => postData(params)}
        withValidation={true}
        formValidation={validations}
        extraInputClass="inputTableFieldAuto inputTableOverflow"
      />
    </>
  );
};

export default ProgressInformationForm;
