import React, { useState, useEffect } from "react";
import "./DefinitionManagement.scss";
import SearchFields from "./SearchFields";
import CrudFields from "./CrudFields";
import { useLocation } from "react-router-dom";
import usePageMeta from "../../hooks/usePageMeta";
import WithPageHeader from "../ui/withPageHeader";
import add_btn from "../../assets/images/plus_icon.svg";
import { PlusCircleDotted,X } from "react-bootstrap-icons";
import SearchIcon from "../../assets/icons/SearchIcon";
import { useDispatch } from "react-redux";

const DefinitionManagement = () => {
  const dispatch = useDispatch();
  const { setMeta } = usePageMeta();
  const location = useLocation();
  const [headingName, setHeadingName] = useState("Cluster");
  const [addEnteryMenu, setAddEnteryMenu] = useState(0);

  const tabChange = (payload) => {
    setHeadingName(payload);
  };
  useEffect(() => {
    setMeta({ title: "Definitions" });
  }, []);

  return (
    <div className="px-5 py-2 mt-5 col-md-12">
      <WithPageHeader
        heading="Definition"
        // text="Review and manage incomming applications"
        buttons={[
          {
            icon:  addEnteryMenu === 0 ? <PlusCircleDotted size={20} /> : <X size={25} fill="red" /> ,
            text: `${addEnteryMenu === 0 ? "New Definition" : "Cancel"}`,
            onClick: () => { addEnteryMenu === 0 ? setAddEnteryMenu(1) :  setAddEnteryMenu(0) },
            className: `${addEnteryMenu === 0 ? "regularButton" : "button"}`,
            style: { gap: "8px" },
          },
        ]}
      />
      <div className="cont col-md-12 mt-2">
        <div className="card-body shadow rounded-lg bg-white w-100">
          <div className="mt-4 px-4"><WithPageHeader heading="Project Details"
            buttons={[
              {
                icon: <SearchIcon />,
                style: { paddingLeft: "15px", paddingRight: "15px", gap: "8px", Justify: "center", alignItems: "center" },
              }
            ]} />

          </div>
          <div className="two-panel-flex px-5">
            <div className=" col-md-3">
              <SearchFields setHeadingName={tabChange} match={location} />
            </div>
            <div className="second col-md-9">
              <CrudFields addEnteryIndex={addEnteryMenu} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefinitionManagement;
