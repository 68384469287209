import { Link } from "react-router-dom";
import {  getFormatedDate, getNocProjecyStatusBadge, getStatusBadge } from "../../Utility";
import "../../assets/styles/global.scss";
import { ThreeDots } from "react-bootstrap-icons";
import { formateGeneralStatus } from "../../utils/util";

export const NocColumns = [
  {
    name: "Project Title",
    selector: (row) => (
      <div className="">
        <Link to={`/nocs/${row?.id}/${row?.organizationId}`}>
          {/* {row?.projectTitle} */}
        { row?.extensionCount > 0 ? "Extension : "+row?.projectTitle : row?.projectTitle}
    
        </Link>
      </div>
    ),
  },
  // TODO Missing from API Location, Organization
  {
    name: "Location",
    selector: (row) => row?.districts && Array.isArray(row?.districts) ? row?.districts.map((loc) => loc?.districtName).join(", ") : "",
  },
  {
    name: "Organization",
    selector: (row) => row?.organizationName,
  },
  {
    name: "Project Status",
    selector: (row) => getNocProjecyStatusBadge(row?.projectStatus),
  },
  {
    name: "Type",
    selector: (row) => {
      if(row?.extensionCount > 0){
        return "Extension : "+row?.extensionCount;
      }

      return "Fresh"
    },
  },
  {
    name: "Expiry Date",
    selector: (row) => getFormatedDate(row?.endDate),
  },
  // {
  //   name: "Action",
  //   selector: (row) => <ThreeDots width={30} height={30} />,
  //   width : "80px"
  // },
];
