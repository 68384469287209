import { Link } from "react-router-dom";
export const ReportColumns = [
  {
    name: "Districts",
    selector: (row, index) => row?.districtName,
  },
  {
    name: "Sectors",
    selector: (row) => row?.clusterName,
  },
  {
    name: "Beneficiaries",
    selector: (row) => row?.totalBeneficiaries,
  },
  {
    name: "Active NOC",
    selector: (row) => row?.activeNOC,
  },
  {
    name: "Closed NOC",
    selector: (row) => row?.closedNOC,
  },
  {
    name: "Funds Utilized (in PKR)",
    selector: (row) => `Rs. ${row?.pkrFundsUtilized}`,
  },
  {
    name: "Funds Allocated (in PKR)",
    selector: (row) => `Rs. ${row?.pkrFundsAllocated}`,
  },
  {
    name: "Organizations",
    selector: (row) => row?.organizationCount,
  },
];

