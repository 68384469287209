import React, { useEffect, useState } from "react";
import { PDFDownloadLink, PDFViewer, usePDF } from "@react-pdf/renderer";
import ReportDocumentTemplate from "../document-template/ReportDocumentTemplate";
import "./Certificate.scss";
import { useDispatch, useSelector } from "react-redux";
import { canView, getRoleAsArray, shortenFileName } from "../../utils/util";
import { CloudArrowUp, Download, PlusCircle } from "react-bootstrap-icons";
import { createRecord, getRecords, putRecord } from "../../redux/reducers";
import { handleDownloadFile } from "../../Utility";

const Certificate = ({ id }) => {
  const { role } = useSelector((state) => state?.setting?.user);
  const dispatch = useDispatch();
  const { response, loading, creating, created } = useSelector(
    (state) => state?.records
  );
  const [instance, update] = usePDF({ document: <ReportDocumentTemplate /> });
  const { nocProjects = {}, organizationInfo = {} } = useSelector(
    (state) => state?.records?.current
  );
  const userRoles = role && getRoleAsArray(role);
  const ref = React.useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileURL, setFileURL] = useState(null);
  const scannedDoc = nocProjects?.nocProjectDetail?.nocScannedDoc;
  const fileName = scannedDoc?.split("/").pop();

  useEffect(() => {
    if (created && response?.filePath) {
      dispatch({
        type: putRecord.type,
        payload: {
          url: `/NocApplication/${id}/attachNOCScannedDoc?scannedDoc=${response?.filePath}`,
          isFormatted: true,
          message: "Certificate Uploaded Successfully!",
        },
      });
    }
  }, [created, response]);

  const uploadSignedCertificate = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFileURL(URL.createObjectURL(file));
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      dispatch({
        type: createRecord.type,
        payload: {
          file: { name: "file", value: selectedFile },
          url: `/FileUpload/nocApplication/file`,
          headers: { "Content-Type": "multipart/form-data" },
          message: "Uploading ....",
          // supressMessages: true,
        },
      });
      setSelectedFile(null);
      setFileURL(null);
    }
  };

  const handleUploadClick = () => {
    ref.current.click();
  };
  return (
    <>
      {!canView(["NGO User"], userRoles) && (
        <div
          style={{ height: "100vh", margin: 0, padding: 0, overflow: "hidden" }}
        >
          {instance.loading && (
            <div className="col-md-12 align-middle loader-text">Loading...</div>
          )}
          {iNGOUser(
            userRoles,
            selectedFile,
            fileURL,
            ref,
            handleUploadClick,
            handleUpload,
            uploadSignedCertificate
          )}
          <PDFViewer style={{ width: "100%", height: "100%" }}>
            <ReportDocumentTemplate
              projectInfo={nocProjects?.nocProjectDetail}
              organizationInfo={organizationInfo}
            />
          </PDFViewer>
        </div>
      )}
      {canView(["NGO User"], userRoles) && (
        <div
          style={{ height: "10vh", margin: 0, padding: 0, overflow: "hidden" }}
          className="d-flex align-items-center m-4 gap-4"
        >
          {scannedDoc ? (
            <>
              <p className="mb-0">{fileName}</p>
              <button
                onClick={(e) => handleDownloadFile(e, scannedDoc)}
                className="btn btn-success"
                type="button"
              >
                <Download color="white" style={{ marginRight: "8px" }} />
                Download
              </button>
            </>
          ) : (
            <p className="mb-0">No File Uploaded</p>
          )}
        </div>
      )}
    </>
  );
};

export default Certificate;

const iNGOUser = (
  userRoles,
  selectedFile,
  fileURL,
  ref,
  handleUploadClick,
  handleUpload,
  uploadSignedCertificate
) => {
  return (
    <>
      <div
        style={{
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <div className="d-flex align-items-center justify-content-end mt-3 me-3">
          {selectedFile?.name && shortenFileName(selectedFile?.name, 40)}
          <div>
            <button
              onClick={handleUploadClick}
              className="btn btn-success m-2 float-right"
              type="button"
            >
              {"Select File"}
            </button>
            <input
              ref={ref}
              type="file"
              className="visually-hidden"
              onChange={uploadSignedCertificate}
            />
          </div>
          <div>
            <button
              onClick={handleUpload}
              className="btn btn-success"
              type="button"
            >
              <CloudArrowUp color="white" style={{ marginRight: "8px" }} />
              Upload
            </button>
          </div>
        </div>

        {/* {selectedFile && (
          <div className="mt-3">
            {selectedFile.type.startsWith("image/") && (
              <div className="d-flex flex-column align-items-center">
                <img
                  src={fileURL}
                  alt="Selected"
                  style={{
                    maxWidth: "95vh",
                    height: "95vh",
                    objectFit: "contain",
                  }}
                />
              </div>
            )}
            {selectedFile.type === "application/pdf" && (
              <div className="d-flex flex-column align-items-center">
                <embed
                  src={fileURL}
                  type="application/pdf"
                  style={{
                    width: "100%",
                    height: "auto",
                    aspectRatio: "auto 1/1",
                  }}
                />
              </div>
            )}
          </div>
        )}
        {!selectedFile && (
          <div className="d-flex flex-column align-items-center h-50 mt-5">
            <div className="w-50 h-100 custom-border d-flex flex-column gap-2 align-items-center justify-content-center">
              <PlusCircle onClick={handleUploadClick} size={40} />
              Please upload signed certificate
            </div>
          </div>
        )} */}
      </div>
    </>
  );
};
