import toast from "react-hot-toast";
import { loadUserState } from "./localStorage";
import { confirmAlert } from "react-confirm-alert";

export const isUserLoggedIn = () => {
  let userState = loadUserState();
  if (userState?.token) return true;
  return false;
};

export const getAuthToken = () => {
  let userState = loadUserState();
  return userState?.token;
};

export const getFieldsByCategory = (fields = [], key) => {
  return fields.filter(({ category = "" }) => category === key);
};

export const getError = (message) => {
  try {
    const messageJson =
      typeof message === "string" ? JSON.parse(message) : message;
    let error = "";
    if (typeof messageJson === "object") {
      Object.keys(messageJson).forEach((i) => {
        messageJson[i].forEach((j) => {
          error += j;
        });
      });
    } else if (typeof messageJson === "string") {
      error = message;
    } else error = "Something went wrong, please try again!";
    return error;
  } catch (error) {
    return "Something went wrong, please try again";
  }
};

export const getIntProgressValue = (value) => {
  if (!isNaN(value)) return value;
  else if (typeof value === "string")
    return parseInt(value.replace(/[`%{\}[\]/]/gi, ""));
  // return parseInt(value.replace(/[`%\{\}\[\]\\\/]/gi, ""));
  else return 0;
};

export const getPieFormatedData = (data) => {
  let labels = [],
    values = [];
  if (!data) return { labels, values };
  Object.entries(data)
    .filter(([key, value]) => key !== "total")
    .forEach(([key, value]) => {
      labels = [...labels, key.toUpperCase()];
      values = [...values, value];
    });
  return { labels, values };
};

export const getBarChartFormattedDate = (data = []) => {
  return data.map(({ name, count }) => ({ x: name, y: count }));
};

export const getPieFormatedDataByArray = (data) => {
  let labels = [],
    values = [];
  if (!data) return { labels, values };

  data.forEach((d) => {
    let label =
      typeof Object.values(d)[0] === "number"
        ? Object.values(d)[0]
        : Object.values(d)[0].toUpperCase();
    if (label && label.length > 10) {
      label = truncateText(label, 10);
    }
    labels = [...labels, label];
    values = [...values, getIntProgressValue(Object.values(d)[1])];
  });
  return { labels, values };
};

export const canView = (allowedRoles, CurrentMemberRoles) => {
  if (typeof CurrentMemberRoles === "string")
    CurrentMemberRoles = [CurrentMemberRoles];

  let result = false;
  allowedRoles.forEach((role) => {
    if (
      CurrentMemberRoles &&
      (CurrentMemberRoles.some((res) => res.includes(role)) ||
        CurrentMemberRoles.includes(role))
    ) {
      result = true;
    }
  });

  return result;
};

export const getCurrentUserRole = (user) => user?.roles;

export const truncateText = (lbl, maxNumber) => {
  if (lbl?.length > maxNumber) return lbl?.substring(0, maxNumber) + "...";
  return lbl;
};

export const formateDateTime = (date) => {
  if (!date) return "";
  return date.replace(/T.*/, "").split("-").join("-");
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return isNaN(date) ? "" : date?.toISOString()?.split("T")[0];
};

export const getFormattedArrayBarChartData = (dataX) => {
  const data = dataX?.map((d) => d?.count);
  const formattedLabels = dataX?.map((d) => d?.name);
  return {
    data: [{ data }],
    categories: formattedLabels,
  };
};

export function readFileAsDataUrl(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const fileContent = e.target.result;
      resolve(fileContent);
    };

    reader.onerror = (err) => {
      reject(err.target.error);
    };

    reader.readAsDataURL(file);
  });
}

export function formateStatus(status) {
  let itemStatus, bg_color;
  switch (status) {
    case "Open":
      itemStatus = "Open";
      bg_color = "blue";
      break;
    case "Resolved":
      itemStatus = "Resolved";
      bg_color = "green";
      break;
    case "Pending":
      itemStatus = "Pending";
      bg_color = "#E4701E";
      break;
  }

  return (
    <span
      style={{ backgroundColor: bg_color }}
      className={`badge badge-pill py-1 text-white ml-2`}
    >
      {itemStatus}
    </span>
  );
}

const STATUS_LIST = {
  Ongoing: "Ongoing",
  Pending: "Pending",
  Cancelled: "Cancelled",

  Approved: "Approved",
  Rejected: "Rejected",
  UnderReview: "UnderReview",

  UnApproved: "UnApproved",
  Archived: "Archived",
};

const STATUS_COLOR = {
  Ongoing: "green",
  Pending: "blue",
  Cancelled: "red",

  Approved: "green",
  Rejected: "red",
  UnderReview: "#E4701E",

  UnApproved: "red",
  Archived: "gray",
};

export function formateGeneralStatus(status) {
  let itemStatus, bg_color;
  itemStatus = STATUS_LIST[status];
  bg_color = STATUS_COLOR[status];

  return (
    <span
      style={{ backgroundColor: bg_color }}
      className={`badge badge-pill py-1 text-white ml-2`}
    >
      {itemStatus}
    </span>
  );
}

export function displayFormat(jsonObj) {
  var keyValueList = "";

  for (var key in jsonObj) {
    if (jsonObj.hasOwnProperty(key)) {
      let value = jsonObj[key];
      if (this) {
        value = jsonObj[key][this];
      }

      keyValueList += key + ": " + value + "\n";
    }
  }

  return keyValueList;
}

//string's first letter to lowercase
export function toLowerCaseFirstLetter(string) {
  if (!string) return string;
  return string.charAt(0).toLowerCase() + string.slice(1);
}

//string's first letter to uppercase
export function toUpperCaseFirstLetter(string) {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getRoleAsArray = (role) => {
  return [role];
};

export const formateRecordsDropDown = (
  records,
  key,
  labelAttribute,
  valueAttribute,
  formateToLable = false,
  nameAndValue = false,
  fieldMeta = {}
) => {
  if (!records[labelAttribute]) return {};

  if (formateToLable) {
    return {
      [key]: records[labelAttribute],
    };
  }

  if (nameAndValue) {
    return {
      ...fieldMeta,
      name: key,
      label: records[labelAttribute],
      value: {
        value: records[valueAttribute],
        label: records[labelAttribute],
      },
    };
  }
  return {
    [key]: {
      ...fieldMeta,
      value: records[valueAttribute],
      label: records[labelAttribute],
    },
  };
  // }
};

export const getFieldMeta = (fields, fieldName) => {
  return fields.find(({ name }) => name === fieldName);
};

const colors = {
  1: "#d8f3dc",
  2: "#a5ffd6",
  3: "#00c49a",
  4: "#84c7d0",
  5: "#81f7e5",
};

export const getRandomColor = () => {
  return colors[Math.floor(Math.random() * 5) + 1];
};

export const generalToast = (
  type = "success",
  message = "Successfull!",
  position = "top-center"
) => {
  return toast[type](message, {
    position: position,
  });
};

export const shortenFileName = (name, maxLength = 15) => {
  if (name.length <= maxLength) return name;
  const ext = name.slice(name.lastIndexOf("."));
  const nameWithoutExt = name.slice(0, name.lastIndexOf("."));
  return `${nameWithoutExt.slice(0, maxLength - ext.length - 3)}...${ext}`;
};

export const Alert = (message, buttons) => {
  confirmAlert({
    message,
    buttons,
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlay-custom-class-name",
  });
};
