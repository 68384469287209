import React, { useState } from "react";
import "./ReadableTable.scss";
import {
  ChevronDown,
  ChevronRight,
  DashLg,
  PlusCircleFill,
  PlusLg,
} from "react-bootstrap-icons";

const ReadableTable = ({ headers, data, label, topHeader, isCollapsible }) => {
  const [collapsedRows, setCollapsedRows] = useState({});

  const toggleRowCollapse = (rowIndex) => {
    setCollapsedRows((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex],
    }));
  };

  let subHeaders = ["Sr.", ...headers];
  return (
    <div className="my-3 rd-tb">
      {label && <p className="table-label">{label}</p>}
      <table className="table table-bordered office-table">
        <thead>
          {topHeader && (
            <tr>
              <th className="text-center top-header" colSpan={headers?.length}>
                {topHeader}
              </th>
            </tr>
          )}
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className={`bg-light  ${
                  index === 0
                    ? "rounded-start"
                    : index === headers.length - 1
                    ? "rounded-end"
                    : ""
                }
                  `}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length === 0 && (
            <tr>
              <td colSpan={headers?.length} className="text-center">
                No data found
              </td>
            </tr>
          )}

          {data.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <tr>
                {Object.values(row).map((value, cellIndex) => (
                  <>
                    {!Array.isArray(value) && (
                      <td key={cellIndex}>
                        {cellIndex === 0 && isCollapsible && (
                          <button
                            className="btn btn-success btn-sm"
                            onClick={() => toggleRowCollapse(rowIndex)}
                          >
                            {collapsedRows[rowIndex] ? (
                              <PlusLg color="white" />
                            ) : (
                              <DashLg color="white" />
                            )}
                          </button>
                        )}
                        {value}
                      </td>
                    )}
                  </>
                ))}
              </tr>
              {isCollapsible && !collapsedRows[rowIndex] && (
                <tr key={`${rowIndex}-detail`} className="border-0">
                  <td
                    colSpan={subHeaders?.length}
                    className="px-0 py-0 border-0"
                  >
                    <table className="table table-bordered office-table">
                      {Object.values(row).map(
                        (value, cellIndex) =>
                          Array.isArray(value) &&
                          value.length > 0 && (
                            <thead>
                              <tr className="border">
                                {subHeaders?.map((header, index) => (
                                  <th
                                    key={index}
                                    className={`bg-light subActivity border-0  ${
                                      index === 0
                                        ? "rounded-start"
                                        : index === subHeaders?.length - 1
                                        ? "rounded-end"
                                        : ""
                                    }
                                  `}
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                          )
                      )}
                      <tbody>
                        {Object.values(row).map((value, cellIndex) =>
                          Array.isArray(value)
                            ? value.map((subActivity, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{subActivity?.workType}</td>
                                  <td>{subActivity?.description}</td>
                                  <td>{subActivity?.startDate}</td>
                                  <td>{subActivity?.endDate}</td>
                                  <td>{subActivity?.responsible}</td>
                                  <td>{subActivity?.verificationMean}</td>
                                </tr>
                              ))
                            : null
                        )}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReadableTable;

{
  /* <td>{subActivity?.workPlan}</td> */
}
