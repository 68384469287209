import React, { useEffect, useState } from "react";
import WithPageHeader from "../../components/ui/withPageHeader";
import { FormStepper, Step } from "../../components/form-stepper/FormStepper";
import { useDispatch, useSelector } from "react-redux";
import {
  clearForm,
  createRecord,
  formatRecords,
  getRecords,
  putRecord,
  resetData,
  setStepper,
  setUserMessages,
} from "../../redux/reducers";
import { geographicLocationFields } from "../../assets/fields/geographicLocationFields";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProjectSummaryForm from "../../components/applications/form/ProjectSummaryForm";
import WorkAndPlanForm from "../../components/applications/form/WorkAndPlanForm";
import ProjectDetailForm from "../../components/applications/form/ProjectDetailForm";
import SupportingDocumentForm from "../../components/applications/form/SupportingDocumentForm";
import ApplicationConfirmModal from "../../components/applications/ApplicationConfirmModal";
import "./Applications.scss";
import { postRecordAsync } from "../../redux/sagas/post";
import Loader from "../../components/loader/loader";
import {
  formateRecordsDropDown,
  getFieldMeta,
  getFieldsByCategory,
} from "../../utils/util";
import { applicationFields } from "../../assets/fields/applicationFields";
import { directBenefFields } from "../../assets/fields/directBenefFields";
import {
  getConditionalFields,
  validateForm,
} from "../../components/form/form.helper";
import { getStepFields } from "../../Utility";
import toast from "react-hot-toast";

const AddUpdateApplications = () => {
  const selectedSteps = {
    0: "Project Summary",
    // 1: "Work Place & Logical Framework",
    1: "Work Plan & Logical Framework",
    2: "Project Details",
    3: "Supporting Documents",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();
  let canUpdate = false;
  if (id) canUpdate = true;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isExtension = queryParams.get("isExtension") === "true";

  const { currentStep, totalSteps } = useSelector(
    (state) => state?.setting?.stepper
  );
  const { values } = useSelector((state) => state.form);
  const { data, created, loading, creating, response } = useSelector(
    (state) => state?.records
  );

  const { nocApplicationUpdateData } = useSelector(
    (state) => state?.records?.current
  );
  const [isAgreed, setIsAgreed] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const isLastStep = currentStep === totalSteps - 1;

  const [isMergedArea, setIsMergedArea] = useState(false);
  const formValues = useSelector((state) => state.form?.values);
  const selectedDistricts = formValues?.geographicLocations?.value;
  const districtsData = useSelector((state) => state.form?.lookups?.districtId);
  const mergedDistricts = districtsData?.filter((item) => item?.isPartOfMergedDistrict);

  useEffect(() => {
    getIsMergedDistrict();
  }, [selectedDistricts, districtsData])



  useEffect(() => {
    if (created && response?.id) {
      navigate(`/applications/update/${response?.id}`);
    }

    if (created && isLastStep) {
      navigate("/applications");
    }
    return () => {
      if (created) dispatch({ type: resetData.type });
    };
  }, [created]);

  useEffect(() => {
    if (created && !isLastStep) {
      dispatch({
        type: setStepper.type,
        payload: { currentStep: currentStep + 1 },
      });
    }
  }, [created]);

  useEffect(() => {
    if (id) {
      dispatch({
        type: getRecords.type,
        payload: {
          url: isExtension
            ? `/NocApplication/${id}/GetNocProjectAndExtensionDetails`
            : `/NocApplication/${id}/GetNocApplicationDetails`,
          appendCurrentReducerKey: "nocApplicationUpdateData",
        },
      });
    } else {
      dispatch({ type: resetData.type });
      dispatch({ type: clearForm.type });
    }
  }, [id, currentStep]);

  useEffect(() => {
    if (nocApplicationUpdateData && id) {
      let modifiedData = isExtension
        ? {
          ...nocApplicationUpdateData?.nocProjectDetail,
          ...nocApplicationUpdateData?.nocProjectDetail?.fundDetail,
          ...nocApplicationUpdateData?.nocProjectDetail?.projectCost,
        }
        : {
          ...nocApplicationUpdateData,
          ...nocApplicationUpdateData?.fundDetail,
          ...nocApplicationUpdateData?.projectCost,
          amountInPKR:
            nocApplicationUpdateData?.projectCost?.amountInPKR?.toString(),
          amountinUSD:
            nocApplicationUpdateData?.projectCost?.amountinUSD?.toString(),
        };
      let fundTypeId = formateRecordsDropDown(
        modifiedData,
        "fundTypeId",
        "fundType",
        "fundTypeId"
      );

      let fundsCurrencyId = formateRecordsDropDown(
        modifiedData,
        "fundsCurrencyId",
        "fundCurrencyName",
        "fundsCurrencyId"
      );
      //geogaphic Location
      const districtMeta = getFieldMeta(geographicLocationFields, "districtId");
      let geographicLocation = modifiedData?.geographicLocations?.map((i) => {
        const districtFieldData = formateRecordsDropDown(
          i,
          "districtId",
          "districtName",
          "districtId",
          false,
          true,
          districtMeta
        );

        return {
          districtId: {
            ...districtFieldData,
          },
          tehsil: {
            ...getFieldMeta(geographicLocationFields, "tehsil"),
            value: i.tehsil,
            label: i.tehsil,
          },
          unionCouncil: {
            ...getFieldMeta(geographicLocationFields, "unionCouncil"),
            value: i.unionCouncil,
            label: i.unionCouncil,
          },
          village: {
            ...getFieldMeta(geographicLocationFields, "village"),
            value: i.village,
            label: i.village,
          },
          edit: false,
        };
      });

      //beneficiaries

      const clusterIdMeta = getFieldMeta(directBenefFields, "clusterId");
      const benefiDistrictMeta = getFieldMeta(directBenefFields, "districtId");
      const projectbeneficiaries = modifiedData?.projectBeneficiaries?.map(
        (i) => {
          const sectorFieldData = formateRecordsDropDown(
            i,
            "clusterId",
            "clusterName",
            "clusterId",
            false,
            true,
            clusterIdMeta
          );

          const districtFieldData = formateRecordsDropDown(
            i,
            "districtId",
            "districtName",
            "districtId",
            false,
            true,
            benefiDistrictMeta
          );

          return {
            clusterId: {
              ...sectorFieldData,
            },
            districtId: {
              ...districtFieldData,
            },

            houseHolds: {
              ...getFieldMeta(directBenefFields, "houseHolds"),
              value: i.houseHolds,
              label: i.houseHolds,
            },

            noOfMales: {
              ...getFieldMeta(directBenefFields, "noOfMales"),
              value: i.noOfMales,
              label: i.noOfMales,
            },
            noOfFemales: {
              ...getFieldMeta(directBenefFields, "noOfFemales"),
              value: i.noOfFemales,
              label: i.noOfFemales,
            },
            edit: false,
          };
        }
      );
      //Work Plan

      //logical framework
      let logicalFramework = modifiedData?.logicalFrameworks?.map((i) => {
        return {
          narrativeSummery: formateRecordsDropDown(
            i,
            "narrativeSummery",
            "narrativeSummery",
            "narrativeSummery",
            false,
            true
          ),
          resultType: formateRecordsDropDown(
            i,
            "resultType",
            "resultType",
            "resultType",
            false,
            true
          ),
          expectedResult: {
            name: "expectedResult",
            value: i.expectedResult,
            label: i.expectedResult,
          },
          performanceMeasure: {
            name: "performanceMeasure",
            value: i.performanceMeasure,
            label: i.performanceMeasure,
          },
          assumptionsAndRisks: {
            name: "assumptions , risks",
            value: i.assumptionsAndRisks,
            label: i.assumptionsAndRisks,
          },
          edit: false,
        };
      });

      let operationalCost = (
        modifiedData?.projectCost?.operationalCost?.value ??
        modifiedData?.projectCost?.operationalCost
      )?.map((i) => {
        return {
          ...i,
          sector: { label: i.clusterName, value: i.clusterId },
          district: { label: i.districtName, value: i.districtId },
        };
      });
      let updatedModifiedData = {
        ...modifiedData,
        ...fundTypeId,
        ...fundsCurrencyId,
        logicalFrameworks: logicalFramework,
        geographicLocations: geographicLocation,
        projectBeneficiaries: projectbeneficiaries,
        operationalCost: operationalCost,
        projectCost: {
          ...modifiedData.projectCost,
          operationalCost,
        },
      };
      dispatch({
        type: formatRecords.type,
        payload: { ...updatedModifiedData },
      });
    }
  }, [nocApplicationUpdateData]);

  const updateStep = () => {
    dispatch({
      type: "setting/setStepper",
      payload: { currentStep: currentStep + 1 },
    });
  };

  const getIsMergedDistrict = () => {

    for (let j = 0; j < mergedDistricts?.length; j++) {
      for (let i = 0; i < selectedDistricts?.length; i++) {

        const item = selectedDistricts[i];

        if (item?.districtId?.value?.value === mergedDistricts[j]?.id) {
          return setIsMergedArea(true)

        }

         setIsMergedArea(false);


      }
    }
  }

  const params = useSelector((state) => state?.form?.values);

  const submitApplication = () => {
    const parameters = {
      projectTitle: params.projectTitle?.value,
      ...(params?.fundTypeId?.value
        ? {
          fundDetail: {
            fundTypeId: params?.fundTypeId?.value?.value,
            fundTypeName: params?.fundTypeId?.value?.label,
            donorType: params?.donorType?.value?.toString(),
            mouDoc: params?.mouDoc?.value
              ? params?.mouDoc?.value
              : params?.mouDoc,
            mouStartDate: params?.mouStartDate?.value,
            mouEndDate: params?.mouEndDate?.value,
          },
        }
        : {}),

      projectClassifications: params?.projectClassifications?.value,
      responsePlans: params?.responsePlans?.value,
      applicationClusters: params?.applicationClusters?.value,
      geographicLocations: params?.geographicLocations?.value?.map((i) => {
        return {
          ...i,
          districtId: i?.districtId?.value?.value,
          tehsil: i?.tehsil?.value,
          unionCouncil: i?.unionCouncil?.value,
          village: i?.village?.value,
        };
      }),
      thematicAreas: params?.thematicAreas?.value,
      projectBeneficiaries: params?.projectBeneficiaries?.value?.map((i) => {
        return {
          ...i,
          clusterId: i?.clusterId?.value?.value,
          districtId: i?.districtId?.value?.value,
          houseHolds: i?.houseHolds?.value,
          noOfFemales: i?.noOfFemales?.value,
          noOfMales: i?.noOfMales?.value,
        };
      }),
      ...(params?.amountinUSD?.value || params?.amountInPKR?.value
        ? {
          projectCost: {
            fundsCurrencyId: params?.fundsCurrencyId?.value?.value,
            fundsCurrencyName: params?.fundsCurrencyId?.value?.label,
            ...(params?.amountinUSD?.value && {
              amountinUSD: parseInt(params?.amountinUSD?.value),
            }),
            ...(params?.amountInPKR?.value && {
              amountInPKR: parseInt(params?.amountInPKR?.value),
            }),
            totalPercentage: params?.totalPercentage?.value,
            remarks: params?.remarks?.value,
            operationalCost: params?.operationalCost?.value?.map((item) => ({
              clusterId: item?.sector?.value,
              districtId: item?.district?.value,
              amountinUSD: parseInt(item?.amountinUSD),
              ...(item?.amountInPKR && {
                amountInPKR: parseInt(item?.amountInPKR),
              }),
              totalPercentage: item?.totalPercentage,
              remarks: item?.remarks,
            })),
          },
        }
        : {}),
      projectStartDate: params?.projectStartDate?.value,
      projectEndDate: params?.projectEndDate?.value,
      //step2
      workPlans: params?.workPlans?.value,
      logicalFrameworks: params?.logicalFrameworks?.value.map((i) => {
        return {
          ...i,
          narrativeSummery:
            typeof i?.narrativeSummery === "object"
              ? i?.narrativeSummery?.value?.value?.toString()
              : i?.narrativeSummery,
          resultType: i?.resultType?.value?.value?.toString(),
          expectedResult: i?.expectedResult?.value,
          performanceMeasure: i?.performanceMeasure?.value,
          assumptionsAndRisks: i?.assumptionsAndRisks?.value,
        };
      }),
      //step3
      projectBackground: params?.projectBackground?.value,
      projectRational: params?.projectRational?.value,
      projectGoal: params?.projectGoal?.value,
      implementationStratergy: params?.implementationStratergy?.value,
      monitoringStratergy: params?.monitoringStratergy?.value,
      outcome: params?.outcome?.value,
      cordinationMechanism: params?.cordinationMechanism?.value,
      projectSummary: params?.projectSummary?.value,
      //step4
      applicationDocuments: params?.applicationDocuments?.value,
      agreementStartDate: params?.agreementStartDate?.value,
      agreementEndDate: params?.agreementEndDate?.value,
    };

    dispatch({
      type: createRecord.type,
      payload: {
        ...parameters,
        isDraft: true,
        url: "/NocApplication",
        isFormatted: true,
      },
    });
  };

  function updateApplication() {
    const parameters = {
      projectTitle: params.projectTitle?.value,
      ...(params?.fundTypeId?.value ||
        params?.donorType?.value ||
        params?.mouDoc?.value ||
        params?.mouStartDate?.value ||
        params?.mouEndDate?.value
        ? {
          fundDetail: {
            fundTypeId: params?.fundTypeId?.value?.value,
            fundTypeName: params?.fundTypeId?.value?.label,
            ...(params?.donorType?.value && {
              donorType: params?.donorType?.value.toString(),
            }),
            ...(params?.mouDoc?.value && {
              mouDoc: params?.mouDoc?.value,
            }),
            ...(typeof params?.mouDoc === "string" && {
              mouDoc: params?.mouDoc,
            }),
            ...(params?.mouStartDate?.value && {
              mouStartDate: params?.mouStartDate?.value,
            }),
            ...(params?.mouEndDate?.value && {
              mouEndDate: params?.mouEndDate?.value,
            }),
          },
        }
        : {}),
      projectClassifications: params?.projectClassifications?.value,
      responsePlans: params?.responsePlans?.value,
      applicationClusters: params?.applicationClusters?.value,
      geographicLocations: params?.geographicLocations?.value?.map((i) => {
        return {
          ...i,
          districtId: i?.districtId?.value?.value,
          tehsil: i?.tehsil?.value,
          unionCouncil: i?.unionCouncil?.value,
          village: i?.village?.value,
        };
      }),
      thematicAreas: params?.thematicAreas?.value,
      projectBeneficiaries: params?.projectBeneficiaries?.value?.map((i) => {
        return {
          ...i,
          clusterId: i?.clusterId?.value?.value,
          districtId: i?.districtId?.value?.value,
          houseHolds: i?.houseHolds?.value,
          noOfFemales: i?.noOfFemales?.value,
          noOfMales: i?.noOfMales?.value,
        };
      }),
      ...(params?.amountinUSD?.value || params?.amountInPKR?.value
        ? {
          projectCost: {
            fundsCurrencyId: params?.fundsCurrencyId?.value?.value,
            fundsCurrencyName: params?.fundsCurrencyId?.value?.label,
            amountinUSD: parseInt(params?.amountinUSD?.value) ?? 0,
            amountInPKR: parseInt(params?.amountInPKR?.value) ?? 0,
            totalPercentage: params?.totalPercentage?.value,
            remarks: params?.remarks?.value,
            operationalCost: params?.operationalCost?.value?.map((item) => ({
              clusterId: item?.sector?.value || item?.clusterId,
              districtId: item?.district?.value || item?.districtId,
              amountinUSD: parseInt(item?.amountinUSD),
              amountInPKR: parseInt(item?.amountInPKR),
              totalPercentage: item?.totalPercentage,
              remarks: item?.remarks,
            })),
          },
        }
        : {}),
      projectStartDate: params?.projectStartDate?.value,
      projectEndDate: params?.projectEndDate?.value,
      workPlans: params?.workPlans?.value,
      logicalFrameworks: params?.logicalFrameworks?.value.map((i) => {
        return {
          ...i,
          narrativeSummery: i?.narrativeSummery?.value?.value?.toString(),
          resultType: i?.resultType?.value?.value?.toString(),
          expectedResult: i?.expectedResult?.value,
          performanceMeasure: i?.performanceMeasure?.value,
          assumptionsAndRisks: i?.assumptionsAndRisks?.value,
        };
      }),
      projectBackground: params?.projectBackground?.value || "",
      projectRational: params?.projectRational?.value || "",
      projectGoal: params?.projectGoal?.value || "",
      implementationStratergy: params?.implementationStratergy?.value || "",
      monitoringStratergy: params?.monitoringStratergy?.value || "",
      outcome: params?.outcome?.value || "",
      cordinationMechanism: params?.cordinationMechanism?.value || "",
      projectSummary: params?.projectSummary?.value || "",
      applicationDocuments: params?.applicationDocuments?.value,
      agreementStartDate: params?.agreementStartDate?.value,
      agreementEndDate: params?.agreementEndDate?.value,
    };

    console.log("🚀 ~ file: AddUpdateApplication.js:559 ~ updateApplication ~ parameters:", parameters);
    dispatch({
      type: putRecord.type,
      payload: {
        ...parameters,
        isSubmit: isLastStep ? true : false,
        url: `/NocApplication/${id}`,
        isFormatted: true,
      },
    });
  }

  const validateStep = (callback) => {
    const steps = getStepFields;
    const fields = steps[currentStep];
    const isValid = validateForm(fields, values, dispatch);
    if (isValid) {
      callback();
    }
  };

  return (
    <div className="px-4 py-2 mt-5">
      <WithPageHeader
        heading={`New NOC Application`}
        text={`${selectedSteps[currentStep]}`}
        buttons={[]}
      />
      <div className=" mt-3">
        <Loader loading={loading || creating} />
        <FormStepper
          fields={applicationFields}
          data={values}
          initialStep={0}
          onNext={() =>
            canUpdate
              ? validateStep(updateApplication)
              : validateStep(submitApplication)
          }
          onSubmit={() => isMergedArea ? setModalShow(true) : validateStep(updateApplication)}
        >
          <Step key="projectSummary" title="Project Summary">
            <ProjectSummaryForm id={id ?? null} />
          </Step>
          <Step
            key="workPlanLogicalFramework"
            title="Work Plan & Logical Framework"
          >
            <WorkAndPlanForm extension={isExtension ?? false} />
          </Step>
          <Step key="projectDetais" title="Project Details">
            <ProjectDetailForm />
          </Step>
          <Step key="supportingDocuments" title="Supporting Documents">
            <SupportingDocumentForm />
          </Step>
        </FormStepper>
      </div>

      <ApplicationConfirmModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        title={"Submit Your NOC Application"}
      >
        <p className="m-0 mt-4">
          Please print and submit a physical copy of your NOC application along
          with the following items to this department for provision regarding
          security clearance from TDP Secretariat.
        </p>
        <ol type="1" className="mt-3">
          <li className="numbered-list">Original Cover Letter</li>
          <li className="numbered-list">
            Legal Status/Registration Certificate
          </li>
          <li className="numbered-list">
            Proof of Registration (Social Welfare Department/SECP)
          </li>
          <li className="numbered-list">MOU with EAD/Ministry of Interior</li>
          <li className="numbered-list">One Page Summary (Project Details)</li>
          <li className="numbered-list">
            Project-specific MOU/Acknowledgment with EAD
          </li>
          <li className="numbered-list">Annexes (A, B, C)</li>
          <li className="numbered-list">CNIC/Passport Copies</li>
          <li className="numbered-list">
            Donor Agreement with Implementing Partner (IP) or Letter of Intent
          </li>
          <li className="numbered-list">Detailed Budget Breakdown</li>
          <li className="numbered-list">Audit Report</li>
          <li className="numbered-list">Tax Return Certificate</li>
          <li className="numbered-list">Proposed Staff List</li>
          <li className="numbered-list">Work Plan</li>
          <li className="numbered-list">
            Progress Report (counter-signed by ADC (RELIEF & HR) or Line
            department)
          </li>
          <li className="numbered-list">Affidavit on Stamp Paper</li>
        </ol>

        <div className="d-flex align-items-center gap-3">
          <input
            type="checkbox"
            checked={isAgreed}
            onChange={(e) => setIsAgreed(e.target.checked)}
            style={{ width: "16px", height: "16px" }}
          />
          <p className="m-0">I have understood the requirements</p>
        </div>
        <div className="d-flex justify-content-end gap-3">
          <button
            onClick={() => {
              setIsAgreed(false);
              setModalShow(false);
            }}
            className="btn-backward w-25"
          >
            CANCEL
          </button>
          <button
            onClick={() => {
              updateApplication();
              setModalShow(false);
            }}
            className={`${isAgreed ? "btn-forward" : "btn-backward disabled"
              } w-25`}
            disabled={!isAgreed}
          >
            {creating ? (
              <div class="spinner-border text-white" role="status"></div>
            ) : (
              "OK"
            )}
          </button>
        </div>
      </ApplicationConfirmModal>
    </div>
  );
};

export default AddUpdateApplications;
