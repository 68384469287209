export const signupAndOrganizationFields = [
  {
    category: "personalInformation",
    children: "Personal Information",
    inputType: "h4",
    inForm: true,
    inResult: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "personalInformation",
    label: "User Name",
    placeholder: "User Name",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "name",
    wrapperClass: "col-md-12",
    validation: {
      required: true,
    },
  },
  {
    category: "personalInformation",
    label: "Phone",
    placeholder: "Phone",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "phone",
    wrapperClass: "col-md-6",
    validation: {
      required: true,
      isNumber: true,
    },
  },
  {
    category: "personalInformation",
    label: "Email",
    placeholder: "Email",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "email",
    wrapperClass: "col-md-6",
    validation: {
      required: true,
      isEmail: true,
    },
  },
  {
    category: "personalInformation",
    label: "Password",
    placeholder: "Password",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "password",
    type: "password",
    wrapperClass: "col-md-6",
    validation: {
      required: true,
    },
  },
  {
    category: "personalInformation",
    label: "Confirm Password",
    placeholder: "Confirm Password",
    inSearch: true,
    inForm: true,
    inResult: true,
    type: "password",
    name: "userConfirmPassowrd",
    wrapperClass: "col-md-6",
    validation: {
      required: true,
      mustMatch: "password",
    },
  },
  {
    category: "organizationInformation",
    children: "Organization Information",
    inputType: "h4",
    inForm: true,
    inResult: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "organizationInformation",
    label: "Organization Name",
    placeholder: "Enter your organization name",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.name",
    wrapperClass: "col-md-12",
    validation: {
      required: true,
    },
  },
  {
    category: "organizationInformation",
    label: "Role in Organization",
    placeholder: "Enter your Role in Organization",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.organizationRole",
    wrapperClass: "col-md-6",
    validation: {
      required: true,
    },
  },
  {
    category: "organizationInformation",
    label: "Type of Organization",
    placeholder: "Select type of Organization",
    name: "userOrganization.orgTypeId",
    id: "userOrganization.orgTypeId",
    inputType: "async-dropdown",
    url: "/OrganizationType",
    searchAttrib: "Search.value",
    labelAttribute: "type",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    isMulti: false,
    wrapperClass: "col-md-6",
    validation: {
      required: true,
    },
  },
  {
    category: "organizationInformation",
    label: "NTN Number",
    placeholder: "Enter NTN Number",
    type: "number",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.ntnNumber",
    wrapperClass: "col-md-6",
    validation: {
      required: true,
    },
  },
  {
    category: "organizationInformation",
    label: "Pervious Year's Return",
    placeholder: "Upload Pervious Year's Return",
    inputType: "file",
    name: "userOrganization.previousYearReturnsDoc",
    id: "userOrganization.organizationPerviousYearsReturn",
    onChangeMethod: "onFileChange",
    inForm: true,
    wrapperClass: "col-md-6",
    validation: {
      required: true,
    },
  },
  {
    category: "organizationInformation",
    label: "Year of Tax Return",
    placeholder: "i:e 2023",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.yearOfTaxReturn",
    wrapperClass: "col-md-6",
    validation: {
      required: true,
    },
  },

  {
    category: "organizationInformation",
    label: "Upload Financial Audit Report",
    placeholder: "Upload Financial Audit Report (Last Year)",
    inputType: "file",
    name: "userOrganization.financialAuditReportDoc",
    id: "userOrganization.financialAuditReportDoc",
    onChangeMethod: "onFileChange",
    inForm: true,
    wrapperClass: "col-md-6",
    validation: {
      required: true,
    },
  },
  {
    category: "organizationInformation",
    label: "Financial Audit Report Year",
    placeholder: "i:e 2024",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.financialAuditReportYear",
    wrapperClass: "col-md-6",
    validation: {
      required: true,
    },
  },
  {
    category: "organizationInformation",
    label: "Working Area in Pakistan",
    placeholder: "Enter Working Area in Pakistan",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.workingAreaInPakistan",
    wrapperClass: "col-md-6",
    validation: {
      required: true,
    },
  },

  {
    category: "essentialContacts",
    children: "Essential Contacts",
    inputType: "h4",
    inForm: true,
    inResult: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "essentialContacts",
    children: "Head of Organization",
    inputType: "p",
    inForm: true,
    inResult: true,
    wrapperClass: "col-md-12 mt-4",
    className: "text-regular",
  },
  {
    category: "essentialContacts",
    label: "Name",
    placeholder: "Enter Name",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.userOrganizationContacts[0].name",
    wrapperClass: "col-md-4",
    validation: {
      required: true,
    },
  },
  {
    category: "essentialContacts",
    label: "Email",
    placeholder: "Enter Email",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.userOrganizationContacts[0].email",
    wrapperClass: "col-md-4",
    validation: {
      required: true,
      isEmail: true,
    },
  },
  {
    category: "essentialContacts",
    label: "Designation",
    placeholder: "Enter Designation",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.userOrganizationContacts[0].designation",
    wrapperClass: "col-md-4",
    validation: {
      required: true,
    },
  },
  {
    category: "essentialContacts",
    label: "Phone No",
    placeholder: "Enter Phone No",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.userOrganizationContacts[0].phone",
    wrapperClass: "col-md-4",
    validation: {
      required: true,
      isNumber: true,
    },
  },
  {
    category: "essentialContacts",
    label: "Mobile No",
    placeholder: "Enter Mobile No",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.userOrganizationContacts[0].mobile",
    wrapperClass: "col-md-4",
    validation: {
      required: true,
      isNumber: true,
    },
  },
  {
    category: "essentialContacts",
    label: "CNIC Copy",
    placeholder: "Upload Cnic Copy",
    inputType: "file",
    name: "userOrganization.userOrganizationContacts[0].cnicDoc",
    id: "userOrganization.userOrganizationContacts[0].cnicDoc",
    onChangeMethod: "onFileChange",
    inForm: true,
    wrapperClass: "col-md-4",
    validation: {
      required: true,
    },
  },
  {
    category: "essentialContacts",
    children: "Focal Person Information",
    inputType: "p",
    inForm: true,
    inResult: true,
    wrapperClass: "col-md-12 mt-4",
    className: "text-regular",
  },
  {
    category: "essentialContacts",
    label: "Name",
    placeholder: "Enter Name",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.userOrganizationContacts[1].name",
    wrapperClass: "col-md-4",
    validation: {
      required: true,
    },
  },
  {
    category: "essentialContacts",
    label: "Email",
    placeholder: "Enter Email",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.userOrganizationContacts[1].email",
    wrapperClass: "col-md-4",
    validation: {
      required: true,
      isEmail: true,
    },
  },
  {
    category: "essentialContacts",
    label: "Designation",
    placeholder: "Enter Designation",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.userOrganizationContacts[1].designation",
    wrapperClass: "col-md-4",
    validation: {
      required: true,
    },
  },
  {
    category: "essentialContacts",
    label: "Phone No",
    placeholder: "Enter Phone No",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.userOrganizationContacts[1].phone",
    wrapperClass: "col-md-4",
    validation: {
      required: true,
      isNumber: true,
    },
  },
  {
    category: "essentialContacts",
    label: "Mobile No",
    placeholder: "Enter Mobile No",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.userOrganizationContacts[1].mobile",
    wrapperClass: "col-md-4",
    validation: {
      required: true,
      isNumber: true,
    },
  },
  {
    category: "essentialContacts",
    label: "CNIC Copy",
    placeholder: "Upload Cnic Copy",
    inputType: "file",
    name: "userOrganization.userOrganizationContacts[1].CnicDoc",
    id: "userOrganization.userOrganizationContacts[1].CnicDoc",
    onChangeMethod: "onFileChange",
    inForm: true,
    wrapperClass: "col-md-4",
    validation: {
      required: true,
    },
  },
  {
    category: "organizationRegistrationDetails",
    children: "Organization's Registration Details",
    inputType: "h4",
    inForm: true,
    inResult: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  // Todo in case of NGO
  {
    category: "organizationRegistrationDetails",
    label: "Registration Jurisdiction",
    placeholder: "Enter Registration Jurisdiction",
    name: "userOrganization.registeredLocation",
    onChangeMethod: "text-in-table",
    inForm: true,
    validation: {
      required: true,
    },
    conditions: [
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "iNGO",
        action: "hide",
        hideOnLabel: true,
      },
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "UN Agencies",
        action: "hide",
        hideOnLabel: true,
      },
    ],
  },
  {
    category: "organizationRegistrationDetails",
    label: "Upload Registration Certificate",
    name: "userOrganization.registrationCertificateDoc",
    id: "userOrganization.registrationCertificateDoc",
    onChangeMethod: "onFileChange",
    inputType: "file",
    placeholder: "Registration Certificate",
    inForm: true,
    validation: {
      required: true,
    },
    conditions: [
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "iNGO",
        action: "hide",
        hideOnLabel: true,
      },
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "UN Agencies",
        action: "hide",
        hideOnLabel: true,
      },
    ],
  },
  {
    category: "organizationRegistrationDetails",
    label: "Start Date of Registration",
    placeholder: "Choose Start Date of Registration",
    name: "userOrganization.regStartDate",
    inForm: true,
    inputType: "date",
    validation: {
      required: true,
    },
    conditions: [
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "iNGO",
        action: "hide",
        hideOnLabel: true,
      },
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "UN Agencies",
        action: "hide",
        hideOnLabel: true,
      },
    ],
  },
  {
    category: "organizationRegistrationDetails",
    label: "End Date of Registration",
    placeholder: "Choose End Date of Registration",
    name: "userOrganization.regEndDate",
    inForm: true,
    inputType: "date",
    validation: {
      required: true,
    },
    conditions: [
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "iNGO",
        action: "hide",
        hideOnLabel: true,
      },
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "UN Agencies",
        action: "hide",
        hideOnLabel: true,
      },
    ],
  },
  {
    category: "organizationRegistrationDetails",
    label: "Registration Jurisdiction (optional)",
    placeholder: "Enter Registration Jurisdiction",
    name: "userOrganization.registeredLocation1",
    inForm: true,
    conditions: [
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "iNGO",
        action: "hide",
        hideOnLabel: true,
      },
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "UN Agencies",
        action: "hide",
        hideOnLabel: true,
      },
    ],
  },
  {
    category: "organizationRegistrationDetails",
    label: "Upload Registration Certificate (optional)",
    name: "userOrganization.registrationCertificateDoc1",
    id: "userOrganization.registrationCertificateDoc1",
    onChangeMethod: "onFileChange",
    inputType: "file",
    placeholder: "Registration Certificate",
    inForm: true,
    conditions: [
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "iNGO",
        action: "hide",
        hideOnLabel: true,
      },
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "UN Agencies",
        action: "hide",
        hideOnLabel: true,
      },
    ],
  },
  {
    category: "organizationRegistrationDetails",
    label: "Start Date of Registration (optional)",
    placeholder: "Choose Start Date of Registration",
    name: "userOrganization.regStartDate1",
    inForm: true,
    inputType: "date",
    conditions: [
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "iNGO",
        action: "hide",
        hideOnLabel: true,
      },
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "UN Agencies",
        action: "hide",
        hideOnLabel: true,
      },
    ],
  },
  {
    category: "organizationRegistrationDetails",
    label: "End Date of Registration (optional)",
    placeholder: "Choose End Date of Registration",
    name: "userOrganization.regEndDate1",
    inForm: true,
    inputType: "date",
    conditions: [
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "iNGO",
        action: "hide",
        hideOnLabel: true,
      },
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "UN Agencies",
        action: "hide",
        hideOnLabel: true,
      },
    ],
  },

  // in case of iNGO
  {
    category: "organizationRegistrationDetails",
    label: "MoU with MoI",
    placeholder: "Upload Upload MOU with MOI",
    inputType: "file",
    name: "userOrganization.mouMoiDoc",
    id: "userOrganization.mouMoiDoc",
    onChangeMethod: "onFileChange",
    inForm: true,
    wrapperClass: "col-md-6",
    validation: {
      required: true,
    },
    conditions: [
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "NGO",
        action: "hide",
        hideOnLabel: true,
      },
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "UN Agencies",
        action: "hide",
        hideOnLabel: true,
      },
    ],
  },
  {
    category: "organizationRegistrationDetails",
    label: "Start Date of MOU",
    placeholder: "Enter Start Date of MOU",
    name: "userOrganization.mouStartDate",
    inForm: true,
    wrapperClass: "col-md-6",
    inputType: "date",
    validation: {
      required: true,
    },
    conditions: [
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "NGO",
        action: "hide",
        hideOnLabel: true,
      },
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "UN Agencies",
        action: "hide",
        hideOnLabel: true,
      },
    ],
  },
  {
    category: "organizationRegistrationDetails",
    label: "End Date of MOU",
    placeholder: "Enter End Date of MOU",
    name: "userOrganization.mouEndDate",
    inForm: true,
    wrapperClass: "col-md-6",
    inputType: "date",
    validation: {
      required: true,
      compareWith: "userOrganization.mouStartDate",
      isEndDate: true,
    },
    conditions: [
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "NGO",
        action: "hide",
        hideOnLabel: true,
      },
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "UN Agencies",
        action: "hide",
        hideOnLabel: true,
      },
    ],
  },

  // in case of UN Agencies
  {
    category: "organizationRegistrationDetails",
    label: "MOU with Govt of Pakistan",
    placeholder: "Upload MOU with Govt of Pakistan",
    inputType: "file",
    name: "userOrganization.mouWithGovtOfPakistanDoc",
    id: "userOrganization.mouWithGovtOfPakistanDoc",
    onChangeMethod: "onFileChange",
    inForm: true,
    wrapperClass: "col-md-6",
    validation: {
      required: true,
    },
    conditions: [
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "iNGO",
        action: "hide",
        hideOnLabel: true,
      },
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "NGO",
        action: "hide",
        hideOnLabel: true,
      },
    ],
  },
  {
    category: "organizationRegistrationDetails",
    label: "Start Date of MOU With GOP",
    placeholder: "Enter Start Date of MOU With GOP",
    name: "userOrganization.mouWithGovtOfPakistanStartDate",
    inForm: true,
    wrapperClass: "col-md-6",
    inputType: "date",
    validation: {
      required: true,
    },
    conditions: [
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "iNGO",
        action: "hide",
        hideOnLabel: true,
      },
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "NGO",
        action: "hide",
        hideOnLabel: true,
      },
    ],
  },
  {
    category: "organizationRegistrationDetails",
    label: "End Date of MOU With GOP",
    placeholder: "Enter End Date of MOU With GOP",
    name: "userOrganization.mouWithGovtOfPakistanEndDate",
    inForm: true,
    wrapperClass: "col-md-6",
    inputType: "date",
    validation: {
      required: true,
      compareWith: "userOrganization.mouWithGovtOfPakistanStartDate",
      isEndDate: true,
    },
    conditions: [
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "iNGO",
        action: "hide",
        hideOnLabel: true,
      },
      {
        fieldName: "userOrganization.orgTypeId",
        matchValue: "NGO",
        action: "hide",
        hideOnLabel: true,
      },
    ],
  },

  //
  {
    category: "informationOfOffice",
    children: "Information of Offices",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12 mt-4",
    className: "heading2",
  },
  {
    category: "informationOfOffice",
    children: "Project Office Information",
    inputType: "p",
    inForm: true,
    wrapperClass: "col-md-12 mt-4",
    className: "regular-text text-black",
  },
  {
    category: "informationOfOffice",
    label: "Mail Address",
    placeholder: "Mail Address",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.userOrganizationOffices[0].mailAddress",
    wrapperClass: "col-md-4",
    validation: {
      required: true,
    },
  },
  {
    category: "informationOfOffice",
    label: "Phone Number",
    placeholder: "Phone Number",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.userOrganizationOffices[0].phone",
    wrapperClass: "col-md-4",
    validation: {
      required: true,
      isNumber: true,
    },
  },
  {
    category: "informationOfOffice",
    label: "Fax Number",
    placeholder: "Fax Number",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.userOrganizationOffices[0].fax",
    wrapperClass: "col-md-4",
    validation: {
      required: true,
      isNumber: true,
    },
  },
  {
    category: "informationOfOffice",
    children: "Provisional Office Information",
    inputType: "p",
    inForm: true,
    wrapperClass: "col-md-12 mt-4",
    className: "regular-text text-black",
  },
  {
    category: "informationOfOffice",
    label: "Mail Address",
    placeholder: "Mail Address",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.userOrganizationOffices[1].mailAddress",
    wrapperClass: "col-md-4",
    validation: {
      required: true,
    },
  },
  {
    category: "informationOfOffice",
    label: "Phone Number",
    placeholder: "Phone Number",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.userOrganizationOffices[1].phone",
    wrapperClass: "col-md-4",
    validation: {
      required: true,
      isNumber: true,
    },
  },
  {
    category: "informationOfOffice",
    label: "Fax Number",
    placeholder: "Fax Number",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.userOrganizationOffices[1].fax",
    wrapperClass: "col-md-4",
    validation: {
      required: true,
      isNumber: true,
    },
  },
  {
    category: "informationOfOffice",
    children: "National Head Office (In Pak) Information",
    inputType: "p",
    inForm: true,
    wrapperClass: "col-md-12 mt-4",
    className: "regular-text text-black",
  },
  {
    category: "informationOfOffice",
    label: "Mail Address",
    placeholder: "Mail Address",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.userOrganizationOffices[2].mailAddress",
    wrapperClass: "col-md-4",
    validation: {
      required: true,
    },
  },
  {
    category: "informationOfOffice",
    label: "Phone Number",
    placeholder: "Phone Number",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.userOrganizationOffices[2].phone",
    wrapperClass: "col-md-4",
    validation: {
      required: true,
      isNumber: true,
    },
  },
  {
    category: "informationOfOffice",
    label: "Fax Number",
    placeholder: "Fax Number",
    inSearch: true,
    inForm: true,
    inResult: true,
    name: "userOrganization.userOrganizationOffices[2].fax",
    wrapperClass: "col-md-4",
    validation: {
      required: true,
      isPhoneNumber: true,
    },
  },

  // bords of director
  {
    category: "userOrganizationDirectorCommand",
    children: "Details of Director Command",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "userOrganizationDirectorCommand",
    name: "userOrganization.userOrganizationDirectors",
    inputType: "tableInput",
    inForm: true,
    className: "w-100 table-overflow",
    wrapperClass: "col-12",
    header: [
      {
        label: "Name",
        placeholder: "Enter Name",
        name: "name",
        onChangeMethod: "text-in-table",
        inForm: true,
      },
      {
        label: "CNIC/Passport",
        placeholder: "Enter CNIC/Passport",
        name: "cnicOrPassport",
        onChangeMethod: "text-in-table",
        inForm: true,
      },
      {
        label: "Designation",
        placeholder: "Enter Designation",
        name: "designation",
        onChangeMethod: "text-in-table",
        inForm: true,
      },
      {
        label: "Name of Other NGO",
        placeholder: "Enter Name of Other NGO",
        name: "otherNGO",
        onChangeMethod: "text-in-table",
        inForm: true,
      },
      {
        label: "Upload Passport Doc",
        name: "passportDocTable",
        id: "passportDocTable",
        onChangeMethod: "onFileChangeTable",
        inputType: "file",
        placeholder: "Choose Files",
        inForm: true,
        inTableInput: true,
        isShowBtn: true,
        inTable: true,
      },
    ],
  },

  // foreigners
  {
    category: "detailsOfForeigners",
    children: "Details of Foreigners",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "detailsOfForeigners",
    children: "Working In the NGO/INGO",
    inputType: "p",
    inForm: true,
    wrapperClass: "col-md-12",
    className: "regular-text",
  },
  {
    category: "detailsOfForeigners",
    name: "userOrganization.userOrganizationForeigners",
    inputType: "tableInput",
    inForm: true,

    className: "w-100 table-overflow",
    wrapperClass: "col-12",
    header: [
      {
        label: "Name",
        placeholder: "Enter Name",
        name: "name",
        onChangeMethod: "text-in-table",
        inForm: true,
      },
      {
        label: "Upload Passport Doc",
        name: "passportDoc",
        id: "passportDoc",
        onChangeMethod: "onFileChangeTable",
        inputType: "file",
        placeholder: "Choose Files",
        inForm: true,
        inTableInput: true,
        isShowBtn: true,
        inTable: true,
      },
      {
        label: "Address In Pakistan",
        placeholder: "Enter Address In Pakistan",
        name: "addressInPakistan",
        onChangeMethod: "text-in-table",
        inForm: true,
      },
      {
        label: "Address In Home Country",
        placeholder: "Enter Address In Home Country",
        name: "addressInHomeCountry",
        onChangeMethod: "text-in-table",
        inForm: true,
      },
    ],
  },
  {
    category: "keyStaffInformation",
    children: "Key Staff Information",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "keyStaffInformation",
    children: "Donor Organization",
    inputType: "p",
    inForm: true,
    wrapperClass: "col-md-12",
    className: "regular-text",
  },
  {
    category: "keyStaffInformation",
    name: "userOrganization.userDonorOrganizationStaff",
    id: "userOrganization.userDonorOrganizationStaff",
    inputType: "tableInput",
    inForm: true,
    className: "w-100 table-overflow",
    wrapperClass: "col-12",
    header: [
      {
        label: "Key Staff Type",
        placeholder: "Donor key Staff",
        name: "staffType",
        id: "keyStaffType",
        inputType: "async-dropdown",
        url: "/StaffType",
        searchAttrib: "Search.value",
        labelAttribute: "type",
        valueAttribute: "type",
        onChangeMethod: "dropdown-in-table",
        inForm: true,
        isMulti: false,
      },
      {
        label: "Staff Level",
        placeholder: "Choose Level",
        name: "staffLevel",
        id: "staffLevel",
        inputType: "async-dropdown",
        url: "/StaffLevel",
        searchAttrib: "Search.value",
        labelAttribute: "level",
        valueAttribute: "level",
        onChangeMethod: "dropdown-in-table",
        inForm: true,
        isMulti: false,
      },
      {
        label: "Name",
        placeholder: "Enter Name",
        name: "name",
        onChangeMethod: "text-in-table",
        inForm: true,
      },
      {
        label: "Email",
        placeholder: "Enter Email",
        name: "email",
        onChangeMethod: "text-in-table",
        inForm: true,
      },
      {
        label: "Phone No.",
        placeholder: "Enter Phone No.",
        name: "phone",
        onChangeMethod: "text-in-table",
        inForm: true,
      },
      {
        label: "Cell No.",
        placeholder: "Enter Cell No.",
        name: "cellNo",
        onChangeMethod: "text-in-table",
        inForm: true,
      },
      {
        label: "Fax No.",
        placeholder: "Enter Fax No.",
        name: "faxNo",
        onChangeMethod: "text-in-table",
        inForm: true,
      },
    ],
  },
  {
    category: "detailsOfLocals",
    children: "Details of Existing Locals",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "detailsOfLocals",
    children: "Working In The NGO/INGO",
    inputType: "p",
    inForm: true,
    wrapperClass: "col-md-12",
    className: "regular-text",
  },
  {
    category: "detailsOfLocals",
    name: "userOrganization.userOrganizationLocal",
    inputType: "tableInput",
    inForm: true,
    className: "w-100 table-overflow",
    wrapperClass: "col-12",
    header: [
      {
        label: "Name",
        placeholder: "Enter Name",
        name: "name",
        onChangeMethod: "text-in-table",
        inForm: true,
      },
      {
        label: "Father Name",
        placeholder: "Enter Father Name",
        name: "fatherName",
        onChangeMethod: "text-in-table",
        inForm: true,
      },

      {
        label: "CNIC No.",
        placeholder: "Enter CNIC No.",
        name: "cnicNo",
        onChangeMethod: "text-in-table",
        inForm: true,
      },
      {
        label: "Contact No.",
        placeholder: "Enter Contact No.",
        name: "contactNo",
        onChangeMethod: "text-in-table",
        inForm: true,
      },
      {
        label: "Address",
        placeholder: "Enter Address",
        name: "address",
        onChangeMethod: "text-in-table",
        inForm: true,
      },
    ],
  },
  {
    category: "detailsOfProjectUnderTakenNGO",
    children:
      "Details of Project Undertaken by the NGO/iNGO in the Project Area",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "detailsOfProjectUnderTakenNGO",
    children:
      "Enter details of all previous projects undertaken by the NGO/iNGO in KP.",
    inputType: "p",
    inForm: true,
    wrapperClass: "col-md-12",
  },
  {
    category: "detailsOfProjectUnderTakenNGO",
    name: "userOrganization.userOrganizationProjects",
    inputType: "tableInput",
    inForm: true,
    className: "w-100 table-overflow",
    wrapperClass: "col-12",
    header: [
      {
        label: "Project",
        placeholder: "Enter Project",
        name: "projectName",
        id: "project",
        onChangeMethod: "text-in-table",
        inForm: true,
      },
      // {
      //   label: "Project Area",
      //   placeholder: "Enter Project Area",
      //   name: "projectArea",
      //   id: "projectArea",
      //   onChangeMethod: "text-in-table",
      //   inForm: true,
      // },
      {
        label: "Project Area",
        placeholder: "Select Project Area",
        name: "projectArea",
        id: "projectArea",
        inputType: "async-dropdown",
        // type: "text",
        //TODO Missing Project Area API
        url: "/District",
        searchAttrib: "Search.value",
        labelAttribute: "name",
        valueAttribute: "id",
        onChangeMethod: "dropdown-in-table",
        inForm: true,
        isMulti: false,
      },

      {
        label: "Cluster/Line Department",
        placeholder: "Enter Cluster/Line Department",
        name: "cluster",
        onChangeMethod: "text-in-table",
        inForm: true,
      },
      {
        label: "Donor Organization",
        placeholder: "Enter Donor Organization",
        name: "donorOrganization",
        onChangeMethod: "text-in-table",
        inForm: true,
      },
    ],
  },
];
