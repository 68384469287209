import React from 'react';
import "./Seperator.scss";

const Seperator = ({width="100%",height="3px",className, ...props}) => {
    return (
        <div className={`separator-container ${className}`}>
            <span className='separator-line' style={{width: width, height: height}}></span>
        </div>
    );
}

export default Seperator