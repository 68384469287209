import React, { useEffect, useState } from "react";
import "./ProjectRelates.scss";
import { useDispatch, useSelector } from "react-redux";
import { getRecords, setFormValues } from "../../redux/reducers";

const ProjectRelates = ({ initialData }) => {
  const [data, setData] = useState([]);
  const formValue = useSelector((state) => state.form.values);
  const { natureOfProject, interventionType } = useSelector(
    (state) => state.records?.current
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: "/ProjectNature",
        appendCurrentReducerKey: "natureOfProject",
      },
    });
    dispatch({
      type: getRecords.type,
      payload: {
        url: "/InterventionType",
        appendCurrentReducerKey: "interventionType",
      },
    });
  }, [dispatch]);

  const initializeData = (natureOfProject, interventionType, source) => {
    const sourceMap = new Map();
    source?.forEach((classification) => {
      const key = `${classification.interventionTypeId}-${classification.projectNatureId}`;
      sourceMap.set(key, true);
    });

    const initialDataState = interventionType.map((intervention) => {
      const projectNatures = natureOfProject.map((nature) => {
        const key = `${intervention.id}-${nature.id}`;
        return {
          projectNatureId: nature.id,
          interventionTypeId: intervention.id,
          checked: sourceMap.get(key) || false,
        };
      });
      return { ...intervention, projectNatures };
    });

    setData(initialDataState);
  };

  useEffect(() => {
    if (natureOfProject && interventionType) {
      if (formValue.projectClassifications) {
        initializeData(
          natureOfProject,
          interventionType,
          formValue.projectClassifications.value
        );
      } else {
        initializeData(natureOfProject, interventionType, initialData?.value);
      }
    }
  }, [natureOfProject, interventionType, formValue.projectClassifications, initialData]);

  const handleCheckboxChange = (interventionIndex, natureIndex) => {
    const newData = data.map((intervention, i) => {
      if (i === interventionIndex) {
        const newProjectNatures = intervention.projectNatures.map(
          (nature, j) => {
            if (j === natureIndex) {
              return { ...nature, checked: !nature.checked };
            }
            return nature;
          }
        );
        return { ...intervention, projectNatures: newProjectNatures };
      }
      return intervention;
    });
    setData(newData);

    const projectClassifications = newData
      .flatMap((intervention) =>
        intervention.projectNatures.filter((nature) => nature.checked)
      )
      .map((nature) => ({
        projectNatureId: nature.projectNatureId,
        interventionTypeId: nature.interventionTypeId,
      }));

    dispatch({
      type: setFormValues.type,
      payload: {
        ...formValue,
        projectClassifications: {
          name: "projectClassifications",
          value: projectClassifications,
        },
      },
    });
  };

  return (
    <div>
      <span  className="heading2">Project Relates To</span>
      <p className="text-regular" style={{ color: "gray" }}>
        Please tick relevant boxes, Multiple selection possible.
      </p>
      <div className="container-fluid mt-3">
        <div className="table-wrapper">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th rowSpan="2" className="align-middle">
                  Description
                </th>
                <th colSpan="4" className="text-center">
                  Nature Of Project / Proposal
                </th>
              </tr>
              <tr>
                {natureOfProject?.map((item, index) => (
                  <th key={index} className="text-center">
                    {item?.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((intervention, interventionIndex) => (
                <tr key={interventionIndex}>
                  <td style={{fontSize: "14px"}}>{intervention?.type}</td>
                  {intervention.projectNatures.map((nature, natureIndex) => (
                    <td key={natureIndex} className="text-center">
                      <input
                        type="checkbox"
                        checked={nature.checked}
                        onChange={() =>
                          handleCheckboxChange(interventionIndex, natureIndex)
                        }
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProjectRelates;
