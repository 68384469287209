export const userManagementFields = [
  {
    label: "Name",
    name: "name",
    placeholder: "Enter Name",
    inForm: true,
    required: true,
    autoComplete: "off",
    wrapperClass: " col-md-6",
  },

  {
    name: "email",
    placeholder: "Enter Email",
    inputType: "email",
    label: "Email",
    type: "email",
    inForm: true,
    wrapperClass: " col-md-6",
    autoComplete: "off",
  },

  {
    name: "phone",
    label: "Phone Number",
    placeholder: "Enter Phone Number",
    pattern: "[0-9]+",
    maxLength: 13,
    inForm: true,
    required: true,
    wrapperClass: " col-md-6",
    validation: {
      min: 9,
      max: 11,
    },
  },
  {
    name: "roleId",
    label: "Assign Role",
    id: "roleId",
    // url: "/Role",
    placeholder: "Select Role",
    inputType: "async-dropdown",
    searchattrib: "Search.value",
    searchattrib: "roleName",
    valueAttribute: "roleId",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    isEnum: true,
    validation: {
      required: true,
    },
  },
 
  {
    name: "password",
    placeholder: "Enter Password",
    label: "Password",
    inForm: true,
    type: "password",
    inputType: "password",
    wrapperClass: " col-md-6",
    autoComplete: "false",
  },
  {
    label: "District",
    placeholder: "Select District",
    name: "districtId",
    id: "districtId",
    inputType: "hidden",
    // type:"hidden",
    url: "/District",
    searchattrib: "Search.value",
    searchattrib: "name",
    valueAttribute: "id",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    isEnum: false,
    validation: {
      required: true,
    },
  },
  

  
  {
    name: "submit",
    inputType: "button",
    buttonType: "submit",
    children: "Submit",
    inForm: true,
    wrapperClass: "btn-wrapper  mt-3  col-md-6",
  },

  {
    type: "hidden",
    inForm: true,
    wrapperClass: "col-md-6",
  },
];
