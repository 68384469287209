export const montlyReportFields = [
  {
    category: "progressInformationForm",
    children: "Progress Information",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-md-12",
  },
  {
    category: "progressInformationForm",
    label: "Project Title",
    name: "nocApplicationId",
    placeholder: "Project Title",
    id: "nocApplicationId",
    inputType: "async-dropdown",
    onChangeMethod: "asyncdropdown",
    url: "/NocApplication/GetAllNOCProject",
    searchAttrib: "Search.value",
    labelAttribute: "projectTitle",
    valueAttribute: "id",
    inForm: true,
    isMulti: false,
    isEnum: true,
    wrapperClass: "col-md-6",
    validation: {
      required: true,
    },
  },
  {
    category: "progressInformationForm",
    label: "Donor Agency",
    name: "donorAgency",
    placeholder: "Donor Agency",
    wrapperClass: "col-md-6",
    inForm: true,
    inSearch: true,
    inResult: true,
    isEnum: true,
    validation: {
      required: true,
    },
  },
  {
    category: "progressInformationForm",
    label: "Target Area",
    placeholder: "Target Area",
    name: "targetDistrictId",
    id: "targetDistrictId",
    inputType: "async-dropdown",
    onChangeMethod: "asyncdropdown",
    // url: "/District/{nocAppId}",
    searchAttrib: "Search.value",
    labelAttribute: "name",
    valueAttribute: "id",
    inForm: true,
    isMulti: false,
    isEnum: true,
    wrapperClass: "col-md-6",
    validation: {
      required: true,
    },
  },
  {
    category: "progressInformationForm",
    label: "Implementing Partners",
    name: "implementingPartner",
    placeholder: "Enter Implementing partners",
    inForm: true,
    inSearch: true,
    inResult: true,
    isEnum: true,
    wrapperClass: "col-md-6",
    validation: {
      required: true,
    },
  },
  {
    category: "progressInformationForm",
    label: "Report Verified by the concerned cluster",
    placeholder: "Upload Verified Reports",
    inputType: "file",
    name: "verifiedReportDoc",
    id: "verifiedReportDoc",
    url: "/FileUpload/nocApplication/file",
    onChangeMethod: "onFileChange",
    inForm: true,
    isEnum: true,
    wrapperClass: "col-md-6",
    validation: {
      required: true,
    },
  },
  {
    category: "progressInformationForm",
    label: "Vetted By",
    name: "vettedBy",
    placeholder: "Enter Who Vetted This Report",
    inSearch: true,
    inForm: true,
    inResult: true,
    isEnum: true,
    wrapperClass: "col-md-6",
    validation: {
      required: true,
    },
  },
  {
    category: "progressInformationForm",
    label: "Report Month",
    placeholder: "Select Report Month",
    name: "reportMonth",
    id: "reportMonth",
    inputType: "async-dropdown",
    // url: "/api/NocApplication/{nocAppId}/GetListofMonthsForNoc",
    onChangeMethod: "asyncdropdown",
    searchAttrib: "Search.value",
    labelAttribute: "name",
    valueAttribute: "id",
    inForm: true,
    isMulti: false,
    isEnum: true,
    wrapperClass: "col-md-6",
    validation: {
      required: true,
    },
  },
  {
    category: "progressDetailTableInputx",
    children: "Progress Detail",
    inputType: "h4",
    inForm: true,
    isEnum: true,
    wrapperClass: "col-md-12",
    className: "heading2",
  },
  {
    category: "progressDetailTableInputx",
    children: "Please fill relevant boxes.",
    inputType: "p",
    inForm: true,
    wrapperClass: "col-md-12",
  },
  {
    category: "progressDetailTableInputx",
    label: "",
    name: "reportDetails",
    inputType: "progressDetailTable",
    inForm: true,
    className: "w-100 table-overflow",
    wrapperClass: "col-12",
    header: [
      {
        label: "Result Indicator",
        placeholder: "Select Indicator From Work Plan",
        name: "workTypeId",
        id: "workTypeId",
        inputType: "async-dropdown",
        searchAttrib: "Search.value",
        labelAttribute: "workDescription",
        valueAttribute: "id",
        onChangeMethod: "dropdown-in-table",
        inForm: true,
        isMulti: false,
        isEnum: true,
      },
      {
        label: "Unit of Measurement",
        placeholder: "Enter Unit of Measurement",
        name: "measurementUnit",
        onChangeMethod: "text-in-table",
        inForm: true,
        isEnum: true,
      },
      {
        label: "Planned Target",
        placeholder: "Enter Planned Target",
        name: "plannedTarget",
        onChangeMethod: "text-in-table",
        type:"number",
        inForm: true,
        isEnum: true,
      },
      {
        label: "Achieved (Reporting Period)",
        placeholder: "Enter Achieved Amount",
        name: "achievedAmount",
        onChangeMethod: "text-in-table",
        type:"number",
        inForm: true,
        isEnum: true,
      },
      {
        label: "Achieved Cummulative *",
        placeholder: "Total Achieved Amount",
        name: "achievedCommulative",
        onChangeMethod: "text-in-table",
        inputType: "p",
        disabled: true,
        inForm: true,
        isEnum: true,
      },
      {
        label: "%Age Achieved Against Cumm.",
        placeholder: "Automatically Calculated",
        name: "percentageAchieved",
        onChangeMethod: "text-in-table",
        inputType: "p",
        inForm: true,
        isEnum: true,
      },
      {
        label: "Progress Status",
        placeholder: "Select Status",
        name: "progressStatus",
        id: "progressStatus",
        inputType: "async-dropdown",
        url: "/Lookup/ProgressStatus",
        searchAttrib: "Search.value",
        labelAttribute: "name",
        valueAttribute: "id",
        onChangeMethod: "dropdown-in-table",
        inForm: true,
        isMulti: false,
        isEnum: true,
      },
      {
        label: "Reason For Delay (if any)",
        placeholder: "Enter Reason for Delay",
        name: "reasonForDelay",
        onChangeMethod: "text-in-table",
        inForm: true,
        isEnum: true,
      },
    ],
  },
  {
    category:"progressInformationBtn",
    inputType: "button",
    buttonType: "submit",
    children: "Submit",
    inForm: true,
    wrapperClass: "btn-wrapper ml-auto",
  },
];
