import React from 'react'
import ReadableTable from '../../readableTable/ReadableTable';
import Seperator from '../../seperator/Seperator';

const LogicalFramework = ({data={}}) => {

  const header = [
    "Narrative Summary",
    "Expected Result Type",
    "Expected Results",
    "Performance Measurement",
    "Assumptions And Risks"
  ];
  return (
  

   
      <div className="mt-4">
        <ReadableTable
          label="Logical Framework"
          headers={header}
          // data={data ?? []}
          data={data?.map(({id, ...rest}) => rest) ?? []}
        />
          <Seperator className={"py-3"}/>
      </div>
    
    

  )
}

export default LogicalFramework