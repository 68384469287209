import React from 'react'

const CheckFileIconSmall = (props) => {
  return (
    <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
   
    fill="#000"
  >
    <path
      d="M13.646 4.684 9.8.86A1.238 1.238 0 0 0 8.919.5H2.882c-.327 0-.641.126-.873.351-.232.226-.362.53-.362.849v.4h-.412c-.327 0-.642.126-.873.351C.13 2.677 0 2.981 0 3.3v2c0 .106.043.208.12.283a.418.418 0 0 0 .583 0 .394.394 0 0 0 .12-.283v-2c0-.106.044-.208.121-.283a.418.418 0 0 1 .291-.117h.412v10.8c0 .318.13.623.362.849.232.225.546.351.873.351h8.647v.4a.394.394 0 0 1-.12.283.418.418 0 0 1-.291.117H1.235a.418.418 0 0 1-.29-.117.394.394 0 0 1-.121-.283V6.9a.394.394 0 0 0-.121-.283.418.418 0 0 0-.582 0A.394.394 0 0 0 0 6.9v8.4c0 .318.13.623.362.848.231.226.546.352.873.352h9.883c.327 0 .641-.126.873-.352.232-.224.362-.53.362-.848v-.4h.412c.327 0 .641-.126.873-.351.232-.226.362-.53.362-.849V5.52a1.143 1.143 0 0 0-.354-.836ZM9.882 2.1l2.833 2.8h-2.42a.418.418 0 0 1-.292-.117.394.394 0 0 1-.12-.283V2.1Zm3.295 11.6a.394.394 0 0 1-.121.283.418.418 0 0 1-.291.117H2.882a.418.418 0 0 1-.29-.117.394.394 0 0 1-.121-.283v-12c0-.106.043-.208.12-.283a.418.418 0 0 1 .291-.117H8.92c.048.001.095.01.14.028V4.5c0 .318.13.623.362.849.231.225.545.351.873.351h2.883v8Zm-2.623-6.712a.403.403 0 0 1 .151.27.388.388 0 0 1-.09.294l-3.294 4A.41.41 0 0 1 7 11.7a.419.419 0 0 1-.255-.088l-2.06-1.6a.392.392 0 0 1 .057-.662.42.42 0 0 1 .459.038l1.733 1.348 3.039-3.684a.409.409 0 0 1 .276-.15.423.423 0 0 1 .304.086Z"
    />
  </svg>
  )
}

export default CheckFileIconSmall