import { Link } from "react-router-dom";
import { getFormatedDate, getStatusText } from "../../Utility";
import Badge from "../../components/badge/Badge";

export const grivenceColumns = [
  {
    name: "Name",
    selector: (row) => row?.userName,
    maxWidth: "300px",
  },
  {
    name: "Issues",
    selector: (row) => (
      <>
        {row?.types &&
          row?.types.map(({ customValue = "" }, index) => (
            <Badge
              className="m-1 text-left btn btn-sm btn-info"
              style={{
                lineHeight: "10px",
                fontSize: "10px",
                backgroundColor: "#f5f5f5",
                color: "#000",
                borderColor: "#f5f5f5",
                borderRadius: 20,
                paddingTop: 8,
                paddingBottom: 8,
                paddingRight: 12,
                paddingLeft: 12,
              }}
              key={index}
            >
              {customValue}
            </Badge>
          ))}
      </>
    ),
    minWidth: "300px",
  },
  {
    name: "Created on",
    // selector: (row) => row.lastActionDate,
    selector: (row) => getFormatedDate(row?.createdOn),
    maxWidth: "150px",

  },
];
