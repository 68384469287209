import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch, useSelector } from "react-redux";
import RecordsTable from "../records-table/RecordsTable";
import * as Icon from "react-bootstrap-icons";
import { getRecords, putRecord } from "../../redux/reducers";
import FaIcon from "../icon/FaIcon";
import MdIcon from "../icon/MdIcon";
import Modal from "react-bootstrap/Modal";
import Form from "../form/Form";
import Loader from "../loader/loader";
import { deleteRecord, formatRecords } from "../../redux/reducers/recordSlice";
import ToolTip from "../tooltip/Tooltip";
import { canView, getRoleAsArray } from "../../utils/util";
import Table from "../table/Table";
import { ORDER_DIRECTION } from "../../Constants";

const definitionCustomStyles = {
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderColor: "#dcdff3",
      backgroundColor: "#F8F8F8",
      height: "48px",
      borderRadius: "8px",
    },
  },
  headCells: {
    style: {
      fontSize: "12px",
      color: "#000000",
      fontWeight: "400",
      paddingLeft: "16px",
      fontFamily: "Roboto Flex, sans-serif",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dcdff3",
      },
      "&:last-of-type": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dcdff3",
        borderTopRightRadius: "6px",
      },
      "&:first-of-type": {
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dcdff3",
        borderTopLeftRadius: "6px",
      },
    },
  },
  cells: {
    style: {
      height: "48px",
      fontSize: "13px",
      paddingLeft: "16px",
      color: "#1E1F23",
      fontFamily: "Roboto Flex, sans-serif",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dcdff3",
      },
      "&:last-of-type": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dcdff3",
      },
      "&:first-of-type": {
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "#dcdff3",
      },
    },
  },
};
const FindValue = () => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);

  const { role } = useSelector((state) => state.setting?.user);
  const userRoles = role && getRoleAsArray(role);

  const { definitions, loading, created } = useSelector(
    (state) => state.records
  );
  const formValues = useSelector((state) => state.form?.values);

  const { resultFields, searchFields, formFields } = useSelector(
    (state) => state?.fields
  );
  const headers = resultFields ?? [];
  let items = definitions?.items ?? [];

  const currentDefinationApiMeta =
    formFields && formFields.find(({ name }) => name == "url");

  useEffect(() => {
    if (created) {
      handleSearch();
    }
  }, [created]);

  useEffect(() => {
    handleSearch();
  }, [searchFields]);



  const handleSearch = (obj = null) => {
    const Name = obj?.target?.value;
    if (!Array.isArray(searchFields)) return;
    const defUrl = searchFields.find(
      ({ name, inSearch = null }) => name == "url" && inSearch == true
    );

    dispatch({
      type: getRecords.type,
      payload: {
        url: defUrl?.value,
        params: {
          Name,
          DisablePagination: false,
          OrderDir: ORDER_DIRECTION.DESC,
        },
      },
    });
  };

  const onDelete = (v) => {
    confirmAlert({
      message: "Are you sure to Delete this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(v),
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => { },
      afterClose: () => { },
      onClickOutside: () => { },
      onKeypressEscape: () => { },
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const handleDelete = ({ id }) => {
    dispatch({
      type: deleteRecord.type,
      payload: { id, url: currentDefinationApiMeta?.value },
    });
  };

  const toggleModel = (value) => {
    const refactoredValues = {
      ...value,
      isAttachedToBannuDIkhan: value?.isAttachedToBannuDIkhan ? "Yes" : "No",
      isBorderWithTribalAreas: value?.isBorderWithTribalAreas ? "Yes" : "No",
      isPartOfMergedDistrict: value?.isPartOfMergedDistrict ? "Yes" : "No",
      isSharingInternationalBorder: value?.isSharingInternationalBorder
        ? "Yes"
        : "No",
    };
    dispatch({
      type: formatRecords.type,
      payload: {
        ...refactoredValues,
      },
    });
    setModalShow(true);
   
  };



  const postData = () => {
    const refactoredData = {
      ...formValues,
      isAttachedToBannuDIkhan: {name:"IsAttachedToBannuDIkhan", value: formValues?.isAttachedToBannuDIkhan?.value?.value || formValues?.isAttachedToBannuDIkhan?.value === "Yes" ? true : false},
      isBorderWithTribalAreas: {name:"IsBorderWithTribalAreas", value: formValues?.isBorderWithTribalAreas?.value?.value || formValues?.isBorderWithTribalAreas?.value === "Yes" ? true : false},
      isPartOfMergedDistrict: {name:"IsPartOfMergedDistrict", value: formValues?.isPartOfMergedDistrict?.value?.value || formValues?.isPartOfMergedDistrict?.value === "Yes" ? true : false},
      isSharingInternationalBorder: {name:"IsSharingInternationalBorder", value: formValues?.isSharingInternationalBorder?.value?.value || formValues?.isSharingInternationalBorder?.value === "Yes" ? true : false},
     


    };
    
    dispatch({
      type: putRecord.type,
      payload: {
        ...refactoredData,
        url: `${currentDefinationApiMeta?.value}/${formValues?.id?.value}`,
        // isFormatted: true,
      },
      onSuccess: [
        {
          dispatch: { type: "form/clearForm" },
        },
      ],
    });
    setModalShow(!modalShow);
  };

  let columns = headers.map(({ label, name }, index) => {
    return {
      name: label,
      selector: (row) =>
        typeof row[name] == "object"
          ? row[name]?.name
          : typeof row[name] == "boolean"
            ? row[name]
              ? "Yes"
              : "No"
            : row[name],
    };
  });

  columns = [
    ...columns,
    {
      name: "Action",
      maxWidth: "100px",
      selector: (row) => (
        <>
          {/* {canView(["SuperAdmin"], userRoles) */}
          {/* ? [ */}
          <span onClick={(e, value) => toggleModel(row)}>
            <ToolTip label="Edit">
              <Icon.PencilSquare className="action-icons" />
            </ToolTip>
          </span>
          <span onClick={(e, v) => onDelete(row)}>
            <ToolTip label="Delete">
              <Icon.Trash className="action-icons" />
            </ToolTip>
          </span>
          {/* ] */}
          {/* [ */}
          {/* <span onClick={(e, value) => toggleModel(row)}>
                <ToolTip label="Edit">
                  <Icon.PencilSquare className="action-icons" />
                </ToolTip>
              </span>, */}
          {/* ]} */}
        </>
      ),
    },
  ];

  return (
    <>
      <Loader loading={loading} />
      {/* table section */}
      <div className="datatable-header">
        <div className="float-right">
          <div className="dataTables_filter">
            <label className="my-2 col-md-6">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                onChange={(e) => handleSearch(e)}
              />
            </label>
          </div>
        </div>
      </div>

      <div className="overflow-auto col-md-12">
        <Table
          columns={columns}
          customStyles={definitionCustomStyles}
          data={definitions}
        />
        {/* <div className="overflow-auto w-100">
        <Table columns={columns} data={items} />
 */}

        {/* <RecordsTable
          data={data}
          header={headers}
          actionButtons={
            canView(["SuperAdmin"], userRoles)
              ?
              [
                <span onClick={(e, value) => toggleModel(value)}>
                  <ToolTip label="Edit">
                    <Icon.PencilSquare className="action-icons" />
                  </ToolTip>
                </span>,
                <span onClick={(e, v) => onDelete(v)}>
                  <ToolTip label="Delete">
                    <Icon.Trash className="action-icons" />
                  </ToolTip>
                </span>,
              ]
              :
              [
                <span onClick={(e, value) => toggleModel(value)}>
                  <ToolTip label="Edit">
                    <Icon.PencilSquare className="action-icons" />
                  </ToolTip>
                </span>,
              ]
          }
        /> */}
      </div>

      {/* Update Record Model */}
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          dispatch({ type: "form/clearForm" });
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="w-100 d-flex justify-content-between"
          >
            <div>Update Record</div>
            <div>
              <MdIcon
                iconName={"MdOutlineClose"}
                action={() => {
                  setModalShow(!modalShow);
                  dispatch({ type: "form/clearForm" });
                }}
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            formData={formFields ? [...formFields] : []}
            onSubmit={(params) => postData(params)}
            withValidation={false}
            formValidation={[]}
            extraInputClass=""
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FindValue;
