import React, { useEffect, useState } from "react";
import "./NocLayout.scss";
import CheckFileIcon from "../../assets/icons/CheckFileIcon";
import ClockIcon from "../../assets/icons/ClockIcon";
import SubmitIcon from "../../assets/icons/SubmitIcon";
import NocButton from "../../components/noc/NocButton";
import CheckFileIconSmall from "../../assets/icons/CheckFileIconSmall";
import { Link, useLocation } from "react-router-dom";
import { NOC_Tabs } from "../../Constants";
import { canView } from "../../utils/util";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { BoxArrowUp } from "react-bootstrap-icons";
import Modal from "react-modal";
import { clearForm, createRecord, resetData } from "../../redux/reducers";
import Loader from "../loader/loader";

const NocLayout = ({ children, project, ...props }) => {
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.setting?.user);
  const { created, loading } = useSelector((state) => state.records);
  const { closureDocument } = useSelector((state) => state.form?.values);

  const location = useLocation();
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    dispatch({ type: resetData.type });
    dispatch({ type: clearForm.type });
  }, [created]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    dispatch({
      type: createRecord.type,
      payload: {
        file: { name: "file", value: selectedFile },
        url: "/FileUpload/nocApplication/file",
        headers: { "Content-Type": "multipart/form-data" },
        supressMessages: true,
      },
      onSuccess: [
        {
          type: "form/setFormFile",
          payload: {
            name: "closureDocument",
            documentId: selectedFile?.name,
            isObj: true,
          },
        },
      ],
    });
  };

  const handleClosure = () => {
    dispatch({
      type: createRecord.type,
      payload: {
        url: `/NocApplication/${project?.nocProjectDetail?.id}/closure?closureDoc=${closureDocument?.value[0]?.appDoc}`,
      },
      onSuccess: [
        {
          type: clearForm.type,
          type: resetData.type,
        },
      ],
    });
    setModalShow(false);
  };

  return (
    <div className="px-4 py-2 noc-layout mt-5">
      <Loader loading={loading} />
      <div className="noc-layout-header">
        <div className="d-flex flex-column justify-content-between gap-1 col-md-4">
          <span className="project-name">
            { project?.nocProjectDetail?.extensionCount > 0  ? "Extension : "+project?.nocProjectDetail?.projectTitle :project?.nocProjectDetail?.projectTitle}
          </span>
        
        </div>
        {canView(["SuperAdmin", "NGO User"], role) && (
          <div className="d-flex flex-row gap-2">
            <Link to={`/extension/update/${project?.nocProjectDetail?.id}`}>
              <div className="extension-btn cursor-pointer ">
                <ClockIcon width={18} height={18} className="icon clock-icon" />
                Apply For Extension
              </div>
            </Link>

            <div
              className="extension-btn cursor-pointer"
              onClick={() => setModalShow(!modalShow)}
            >
              <CheckFileIconSmall fill="#000" width={18} height={18} className="icon" />
              Apply For Closure
            </div>

            <Link to={`/monthly-report/add/${project?.nocProjectDetail?.id}`}>
              <div className="extension-btn submit-btn">
                <SubmitIcon fill="#fff" />
                Submit Progress Report
              </div>
            </Link>
          </div>
        )}
      </div>

      <div className="col-md-12 bg-white shadow-sm mb-2 mt-4 rounded">
        {children}
      </div>
      <div className="col-md-12 bg-white shadow-sm mb-2 p-4 mt-3 rounded">
        <div className="d-flex flex-row gap-4">
          {NOC_Tabs.map((item, key) => (
            <Link
              to={`/nocs/${project?.nocProjectDetail?.id}/${props?.orgId}/${item.pathName}`}
              key={key}
            >
              <NocButton
                icon={item.icon ?? CheckFileIcon}
                btnTitle={`${item.btnText}`}
                active={location.pathname.includes(`${item.pathName}`)}
              />
            </Link>
          ))}
        </div>
      </div>
      <Modal
        isOpen={modalShow}
        onRequestClose={() => setModalShow(false)}
        contentLabel="Apply for Closure"
        overlayClassName="modal-xs"
        ariaHideApp={false}
      >
        <div className="d-flex align-items-center justify-content-between">
          <p className="heading1 m-0">Project Closure Form</p>
          <Icon.X
            size={32}
            color="red"
            onClick={() => setModalShow(!modalShow)}
          />
        </div>

        <div className="seprator"></div>

        <div className="d-flex flex-column gap-2">
          <div className="mt-4">
            <div className="col-12">
              <label className="text-regular text-secondary">
                Closure Document
              </label>
              <div className="bg-light px-3 py-2 rounded d-flex align-items-center justify-content-between">
                <input
                  id="closureDocumentInput"
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <p className="px-0 my-0">
                  {closureDocument?.value[0]?.documentId ?? "Upload"}
                </p>
                <label className="upload-button" htmlFor="closureDocumentInput">
                  <BoxArrowUp size={20} color="black" /> Upload
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              onClick={() => {
                handleClosure();
              }}
              className="btn-forward"
            >
              Apply For Closure
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NocLayout;
