import React, { useEffect } from "react";
import FilterIcon from "../../assets/images/Filter.svg";
import "./ExtensionNoc.scss";
import WithPageHeader from "../../components/ui/withPageHeader";
import { customStyles } from "../../assets/styles/CustomStyle";
import { createRecord, getRecords } from "../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import nocsStatus from "../../assets/values/nocsStatus";
import Table from "../../components/table/Table";
import { ExtensionColumns } from "../../assets/columns/ExtensionColumns";
import application_btn from "../../assets/images/plus.svg";
import { useNavigate } from "react-router-dom";
import { canView, getRoleAsArray } from "../../utils/util";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import DropdownButton from "../../components/dropdown-button/DropdownButton";
import { FaBan, FaCheck } from "react-icons/fa";

const ExtensionNoc = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.setting?.user);
  const userRoles = role && getRoleAsArray(role);

  const { extension = [] } = useSelector((state) => state?.records?.current);
  const [filterData, setFilterData] = React.useState(extension);
  const [selectedStatus, setSelectedStatus] = React.useState("All");

  useEffect(() => {
      getNocExtensionData();
  }, []);

  const getNocExtensionData = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/NocApplication/AllNOCExtension`,
        appendCurrentReducerKey: "extension",
      },
    });
  };

  useEffect(() => {
    setFilterData(extension);
  }, [extension]);

  const filterByStatus = (type) => {
   
    setSelectedStatus(type);
    if (type === "All") {
      setFilterData(extension);
    } else {
      const filtered = extension?.filter((record) =>  record?.applicationStatus === type);      
     
      setFilterData(filtered);
    }
  };

  const getStatusClass = (type) => {
    return selectedStatus === type ? "active" : "";
  };

  const statusList = Object.entries(nocsStatus).map(([key, value]) => {
    return {
      text: value,
      className: `${getStatusClass(value)}`,
      onClick: () => filterByStatus(value),
    };
  });

  const handleEdit = (id) => {
    navigate(`/extension/update/${id}`);
  };

  const handleDelete = (id) => {
    // Implement your delete logic here
  };


  const handleApprove = (id) => {
    dispatch({
      type: createRecord.type,
      payload: {
        url: `/NocApplication/${id}/Extension/approve`,
       
      },
    });
  };

  const handleReject = (id) => {
    dispatch({
      type: createRecord.type,
      payload: {
        url: `/NocApplication/${id}/Extension/reject`,

      },
    });
  };

  const actions = [
    {
      label: "Approve",
      icon: FaCheck,
      onClick: handleApprove,
    },
    {
      label: "Block",
      icon: FaBan,
      onClick: handleReject,
    },
  ];

  const columns = [
    ...ExtensionColumns,
  ...(!canView(["NGO User"], userRoles) ? [{
    name: "Action",
    width: "100px",
    cell: (row) => (
      <DropdownButton
        rowId={row?.id}
        actions={actions}
      />
    ),
  }] : []),
  ];
  return (
    <div className="px-4 py-2 mt-5">
      <WithPageHeader
        heading="NOC Extensions"
        text="Review and manage extensions of NOCs"
        buttons={
          canView(["NGO User"], userRoles) ?[
         {
            icon: <img src={application_btn} alt="application_btn" />,
            text: "Apply for Extension",
            onClick: () => navigate("/extension/add"),
            className: "regularButton",
            style: { gap: "8px" },
          },
        ] : []}
      />

      <div className="bg-white shadow-sm rounded p-3 table-container-anim">
        <WithPageHeader
          heading="NOC Extensions List"
          buttons={[
            ...statusList,
          ]}
        />
        <div className="mt-3">
          <Table
            columns={columns}
            data={filterData}
            customStyles={customStyles}
            pagination
            // selectableRows
            dense
          />
        </div>
      </div>
    </div>
  );
};

export default ExtensionNoc;
