import React from "react";
import DashboardMainPage from "../dashboardTabs/DashboardMainPage";
import BudgetAllocationPage from "../dashboardTabs/BudgetAllocationPage";
import { useSelector } from "react-redux";

const DashboardTabs = () => {
  // const { currentActiveTab } = useSelector((state) => state?.setting?.filter);
  return (
    // <div key={currentActiveTab}>
    <div>
      {/* {currentActiveTab === "Dashboard" && <DashboardMainPage />}
      {currentActiveTab === "Budget Allocation" && <BudgetAllocationPage />} */}
      <DashboardMainPage />
    </div>
  );
};

export default DashboardTabs;
