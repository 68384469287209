import React, { useEffect, useState } from "react";
import "./DashboardPage.scss";
import DashboardTopNav from "../../components/dashboard/DashboardTopNav";
import DashboardTabs from "../../components/dashboard/DashboardTabs";



const DashboardPage = () => {
  return (
    <div className="px-4 py-4 mt-5 col-md-12">
      {/* <DashboardTopNav/> */}
      <DashboardTabs/>
    </div>
  );
};

export default DashboardPage;
