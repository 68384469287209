import React from "react";
import "./ProjectDetail.scss";

const ProjectDetail = ({ data = {} }) => {
  return (
    <div>
      <p className="label-heading">Project Details</p>

      <div className="detail-container">
        <p className="body-heading mb-2">Project Background:</p>
        <p className="body-text">
          {data?.projectBackground}
        </p>

        <p className="body-heading mb-2">Project Summary:</p>
        <p className="body-text">
          {data?.projectSummary}
        </p>

        <p className="body-heading mb-2">Project Rational:</p>
        <p className="body-text">
         {data?.projectRational}
        </p>

        <p className="body-heading mb-2">Implementation Strategy:</p>
        <p className="body-text">
         {data?.implementationStratergy}
        </p>

        <p className="body-heading mb-2">Project Goal:</p>
        <p className="body-text">
         {data?.projectGoal}
        </p>

        <p className="body-heading mb-2">Outcomes & Output:</p>
        <p className="body-text">
        {data?.outcome}
        </p>

        <p className="body-heading mb-2">Coordination Mechanism:</p>
        <p className="body-text">
         {data?.cordinationMechanism}
        </p>

        <p className="body-heading mb-2">Monitoring Strategy:</p>
        <p className="body-text">
        {data?.monitoringStratergy}
        </p>
      </div>
    </div>
  );
};

export default ProjectDetail;
