// ToggleButton.js
import React from "react";
import * as Icon from "react-bootstrap-icons";
import ClosingArrowIcon from "../../assets/icons/ClosingArrowIcon";
import ToolTip from "../tooltip/Tooltip";

const ToggleCommentSection = ({
  isCommentSectionOpen,
  toggleCommentSection,
}) => {
  return (
    <button
      className="btn rounded-circle"
      onClick={toggleCommentSection}
      style={{
        // position: 'absolute',
        // top: '12%',
        // right: isCommentSectionOpen ? '16.6%' : '0',
        // margin: "-30px",
        backgroundColor: "#f8f9fa",
        marginLeft: "-30px",
        marginBottom: "-100px",
        transform: "translateY(-50%)",
        zIndex: 999,
        transition: "all 0.3s",
      }}
    >
      <ToolTip label={`${isCommentSectionOpen ? "Close" : "Open"} Comments`}>
        {isCommentSectionOpen ? <Icon.ChevronRight color="green"  /> : <Icon.ChevronLeft color="green"/>}
      </ToolTip>
    </button>
  );
};

export default ToggleCommentSection;
