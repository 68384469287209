import React from "react";
import "./HumanResourceDetail.scss";
import ReadableTable from "../../readableTable/ReadableTable";
import WithPageHeader from "../../ui/withPageHeader";
import { handleDownloadFile } from "../../../Utility";
import { Download } from "react-bootstrap-icons";

const HumanResourceDetail = ({ data = {} }) => {
  //TODO Data missing from API
  const header = [
    "Name",
    "Copy of Passport",
    "Address In Pakistan",
    "Address In Home Country",
  ];

  const existingLocals = [
    "Name",
    "Father Name",
    "CNIC",
    "Contact No.",
    "Address (Permanent/Temporary)",
  ];

  const projectArea = [
    "Project Title",
    "Project Area",
    "Cluster/line Department",
    "Donor Organization",
  ];
  const orderedData =
    data?.organizationLocals?.map((local) => ({
      Name: local.name,
      "Father Name": local.fatherName,
      CNIC: local.cnicNo,
      "Contact No.": local.contactNo,
      "Address (Permanent/Temporary)": local.address,
    })) ?? [];

  const organizationForeigners = data?.organizationForeigners?.map((i) => {
    return {
      ...i,
      passportDoc: <button className="bg-success text-light p-2 border-0 rounded" onClick={(e) => handleDownloadFile(e,i?.passportDoc)} > <Download size={20} color="white" /> {i?.passportDoc?.replace(/^.*[\\\/]/, "")}</button>,
    };
  });
  
  return (
    <div>
      <WithPageHeader heading="Human Resource Detail" buttons={[]} />
      <ReadableTable
        label="Details Of Foreigners Working In The NGO/INGO"
        headers={header}
        data={organizationForeigners ?? []}
      />

      <ReadableTable
        headers={existingLocals}
        data={orderedData ?? []}
        label={"Details Of Existing Locals Working In The NGO/INGO"}
      />

      <ReadableTable
        headers={projectArea}
        data={data?.organizationProjects ?? []}
        label={
          "Details of Project Undertaken By The NGO/INGO In The Project Area"
        }
      />
    </div>
  );
};

export default HumanResourceDetail;
