import React, { useEffect } from "react";
import "./NotificationPage.scss";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";

import Loader from "../../components/loader/loader";
import { createRecord, getRecords } from "../../redux/reducers";
import WithPageHeader from "../../components/ui/withPageHeader";
import NoMessageIcon from "../../assets/icons/NoMessageIcon";
import { formatDate } from "../../utils/util";




const NotificationPage = () => {
  // const { setMeta } = usePageMeta();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state?.records);
  const { name } = useSelector((state) => state?.setting?.user);
  const { notifications = [] } = useSelector((state) => state?.records?.current);

  useEffect(() => {
    getNotifications();
  }, [])

  const getNotifications = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Notification/user`,
        appendCurrentReducerKey: "notifications",
      },
    });
  };

  const markAsRead = (id) => {
    dispatch({
      type: createRecord.type,
      payload: {
        url: `/Notification/${id}/read`,
      },
    });
  };


  const generateRandomColor = () => {
    let r = Math.round((Math.random() * 255)); //red 0 to 255
    let g = Math.round((Math.random() * 255)); //green 0 to 255
    let b = Math.round((Math.random() * 255)); //blue 0 to 255
    if (r < 255 && g < 255 && b < 255) {
      return (`#${r + + g + + b}`);
    }
    generateRandomColor();
  };

  return (
    // <div className="my-3">
    //   <Loader loading={loading} />
    //   {/* <Table columns={columns} data={items} /> */}
    // </div>
    <div className=" notification-page my-3 p-4 col-md-12  ">
      <Loader loading={loading} />
      <div className=" col-md-8 d-flex flex-column gap-1 ">
        <div className="header">
          <WithPageHeader
            heading="Notifications"
            buttons={[]}
          />
        </div>
        <div className="notifications mt-4 bg-white">
          {
            notifications.length <= 0 ? (
              <div className="no-comments col-md-12 text-center">
                <Icon.BellSlash color="#9e9e9e" />
                <p className="text-gray">
                  You have no notification yet
                </p>
              </div>
            ) : (
              notifications?.slice()?.reverse()?.map((item, key) => {
                return (
                  <div className=" notification-child  col-md-12 " key={item?.id}>
                    {/* <div className="notification-user-img"><div className="data rounded-5" style={{ backgroundColor: generateRandomColor() }}>{name}</div></div> */}
                    <div className="notification-user-img">
                      <div className="data rounded-5" style={{ backgroundColor: generateRandomColor() }}>
                        {name?.split(' ').map(part => part.charAt(0).toUpperCase()).join('')}
                      </div>
                    </div>
                    <div className=" notification-message">
                      {
                        item?.collaborate ?
                          <div className="notification-message-data"><span style={{ fontWeight: 'bold' }}>{item?.sender?.name} </span>{item?.message}</div>
                          :
                          <div className="notification-message-data">{item?.message ?? "Loading..."}</div>
                      }
                      <div className="notification-message-time">{formatDate(item?.createdOn ?? "Loading")}</div>
                    </div>
                  </div>
                )
              })
            )
          }

        </div>
      </div>
    </div>
  );
};

export default NotificationPage;
