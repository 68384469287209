export const userAccountFields = [
  {
    label: "Username",
    name: "userName",
    inForm: true,
    required: true,
    wrapperClass: " col-md-6",
  },
  {
    name: "firstName",
    label: "First Name",
    inForm: true,
    required: true,
    wrapperClass: " col-md-6",
  },
  {
    name: "lastName",
    label: "Last Name",
    required: true,
    inForm: true,
    wrapperClass: " col-md-6",
  },
  {
    name: "email",
    inputType: "email",
    label: "Email Address",
    type: "email",
    inForm: true,
    required: true,
    wrapperClass: " col-md-6",
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    inForm: true,
    required: true,
    wrapperClass: " col-md-6",
  },
  {
    label: "Upload File",
    name: "file",
    onChangeMethod: "onFileChange",
    inputType: "file",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  // {
  //   label: "Image",
  //   placeholder: "",
  //   name: "imageUrl",
  //   inForm: true,
  //   wrapperClass: "col-md-6",
  // },
  {
    type: "hidden",
    inForm: true,
    wrapperClass: "col-12",
  },
  {
    name: "Update",
    inputType: "button",
    buttonType: "submit",
    children: "update",
    inForm: true,
    wrapperClass: "btn-wrapper position-right mr-3 col-md-2 mt-2",
  },
];
