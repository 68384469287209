import React, { useEffect, useState } from "react";
import "./NocDetail.scss";
import NocProjectSummary from "../../components/noc/NocProjectSummary";
import NocSupportingDocuments from "../../components/noc/NocSupportingDocuments";
import NocProjectDetails from "../../components/noc/NocProjectDetails";
import NocWorkPlan from "../../components/noc/NocWorkPlan";
import NocLayout from "../../components/noc/NocLayout";
import { Route, Routes, useParams } from "react-router-dom";
import BeneficiariesList from "../../components/noc/BeneficiariesList";
import FundsDetails from "../../components/noc/FundsDetails";
import NocLogicalFramework from "../../components/noc/NocLogicalFramework";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../redux/reducers";
import Loader from "../../components/loader/loader";
import Certificate from "../../components/noc/Certificate";
const NocDetail = () => {
  const { id, orgId } = useParams();
  const dispatch = useDispatch();
  const { nocProjects = [] } = useSelector((state) => state?.records?.current);
  const { created, loading } = useSelector((state) => state?.records);
  // const selectedProject = data.find((project) => project.id === parseInt(id));

  useEffect(() => {
    getProjectData();
  }, [created]);

  useEffect(() => {
    getAllProgressReportData();
  }, []);
  const getProjectData = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/NocApplication/${id}/GetNocProjectAndExtensionDetails`,
        appendCurrentReducerKey: "nocProjects",
      },
    });
  };

  const getAllProgressReportData = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        // url: `/monthlyProgressReport/${id}/GetAllProgressReportsByNoc`,
        url: `/ProgressReport/${id}/GetAllProgressReportsByNoc`,
        appendCurrentReducerKey: "monthlyProgressReport",
      },
    });
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Organization/${orgId}/GetById`,
        appendCurrentReducerKey: "organizationInfo",
      },
    });
  };
  return (
    <>
      <Loader loading={loading} />
      <NocLayout project={nocProjects} orgId={orgId}>
        <Routes>
          <Route
            path="project-summary"
            element={
              <NocProjectSummary
                projectData={nocProjects?.nocProjectDetail ?? []}
              />
            }
          />
          <Route
            path="*"
            element={
              <NocProjectSummary
                projectData={nocProjects?.nocProjectDetail ?? []}
              />
            }
          />
          <Route
            path="project-details"
            element={
              <NocProjectDetails
                projectDetails={nocProjects?.nocProjectDetail ?? []}
              />
            }
          />
          <Route
            path="beneficiaries-list"
            element={
              <BeneficiariesList
                beneficiariesList={nocProjects?.nocProjectDetail ?? []}
              />
            }
          />
          <Route
            path="funds-details"
            element={
              <FundsDetails
                fundsDetails={nocProjects?.nocProjectDetail ?? []}
              />
            }
          />
          <Route
            path="work-plan"
            element={<NocWorkPlan nocWorkPlan={nocProjects ?? []} />}
          />
          <Route
            path="logical-framework"
            element={
              <NocLogicalFramework
                NocLogicalFramework={
                  nocProjects?.nocProjectDetail?.logicalFrameworks ?? []
                }
              />
            }
          />
          <Route
            path="supporting-documents"
            element={
              <NocSupportingDocuments
                supportingDocuments={nocProjects?.nocProjectDetail ?? []}
              />
            }
          />
          <Route path="certificate" element={<Certificate id={id} />} />

          {/* <Route path="organization-information" element={<OrganizationInformationNocDetail />} /> */}
        </Routes>
      </NocLayout>
    </>
  );
};

export default NocDetail;
