import React from "react";
import { Route, Routes } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast, Slide, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AppContainer from "./components/container/AppContainer";
import LoginForm from "./components/login-signup/LoginForm";
import ForgetPassword from "./pages/userAccount.js/ForgetPassword";
import SingupAndOrganizationForm from "./components/login-signup/SingupAndOrganizationForm";
import "./App.scss";
import { Toaster } from "react-hot-toast";
// import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const { user } = useSelector((state) => state.setting);
  const isLoggedIn = user?.token;

  return (
    <div className="App">
      {/* <ToastContainer transition={Flip} autoClose={5000}  position={toast.POSITION.TOP_RIGHT} limit={3} /> */}
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 4000,
        }}
      />

      <Routes>
        {isLoggedIn ? (
          <Route path="/*" element={<AppContainer />} />
        ) : (
          <Route path="/*" element={<LoginForm />} />
        )}
        {!isLoggedIn && <Route path="/login" element={<LoginForm />} />}
        {!isLoggedIn && (
          <Route path="/signup" element={<SingupAndOrganizationForm />} />
        )}
        {!isLoggedIn && <Route path="/forget" element={<ForgetPassword />} />}
        {!isLoggedIn && <Route path="/reset" element={<ForgetPassword />} />}
      </Routes>
    </div>
  );
}

export default App;
