import NOCS_STATUS from "../enums/NOCS_STATUS";

const nocsStatus = {
  [NOCS_STATUS.ALL]: "All",
  [NOCS_STATUS.ONGOING]: "Ongoing",
  [NOCS_STATUS.COMPLETED]: "Completed",
  [NOCS_STATUS.CANCELLED]: "Cancelled",
  [NOCS_STATUS.REVISIONREQ]: "Revision Required",

};
export default nocsStatus;
