import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import FilterIcon from "../../assets/icons/FilterIcon";
import { generalToast } from "../../utils/util";
import toast from "react-hot-toast";
import "./DashboardFilterDropDown.scss";
import ToolTip from "../tooltip/Tooltip";

const DashboardFilterDropDown = ({
  dropdownVisible,
  toggleDropdown,
  districts,
  sectors,
  projectStatus,
  applyFilter,
}) => {
  const notify = ({
    type = "success",
    message = "Successfull!",
    position = "top-center",
  } = {}) => {
    toast[type](message, {
      position: position,
    });
  };
  const [filters, setFilters] = useState({});
  useEffect(() => {
    setFilters({
      districtId: "",
      sectorId: "",
      projectStatus: "",
      startDate: "",
      endDate: "",
    });
  }, [dropdownVisible]);

  function handleFilter() {
    const validate = Object.values(filters).some((item) => item !== "");
    if (!validate) {
      notify({ type: "error", message: "Please Select Filter First" });
    }

    applyFilter(filters);
    toggleDropdown();
  }

  const handleResetFilter = () => {
    setFilters({});
    notify({ type: "success", message: "Reset..." });
    applyFilter(filters);
    toggleDropdown();

  }
  return (
    <Dropdown show={dropdownVisible} onToggle={toggleDropdown}>
      <ToolTip
        label={`Filter Data`}
      >
        <Dropdown.Toggle variant="light" id="dropdown-basic">
          <FilterIcon />
        </Dropdown.Toggle>
      </ToolTip>

      <Dropdown.Menu className="p-4 filterdropdown">
        <div className="d-flex flex-column ">
          <label className="label-heading">District</label>
          <select
            value={filters.districtId}
            onChange={(e) =>
              setFilters({ ...filters, districtId: e.target.value })
            }
            className="drop-down "
            name="districtId"
            id="districtId"
          >
            <option disabled value="">
              Choose District
            </option>
            {districts?.map((item) => (
              <option value={item.id}>{item.name}</option>
            ))}
          </select>
        </div>
        <div className="d-flex flex-column ">
          <label className="label-heading">Sector</label>
          <select
            value={filters.sectorId}
            onChange={(e) =>
              setFilters({ ...filters, sectorId: e.target.value })
            }
            className="drop-down"
            name="sectorId"
            id="sectorId"
          >
            <option disabled value="">
              Choose Sector
            </option>
            {sectors?.map((item) => (
              <option value={item.id}>{item.name}</option>
            ))}
          </select>
        </div>
        <div className="d-flex flex-column ">
          <label className="label-heading">Project Status</label>
          <select
            value={filters.projectStatus}
            onChange={(e) =>
              setFilters({ ...filters, projectStatus: e.target.value })
            }
            className="drop-down"
            name="projectStatus"
            id="projectStatus"
          >
            <option disabled value="">
              Choose Project Status
            </option>
            {projectStatus?.map((item) => (
              <option value={item.name}>{item.description}</option>
            ))}
          </select>
        </div>
        <div className="d-flex flex-column ">
          <label className="label-heading">Start Date</label>
          <input
            value={filters.startDate}
            onChange={(e) =>
              setFilters({ ...filters, startDate: e.target.value })
            }
            type="date"
            name="startDate"
            id="startDate"
            className="input-field"
          />
        </div>
        <div className="d-flex flex-column ">
          <label className="label-heading">End Date</label>
          <input
            value={filters.endDate}
            onChange={(e) =>
              setFilters({ ...filters, endDate: e.target.value })
            }
            type="date"
            name="endDate"
            id="endDate"
            className="input-field"
          />
        </div>
        <div>
          <button onClick={handleFilter} className="btn-apply" type="button">
            Apply
          </button>
          <button className="btn-reset mt-3" onClick={handleResetFilter}>Reset</button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DashboardFilterDropDown;
