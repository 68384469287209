import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { userManagementFields } from "../../assets/fields/userManagementFields";
import Form from "../../components/form/Form";
import Loader from "../../components/loader/loader";
import {
  getRecords,
  createRecord,
  resetData,
  formatRecords,
  putRecord,
} from "../../redux/reducers/recordSlice";
import WithPageHeader from "../../components/ui/withPageHeader";
import { clearForm } from "../../redux/reducers";

const AddUpdateUserManagement = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fields, setFields] = useState(userManagementFields);

  const { loading, created, data } = useSelector((state) => state.records);
  const { rolesData } = useSelector((state) => state.records?.current);
  const { values } = useSelector((state) => state.form);
  const selectedRole = values?.roleId?.value?.label ?? "None";
  // const { values, validations } = useSelector((state) => state.form);
  const { userData } = useSelector((state) => state.records?.current);

  let canUpdate = false;
  if (id) canUpdate = true;

  useEffect(() => {
    if (rolesData) {
      const filteredRolesData = rolesData?.data?.filter(
        (role) => role.roleName !== "NGO User"
      );
      const formattedFields = (userManagementFields ?? [])?.map((field) => {
        if (field?.name === "roleId") {
          return {
            ...field,
            options: filteredRolesData?.map((role) => ({
              label: role?.roleName,
              value: role?.roleId,
            })),
          };
        }

        if (field?.name === "districtId") {
          if (selectedRole === "ADC Relief") {
            return {
              ...field,
              inputType: "async-dropdown",
            };
          }
        }
        return field;
      });

      setFields(formattedFields);
    }
  }, [rolesData, selectedRole]);

  useEffect(() => {
    if (userData)
      initializeUserData();
    return () => {

    }
  }, [userData])

  const initializeUserData = () => {
    if (userData) {
      const formattedUserData = {
        ...userData,
        roleId: {
          label: userData?.role,
          value: userData?.role,
        },
        districtId: {
          label: userData?.districtName,
          value: userData?.districtId,
        },
      };
      dispatch({
        type: formatRecords.type,
        payload: formattedUserData,
      });
    }
  };

  useEffect(() => {
    if (id) {
      dispatch({
        type: getRecords.type,
        payload: {
          url: `/User/${id}/OrganizationUser`,
          appendCurrentReducerKey: "userData",
        },
      });
    }
    return () => {
      dispatch({ type: resetData.type });
      dispatch({ type: clearForm.type });
    };
  }, [id]);

  useEffect(() => {
    if (created) {
      navigate("/user-management");
    }
  }, [created]);

  useEffect(() => {
    setTimeout(() => {
      getData();
    }, 200);
  }, []);

  const getData = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: "/Role",
        appendCurrentReducerKey: "rolesData",
      },
    });
  };

  function addRecord(params) {
    // return;
    const updatedParams = {
      ...params,
      roleId: params?.roleId?.value,
      districtId: params?.districtId?.value
    }
    if (canUpdate) {
      dispatch({
        type: putRecord.type,
        payload: {
          ...updatedParams,
          url: `/User/${id}`,
        },
      });
      return;
    } else {
      dispatch({
        type: createRecord.type,
        payload: {
          ...updatedParams,
          url: "/User",
        },
      });
    }

    // dispatch({ type: "form/clearForm" });
  }

  return (
    <div className="col-sm-12 d-flex flex-row justify-content-center items-center bg-white shadow p-5 rounded  ">
      <div className="p-2">
        <Loader loading={loading} />
        <WithPageHeader heading={(canUpdate?'Update':'Add')+" User"} text="" buttons={[]} />
        <Form
          formData={[...fields] ?? []}
          onSubmit={(params) => addRecord(params)}
          withValidation={false}
          extraInputClass=""
          autoComplete="off"
        />
      </div>
    </div>
  );
};

export default AddUpdateUserManagement;
