import React from "react";
import Loader from "../loader/loader";
import { Link } from "react-router-dom";

import { LOGIN_IMG, LOGO } from "../../assets/GlobalMediaAssets";
import "./Login_Signup.scss";
import { useSelector } from "react-redux";
import govtLogo from '../../assets/kpk-govt-logo.png'
import pdma from '../../assets/kpk-pdma-logo.png'

const LoginTemplate = ({ children, meta, forgetpass }) => {
  const { loading } = useSelector((state) => state.records);
  return (
    <div className="container-fluid d-flex justify-content-center align-items-center bg-white p-3" style={{ minHeight: "100vh" }}>
      <div className="shadow row bg-white col-12 col-md-10 col-lg-9 p-0" style={{ borderRadius: "8px", minHeight: "75vh" }}>
        <Loader loading={loading} />
        <div className="d-none d-md-flex flex-column align-items-center justify-content-around col-md-6 p-4 rounded-start" style={{ backgroundColor: "#C7DCD1" }}>
          <div className="text-center">
            <p className="m-0 text-wel">Welcome to</p>
            <p className="m-0 text-noc">NOC Portal</p>
            <p className="m-0 text-noc-b">Relief Rehabilitation & Settlement Department</p>
            <p className="m-0 text-noc-b">Provincial Disaster Management Authority</p>
          </div>
          <div className="d-flex flex-column align-items-center gap-4">
            <div className="d-flex">
              <img src={govtLogo} alt="govt-logo" className="logo-des" />
              <img src={pdma} alt="pdma-logo" className="logo-des" />
            </div>
            <p className="m-0 text-noc-b">Government of Khyber Pakhtunkhwa</p>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center col-12 col-md-6 p-4">
          <div className="d-inline-flex align-items-center">
            <img className="img-fluid logo-img" src={LOGO} alt="NOC" />
            <p className="mx-2 my-0 text-noc">NOC</p>
          </div>
          <div className="container py-2">
            <p className="my-0 text-noc-label">{meta?.headerTitle}</p>
            <p className="my-0 text-noc-light">{meta?.headerDescription}</p>
          </div>
          {children}
          {!forgetpass && (
            <div className="mt-4">
              <Link to="/forget" className="text-primary">
                Forget Password?
              </Link>
            </div>
          )}
          <div className="d-flex justify-content-center mt-2">
            <div className="seprator-line"></div>
            <span className="text-noc-light or-seprator">or</span>
            <div className="seprator-line"></div>
          </div>
          <div className="d-flex justify-content-center my-4">
            <p className="text-noc-light mx-1">
              {meta?.footerText}{" "}
              <Link to={meta?.footerLink} className="text-primary">
                {meta?.footerLinkText}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginTemplate;
