import React from "react";
import "./fundsdetails.scss";
import ReadableTable from "../readableTable/ReadableTable";

const FundsDetails = ({ fundsDetails = [], ...props }) => {
  const calculateTotalAmount = (data, currencyType) => {
    let totalAmount = 0;

    data?.forEach((item) => {
      if (currencyType === "USD") {
        totalAmount += item.amountinUSD;
      } else if (currencyType === "PKR") {
        totalAmount += item.amountInPKR;
      }
    });

    return totalAmount;
  };

  const header = [
    "Sector",
    "District",
    `Amount in ${fundsDetails?.projectCost?.fundCurrencyName}`,
    "Equivalent PKR Amount",
    "% Age To Total",
    "Remarks",
  ]?.filter((i) =>
    fundsDetails?.projectCost?.fundCurrencyName === "PKR"
      ? i !== "Equivalent PKR Amount"
      : i
  );

  return (
    <div className="funds-details d-flex flex-column gap-1 mt-3">
      <div className="noc-project-summary  p-0">
        <span className="header">Funds Details</span>
      </div>
      <div className="bg-white d-flex flex-column justify-content-between gap-2 mb-2 px-5 py-4 mt-3 rounded-top-2">
        <div>
          <div>Fund Currency</div>
          <div className="header-name col-md-4">
            {fundsDetails?.projectCost?.fundCurrencyName == "USD"
              ? fundsDetails?.projectCost?.amountinUSD
              : fundsDetails?.projectCost?.amountInPKR}{" "}
            {fundsDetails?.projectCost?.fundCurrencyName}
          </div>
        </div>
        <div className="d-flex flex-column gap-3 mt-3">
          <span className="label">Program Cost</span>

          <div className="d-flex flex-row gap-3 ">
            <div className=" col-md-4 d-flex flex-column gap-1 ">
              {
                <span className="header-child">Amount in {fundsDetails?.projectCost?.fundCurrencyName}</span>
              }
              <div className="header-name col-md-12">
                <p>{fundsDetails?.projectCost?.amountinUSD}</p>
              </div>
            </div>

            {fundsDetails?.projectCost?.fundCurrencyName !== "PKR" && (
              <div className=" col-md-4 d-flex flex-column gap-1 ">
                <span className="header-child">Equivalent PKR Amount</span>
                <div className="header-name col-md-12">
                  <p>{fundsDetails?.projectCost?.amountInPKR}</p>
                </div>
              </div>
            )}

            <div className=" col-md-4 d-flex flex-column gap-1 ">
              <span className="header-child">% Age to Total</span>
              <div className="header-name col-md-12">
                <p>{fundsDetails?.projectCost?.totalPercentage}</p>
              </div>
            </div>
          </div>

          <div className=" col-md-6 d-flex flex-column gap-1 ">
            <span className="header-child">Remarks</span>
            <div className="header-name col-md-12">
              <p>{fundsDetails?.projectCost?.remarks}</p>
            </div>
          </div>
        </div>
        <div>
          <ReadableTable
            label="Operational Cost"
            headers={header}
            data={
              fundsDetails?.projectCost?.operationalCost?.map(
                ({ id, districtId, clusterId,amountInPKR, ...rest }) => ({
                  ...(!fundsDetails?.projectCost?.fundCurrencyName == "PKR" && {
                    amountInPKR,
                  }),
                  ...rest,
                })
              ) ?? []
            }
          />
        </div>

        <div className="col-md-12 d-flex flex-column gap-2">
          <span className="label">Amount Of Funds Confirmed</span>
          <div className="d-flex flex-row gap-3">
            <div className="d-flex flex-row gap-3 col-md-12 ">
              <div className=" col-md-6 d-flex flex-column gap-1 ">
                <div className="header-name d-flex flex-row gap-1 col-md-12">
                  <p className="header-child">
                    {fundsDetails?.projectCost?.fundCurrencyName}:
                  </p>
                  <p>
                    {fundsDetails?.projectCost?.amountinUSD +
                      calculateTotalAmount(
                        fundsDetails?.projectCost?.operationalCost,
                        "USD"
                      )}
                  </p>
                </div>
              </div>
              {fundsDetails?.projectCost?.fundCurrencyName !== "PKR" && (
                <div className=" col-md-6 d-flex flex-column gap-1 ">
                  <div className="header-name d-flex flex-row gap-1 col-md-12">
                    <p className="header-child">Amount in PKR:</p>
                    <p>
                      {fundsDetails?.projectCost?.amountInPKR +
                        calculateTotalAmount(
                          fundsDetails?.projectCost?.operationalCost,
                          "PKR"
                        )}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundsDetails;
