import React, { useEffect } from "react";
import { PlusCircle, Trash3 } from "react-bootstrap-icons";
import { useState } from "react";

import axios from "./../../redux/sagas/api-client";
import FaIcon from "../icon/FaIcon";
import {
  isEmpty,
  handleChangeStrategies,
  getConditionalFields,
} from "../form/form.helper";
import "./ProgressDetailTable.scss";
import Input from "../form/Input";
import { baseUrl } from "../../redux";
import { useSelector } from "react-redux";
import { getAuthToken } from "../../utils/util";

const ProgressDetailTable = ({
  name,
  defaultRows,
  header,
  onChange,
  value,
  ...otherProps
}) => {
  useEffect(() => {
    const initialRows = value ? value : [];
    if (!value?.edit && rows.length == 0) setRows(initialRows);
  }, [value]);

  const defaultForm = header.reduce((c, v) => {
    return { ...c, [v.name]: { ...v, value: "" } };
  }, {});
  const [form, setForm] = useState(defaultForm);
  const [rows, setRows] = useState([]);
  const [clearInput, setClearInput] = useState(false);
  const handleSubmitChanges = (updatedRows) => {
    setRows(updatedRows);
    onChange({ target: { name, value: updatedRows } });
  };
  const { values } = useSelector((state) => state.form);
  const nocId = values?.nocApplicationId?.value?.value;

  const checkRequired = () => {
    const required = header
      .filter((i) => i.isRequired)
      .filter((j) => {
        return form[j.name].value == "";
      });
    if (required.length) {
      setClearInput(false);
      setForm({
        ...form,
        [required[0].name]: {
          ...required[0],
          ...form[required[0].name],
          className: `${form[required[0].name].className} danger`,
        },
      });
    }
    return !required.length;
  };

  const getProgressAchievedAmount = async (workTypeId) => {
    const token = getAuthToken();
    //axios call to get progressAchievedAmount
    return axios.get(baseUrl + `/api/NocApplication/${nocId}/GetProgressAchievedAmount?workTypeId=${workTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token
      }
    })
  }

  const modifyFormVal = async () => {
    let modifiedForm = { ...form };
    if (form.workTypeId?.value) {
      const workTypeId = form.workTypeId?.value?.value;
      const progressAchievedAmount = await getProgressAchievedAmount(workTypeId);
      if (modifiedForm?.achievedAmount?.value && !isNaN(modifiedForm?.achievedAmount?.value)) {
      }

      if (!isNaN(modifiedForm?.achievedAmount?.value)) {
        const achievedCommulative = parseFloat(modifiedForm?.achievedAmount?.value) + parseFloat(progressAchievedAmount?.data);
        modifiedForm = { ...modifiedForm, achievedCommulative: { ...modifiedForm.achievedCommulative, value: achievedCommulative, label: achievedCommulative } };
      }

      if (modifiedForm?.plannedTarget?.value && !isNaN(modifiedForm?.plannedTarget?.value)) {
        const calculatedAcheived = (modifiedForm?.achievedCommulative?.value / modifiedForm?.plannedTarget?.value) * 100;
      
        modifiedForm = { ...modifiedForm, percentageAchieved: { ...modifiedForm.percentageAchieved, value: calculatedAcheived, label: calculatedAcheived.toFixed(2)+'%' } };
      }
    }
    return modifiedForm;
  }

  const handleAdd = async () => {
    if (checkRequired()) {
      //compile data on workTypeId selection and set it to form
      const modifiedFormx = await modifyFormVal()

      const updatedRows = [...rows, { ...modifiedFormx, edit: false }];
      handleSubmitChanges(updatedRows);
      setClearInput(true);
      handleClear();
    }
  };
  const handleClear = () => {
    setForm(defaultForm);
  };
  const handleEdit = (index) => {
    const updatedRows = rows.map((i, j) => {
      if (j === index) {
        setForm(rows[j]);
      }
      return j === index ? { ...i, edit: true } : { ...i, edit: false };
    });
    setRows([...updatedRows]);
    setClearInput(true);
  };

  const handleEditComplete = async (index) => {
    if (checkRequired()) {
      const modifiedFormx = await modifyFormVal()
      const updatedRows = rows.map((row, j) => {
        return j === index ? { ...modifiedFormx, edit: false } : { ...row, edit: false };
      });
      handleSubmitChanges(updatedRows);
      handleClear();
    }
  };
  const handleHeaderChange = (evt, def) => {
    setClearInput(false);
    handleChange(evt, def);
  };
  const handleChange = (evt, def) => {
    let stretegy = "default"; //stretegy = "tableInput";
    if (def && def["onChangeMethod"]) {
      stretegy = def["onChangeMethod"];
    }

    if (def?.id == "workTypeId") {
    }
    handleChangeStrategies(stretegy, form, setForm)(evt, def);
  };

  const handleDelete = (index) => {
    setRows(rows.filter((i, j) => j != index && !isEmpty(i)));
  };

  // let parentForm = {};
  const formatedFields = getConditionalFields(header, form);

  return (
    <div className="tb-input">
      <table className="tableInput">
        <thead>
          <tr>
            {formatedFields.map((data, index) => {
              return <th key={index}>{data.label}</th>;
            })}
            <th className="text-center">
              <span>
                {/* <FaIcon iconName="FaCog" color="#075f33" /> */}
                Action
              </span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            {formatedFields.map((col, index) => {
              let columnData = col;

              if (!clearInput) {
                columnData = { ...columnData, ...form[col.name] };
              } else {
                columnData = { ...columnData, value: "" };
              }
              return (
                <td key={index} className={`input-wrap`}>
                  <Input
                    attributes={{ ...columnData }}
                    // name={col.name}
                    onChange={(evt) => handleHeaderChange(evt, col)}
                  />
                </td>

              );
            })}
            <td className="text-center">
              <button
                className="add-more-btn"
                type="button"
                onClick={() => handleAdd()}
              >
                <PlusCircle color="#075f33" size={16} />
                Add
              </button>
            </td>
          </tr>
          {rows.map((row, j) => {
            return (
              <tr key={j}>
                {Object.values(row).map((col, index) => {
                  return row.edit && typeof col != "boolean" ? (
                    <td key={index} className="input-wrap">
                      <Input
                        attributes={{ ...col, ...form[col.name] }}
                        // defaultValue={col && col}
                        // name={col && col.name}
                        onChange={(evt) => handleChange(evt, col)}
                      />
                    </td>
                  ) : (
                    <td
                      className={
                        row.edit || typeof col == "boolean" ? "act-wrap" : ""
                      }
                      key={index}
                    >
                      {row.edit ? (
                        <div className="act">
                          <button
                            type="button"
                            onClick={() => handleEditComplete(j)}
                          >
                            <FaIcon iconName={"FaCheck"} color="" />
                          </button>
                        </div>
                      ) : (
                        <div className="act">
                          {typeof col == "boolean" ? (
                            <>
                              <button
                                type="button"
                                onClick={() => handleEdit(j)}
                              >
                                <FaIcon iconName={"FaEdit"} color="#075f33" />
                              </button>
                              <button
                                type="button"
                                onClick={() => handleDelete(j)}
                              >
                                <Trash3 />
                              </button>
                            </>
                          ) : (
                            <>{col?.label}</>
                          )}
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {/* <td className="text-center p-2" colSpan={formatedFields.length + 1}>
          <button
            className="add-more-btn"
            type="button"
            onClick={() => handleAdd()}
          >
            <PlusCircle color="#075f33" size={16} />
            Add {rows && rows.length > 0 ? 'More' : 'Data'}
          </button>
        </td> */}
      </table>
    </div>
  );
};

export default ProgressDetailTable;
