
export const BarChartTargetData = {
  series: [
    {
      name: "1",
      type: "column",
      data: [30, 40, 37, 46, 30],
    },
    {
      name: "2",
      type: "column",
      data: [30, 40, 37, 46, 30],
    },
    {
      name: "3",
      type: "column",
      data: [30, 40, 37, 46, 30],
    },
    {
      name: "4",
      type: "column",
      data: [30, 40, 37, 46, 30],
    },
    {
      name: "5",
      type: "line",
      data: [30, 40, 37, 46, 10],
    },
  ],

  options: {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },

    },
    markers: {
      size: 3,
      colors: ["#000"],
      strokeColors: "#000",
      strokeWidth: 1,
      hover: {
        size: 7,
      },
    },
    colors: ["#E63A00", "#FF4800", "#FF8929", "#FFC19A", "#000"],
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    stroke: {
      width: [0, 0, 0, 0, 3],
    },

    xaxis: {
      categories: [2019, 2020, 2021, 2022, 2023],
    },
    tooltip: {
      fixed: {
        enabled: true,
        position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: "center",
      offsetX: 40,
      position: "left",
    },
  },
};

export const BarChartImpactData = {
  series: [
    {
      data: [1380, 1200, 1100, 690, 580, 540, 470],
    },
  ],
  options: {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },

    grid: {
      show: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    colors: ["#E63A00", "#808080"],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        "Total",
        "Gender Equality",
        "Humanitarian Action",
        "Right to Food, Water and Nutrition",
        "Women’s Economic Justice",
        "Right to Health",
        "Climate Justice",
      ],
    },
  },
};

export const PieChartAgeData = {
  series: [20, 30, 50, 70],
  options: {
    chart: {
      type: "donut",
    },
    labels: ["Under 18", "18-25", "55+"],
    legend: {
      horizontalAlign: "left",
      // offsetX: 40,
      position: "bottom",
    },
    colors: ["#6f550a", "#585708", "#BFBE3F", "#DFDF9F"],
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "left",
          },
        },
      },
    ],
  },
};

export const PieChartGenderData = {
  series: [40, 60],
  options: {
    chart: {
      type: "donut",
    },
    labels: ["Female", "Male"],
    legend: {
      horizontalAlign: "left",
      // offsetX: 40,
      position: "bottom",
    },
    colors: ["#610043", "#940066", "#B9669F"],
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  },
};

export const PieChartDisabilityData = {
  series: [10, 10, 10, 10, 10, 50],
  options: {
    chart: {
      type: "donut",
    },
    labels: [
      "Developmental",
      "Agility",
      "Hearing",
      "Mobility",
      "Speech",
      "Learning",
    ],
    legend: {
      horizontalAlign: "left",
      position: "bottom",
      labels: {
        align: "left",
      },
    },
    colors: ["#002528", "#003E43", "#00565D", "#1A8891", "#80BDC2", "#B3D7DA"],
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  },
};

export const ActualVsTargetData = {
  series: [
    {
      name: "Q1 Budget",
      group: "budget",
      data: [44000, 55000, 41000, 67000, 22000, 43000],
    },
    {
      name: "Q1 Actual",
      group: "actual",
      data: [48000, 50000, 40000, 65000, 25000, 40000],
    },
    {
      name: "Q2 Budget",
      group: "budget",
      data: [13000, 36000, 20000, 8000, 13000, 27000],
    },
    {
      name: "Q2 Actual",
      group: "actual",
      data: [20000, 40000, 25000, 10000, 12000, 28000],
    },
  ],
  options: {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    dataLabels: {
      formatter: (val) => {
        return val / 1000 + "K";
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: [2020, 2021, 2022, 2023, 2024, 2025],
    },
    fill: {
      opacity: 1,
    },
    colors: ["#80c7fd", "#008FFB", "#80f1cb", "#00E396"],
    yaxis: {
      labels: {
        formatter: (val) => {
          return val / 1000 + "K";
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  },
};
