import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import { customStyles } from "../../assets/styles/CustomStyle";
import UserManagementColumns from "../../assets/columns/UserManagementColumns";
import { useDispatch, useSelector } from "react-redux";
import WithPageHeader from "../../components/ui/withPageHeader";
import { createRecord, getRecords } from "../../redux/reducers";
import userOrganizationStatus from "../../assets/values/userOrganizationStatus";
import Loader from "../../components/loader/loader";
import { FaBan, FaCheck, FaTrash } from "react-icons/fa";
import DropdownButton from "../../components/dropdown-button/DropdownButton";
import { deleteRecord } from "../../redux/reducers/recordSlice";

const OrganizationTabForManagment = () => {
  const dispatch = useDispatch();
  const { organizationList } = useSelector((state) => state.records?.current);
  const { loading, created, creating, searchText } = useSelector(
    (state) => state.records
  );
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [filteredData, setFilteredData] = useState(organizationList?.data);
  const [perPage, setPerPage] = useState(organizationList?.pageSize ?? 10);
  const [page, setPage] = useState(organizationList?.pageNumber ?? 1);

  useEffect(() => {
    getUserManagement();
  }, [created, searchText?.generalSearch]);

  useEffect(() => {
    setFilteredData(organizationList?.data);
  }, [organizationList]);

  useEffect(() => {
    handlePaginationPerpage();
  }, [perPage, page]);


  

  const getUserManagement = () => {
    const params = {
      isPdmaUser: false,
      pageNumber: page,
      pageSize: perPage,
    };
    if (searchText?.generalSearch) {
      params.search = searchText.generalSearch;
    }
    dispatch({
      type: getRecords.type,
      payload: {
        url: "/User",
        params,
        appendCurrentReducerKey: "organizationList",
      },
    });
  };

  const handlePaginationPerpage = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/User?isPdmaUser=false&pageNumber=${page}&pageSize=${perPage}`,
        appendCurrentReducerKey: "organizationList",
      },
    });
  };

  const filterByStatus = (type) => {
    setSelectedStatus(type);
    if (type === "All") {
      setFilteredData(organizationList?.data);
    } else {
      const filtered = organizationList?.data?.filter(
        (record) => record.status === type
      );

      setFilteredData(filtered);
    }
  };

  const getStatusClass = (type) => {
    return selectedStatus === type ? "active" : "";
  };

  const statusList = Object.entries(userOrganizationStatus).map(
    ([key, value]) => ({
      text: value,
      className: `${getStatusClass(value)}`,
      onClick: () => filterByStatus(value),
    })
  );

  const handleApprove = (id) => {
    dispatch({
      type: createRecord.type,
      payload: {
        url: `/User/${id}/approve`,
        message: "Organization Approved Successfully!",
      },
    });
  };
  const handleBlock = (id) => {
    dispatch({
      type: createRecord.type,
      payload: {
        url: `/User/${id}/block`,
        message: "Organization Blocked Successfully!",
      },
    });
  };

  const handleDelete = (id) => {
    dispatch({
      type: deleteRecord.type,
      payload: {
        id:id,
        url: `/User`,
        message: "Organization Deleted Successfully!",
      },
    });
  };

  const NgoActions = [
    {
      label: "Approve",
      icon: FaCheck,
      onClick: handleApprove,
    },
    {
      label: "Block",
      icon: FaBan,
      onClick: handleBlock,
    },
  ];


  const NgoActionsDelete = [
    {
      label: "Approve",
      icon: FaCheck,
      onClick: handleApprove,
    },
    {
      label: "Delete",
      icon: FaTrash,
      onClick: handleDelete,
    },
  ];

  const columns = [
    ...UserManagementColumns,
    {
      name: "Action",
      width: "100px",
      cell: (row) => (
        
        <DropdownButton rowId={row?.userId} actions={row?.status ==="Blocked" ? NgoActionsDelete : NgoActions} />
      ),
    },
  ];
  return (
    <div className="bg-white shadow-sm rounded p-3 mt-3 table-container-anim">
      <Loader loading={loading} />
      <WithPageHeader
        heading="NGO's & Organizations List"
        buttons={[...statusList]}
      />
      <div className="mt-3">
        <Table
          loading={loading}
          columns={columns}
          customStyles={customStyles}
          data={filteredData}
          pagination
          paginationServer
          paginationTotalRows={organizationList?.totalRecords}
          onChangeRowsPerPage={(pP) => {
            setPerPage(pP);
          }}
          onChangePage={(p) => {
            setPage(p);
          }}
          dense
        />
      </div>
    </div>
  );
};

export default OrganizationTabForManagment;
