import React, { useEffect } from "react";
import WithPageHeader from "../../components/ui/withPageHeader";
import "./GrievencePage.scss";
import { useDispatch, useSelector } from "react-redux";
import { createRecord, getRecords } from "../../redux/reducers";
import { customStyles } from "../../assets/styles/CustomStyle";
import Table from "../../components/table/Table";
import { grivenceColumns } from "../../assets/columns/GrivenceColumns";
const GrievencePage = () => {
  const [checked, setChecked] = React.useState([]);
  const [otherValue, setOtherValue] = React.useState("");
  const [showOtherInput, setShowOtherInput] = React.useState(false);
  const { role } = useSelector((state) => state.setting?.user);
  const { current, created, loading, creating } = useSelector(
    (state) => state.records
  );
  const { grievenceTypeList, grievenceList } = current;
  const [perPage, setPerPage] = React.useState(grievenceList?.pageSize ?? 10);
  const [page, setPage] = React.useState(getGrievenceList?.pageNumber ?? 1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (created) {
      setChecked([]);
      setOtherValue("");
      setPage(1);
      getGrievenceList();
    }
  }, [created]);

  useEffect(() => {
    getGrievenceList();
  }, []);

  useEffect(() => {
    handlePaginationPerpage();
  }, [perPage, page]);

  function getGrievenceList() {
    dispatch({
      type: getRecords.type,
      payload: {
        url: "/GrievanceType",
        appendCurrentReducerKey: "grievenceTypeList",
      },
    });
    dispatch({
      type: getRecords.type,
      payload: {
        url: "/Grievance",
        appendCurrentReducerKey: "grievenceList",
      },
    });
  }

  function handleCheckboxChange(e) {
    const value = e.target.value;
    const name = e.target.name;

    if (checked.includes(value)) {
      setChecked(checked.filter((item) => item !== value));
      name === "Other (Please Specify)" && setShowOtherInput(false);
      if (name === "Other (Please Specify)") setOtherValue("");
    } else {
      setChecked([...checked, value]);
      name === "Other (Please Specify)" && setShowOtherInput(true);
    }
  }

  function handleOtherInputChange(e) {
    setOtherValue(e.target.value);
  }

  const onSubmit = () => {
    const getItemsObj = grievenceTypeList
      .filter((item) => checked.includes(item.id.toString()))
      .map((item) => {
        if (item.name === "Other (Please Specify)") {
          return { ...item, name: otherValue };
        }
        return item;
      });

    const iTypesArray = {
      types: getItemsObj.map((item) => ({
        typeId: item.id,
        value: item.name,
      })),
    };

    dispatch({
      type: createRecord.type,
      payload: {
        url: "/Grievance",
        ...iTypesArray,
        isFormatted: true,
      },
    });
  };

  const handlePaginationPerpage = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Grievance?pageNumber=${page}&pageSize=${perPage}`,
        appendCurrentReducerKey: "grievenceList",
      },
    });
  };

  const columns = [...grivenceColumns];

  return (
    <div className="px-4 py-2 mt-5">
      <div className="bg-white shadow-sm rounded p-3 table-container-anim">
        {role === "SuperAdmin" ? (
          <>
            <WithPageHeader
              heading="Suggestions and Complaints List"
              buttons={[]}
            />
            <div className="mt-3 ms-3">
              <Table
                data={grievenceList?.data ?? []}
                columns={columns}
                customStyles={customStyles}
                pagination
                paginationServer
                paginationTotalRows={grievenceList?.totalRecords}
                onChangeRowsPerPage={(pP) => {
                  setPerPage(pP);
                }}
                onChangePage={(p) => {
                  setPage(p);
                }}
                dense
              />
            </div>
          </>
        ) : (
          <>
            <WithPageHeader
              heading="Please select the issue(s) you are experiencing with the NOC issuance system:"
              buttons={[]}
            />
            <div className="mt-3 ms-3">
              {grievenceTypeList?.map((item) => {
                return (
                  <div className="mb-3 d-flex align-items-center" key={item.id}>
                    <input
                      type="checkbox"
                      className="me-4 custom-checkbox"
                      name={item.name}
                      id={item.id}
                      onChange={handleCheckboxChange}
                      value={item.id}
                      checked={checked.includes(item.id.toString())}
                    />
                    <label htmlFor={item.name}>{item.name}</label>
                  </div>
                );
              })}
              {showOtherInput ? (
                <textarea
                  className="form-control"
                  placeholder="Please specify"
                  value={otherValue}
                  onChange={handleOtherInputChange}
                ></textarea>
              ) : (
                ""
              )}
              <button onClick={onSubmit} className="btn btn-success mt-3 w-25">
                Submit
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GrievencePage;
