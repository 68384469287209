import React from "react";
import "./NocSupportingDocuments.scss";
import { Download } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import { getRecords } from "../../redux/reducers";
import { handleDownloadFile } from "../../Utility";
import { baseUrl } from "../../redux"
import axios from "./../../redux/sagas/api-client";
import { getAuthToken } from "../../utils/util";



const NocSupportingDocuments = ({ supportingDocuments = [] }) => {
  const dispatch = useDispatch();
  // const handleDownload = async (e,filepath) => {
  //   e.preventDefault();
  //   const token = getAuthToken();

  //   try {
  //     const response = await axios.get(`${baseUrl}/api/FileUpload/download?filePath=${encodeURIComponent(filepath)}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         'Authorization': `Bearer ${token}`
  //       },
  //       responseType: 'blob' // Ensure the response is treated as a blob
  //     });

  //     const url = URL.createObjectURL(response.data);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = filepath.split('/').pop() ||'Kpk-Noc-Document'; // Default to 'download' if no documentName is provided
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     URL.revokeObjectURL(url);
  //   } catch (error) {
  //     // console.error("Error downloading file:", error);
  //   }
  // };
  return (
    <div>
      <div className="header-documents">
        <p className="header-text">Supporting Documents</p>
      </div>
      <div className="col-md-12 bg-white shadow-sm mb-2 p-4 rounded">
        <div className="row g-4">
          {supportingDocuments?.applicationDocuments?.map((item) => (
            <div className={`col-md-6`} key={item?.id}>
              <p className="mb-2 text-black label-text">{item?.documentName}</p>
              <div className="border px-3 rounded d-flex align-items-center justify-content-between p-2">
                <p className="px-0 my-0 general-text"> {item?.appDoc.split('/').pop() ?? "Download File"}</p>
                <label for="files" className="upload-btn">
                  <button className="download-btn" onClick={(e)=>{handleDownloadFile(e,item?.appDoc)}} > <Download size={20} color="white" /> Download</button>
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NocSupportingDocuments;
