import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getRecords } from "../../redux/reducers";
import Table from "../../components/table/Table";
import FilterIcon from "../../assets/images/Filter.svg";
import WithPageHeader from "../../components/ui/withPageHeader";
import { MonthlyReportColumns } from "../../assets/columns/MonthlyReportColumns";
import { defaultListStyle } from "../../assets/styles/ListStyle";
import "./MonthlyReport.scss";
import reportStatus from "../../assets/values/reportStatus";
import SubmitIcon from "../../assets/icons/SubmitIcon";
import { customStyles } from "../../assets/styles/CustomStyle";

const MonthlyReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {monthlyReportList = []} = useSelector(
    (state) => state?.records?.current
  );


  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedRows, setSelectedRows] = useState(false);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [filteredData, setFilteredData] = useState(monthlyReportList?.data ?? []);
  const [perPage, setPerPage] = useState(monthlyReportList?.pageSize ?? 10);
  const [page, setPage] = useState(monthlyReportList?.pageNumber ?? 1);

  useEffect(() => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/ProgressReport/GetAllProgressReports`,
        appendCurrentReducerKey: "monthlyReportList",
      },
    });
  }, []);

  useEffect(() => {
    if (monthlyReportList?.data?.length > 0) setFilteredData(monthlyReportList?.data ?? []);
  }, [monthlyReportList?.data]);

  useEffect(() => {
    handlePaginationPerpage();
  }, [perPage, page]);
  const handlePaginationPerpage = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/ProgressReport/GetAllProgressReports?pageNumber=${page}&pageSize=${perPage}`,
        appendCurrentReducerKey: "monthlyReportList",
      },
    });
  };


  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };

  const filterByStatus = (type) => {
    setSelectedStatus(type);
    if (type === "All") {
      setFilteredData(monthlyReportList?.data ?? []);
    } else {
      const filtered = monthlyReportList?.data?.filter((record) => record?.progressReportStatus === type);
      setFilteredData(filtered);
    }
  };

  const getStatusClass = (type) => {
    return selectedStatus === type ? "active" : "";
  };

  const statusList = Object.entries(reportStatus).map(([key, value]) => {
    return {
      text: value,
      className: `${getStatusClass(value)}`,
      onClick: () => filterByStatus(value),
    };
  });

  return (
    <div className="px-4 py-2 mt-5">
      <WithPageHeader
        heading="Progress Reports"
        text="Monitor and evaluate the monthly progress of NOCs"
        buttons={[
          {
            icon: <SubmitIcon fill="#fff" />,
            text: "Add Progress Report",
            onClick: () => navigate("/monthly-report/add"),
            className: "regularButton",
            style: { gap: "8px" },
          },
        ]}
      />

      <div className="bg-white shadow-sm rounded p-3 table-container-anim">
        <WithPageHeader
          heading="Monthly Progress Reports List"
          buttons={[
            ...statusList,
          ]}
        />
        <div className="mt-3">
          <Table
            columns={MonthlyReportColumns}
            data={filteredData}
            customStyles={customStyles}
            pagination
            paginationServer
            paginationTotalRows={monthlyReportList?.totalRecords}
            onChangeRowsPerPage={(pP) => {
              setPerPage(pP);
            }}
            onChangePage={(p) => {
              setPage(p);
            }}
            dense
            onSelectedRowsChange={handleChange}
            clearSelectedRows={toggledClearRows}
          />
        </div>
      </div>
    </div>
  );
};

export default MonthlyReport;
