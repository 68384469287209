import React from "react";
import "./laoder.scss";
import { LOGO } from "../../assets/GlobalMediaAssets";

const Loader = ({
  loading,
  otherProps,
  text = "",
  overlay = true,
  theme = "dark",
}) => {
  return (
    <>
      {loading && (
        <div
          className={`wrapper ${overlay && "overlay"} ${theme}`}
          {...otherProps}
        >
           <div className="loader-inner">
            <div className="img-wrapper">
              <img className="img-logo" style={{"userSelect":"none"}}  src={LOGO} alt="loading" />
            </div>
          </div>
          {text}
        </div>
      )}
    </>
  );
};

export default Loader;
