import fieldsReducer from './reducers/fieldsSlice';
import formReducer from './reducers/formSlice';
import recordsReducer from './reducers/recordSlice';
import settingReducer from './reducers/settingSlice';
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { watcherSaga } from "./sagas";


import logger from "redux-logger";

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
  fields: fieldsReducer,
  form: formReducer,
  records: recordsReducer,
  setting:settingReducer
});

const store = configureStore({
  reducer,
  middleware: [sagaMiddleware, logger],
});
sagaMiddleware.run(watcherSaga);

//export const baseUrl = "http://203.124.35.61:8101/";
// export const baseUrl = "http://localhost:8101/";

export default store;
