import React, { useState, useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import "./PageHeader.scss";

const PageHeader = ({
  heading,
  headingProps = {},
  text,
  buttons,
  containerProps = {},
  headingContainerProps = {},
  buttonsContainerProps = {},
}) => {
  // State to determine if screen is small
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    // Function to update state based on screen size
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="container-fluid d-flex justify-content-between align-items-center"
      {...containerProps}
    >
      <div className="" {...headingContainerProps}>
        {heading && (
          <p className="heading1 py-0 my-0 " {...headingProps}>
            {heading}
          </p>
        )}
        {text && <p className="py-0 ">{text}</p>}
      </div>

      <div className="buttons-container" {...buttonsContainerProps}>
        {isSmallScreen
          ? buttons.length > 0 && (
              <Dropdown>
                <DropdownButton variant="primary" id="dropdown-basic">
                  {buttons
                    .filter(Boolean)
                    .map(({ className = "", ...btn }, i) => (
                      <Dropdown.Item
                        as="button"
                        className={`button ${className}`}
                        key={i}
                        {...btn}
                      >
                        {btn.icon && <span className="icon">{btn.icon}</span>}
                        {btn.text && (
                          <span className="text-regular">{btn.text}</span>
                        )}
                      </Dropdown.Item>
                    ))}
                </DropdownButton>
                <Dropdown.Menu>
                  {buttons
                    .filter(Boolean)
                    .map(({ className = "", ...btn }, i) => (
                      <Dropdown.Item
                        as="button"
                        className={`button ${className}`}
                        key={i}
                        {...btn}
                      >
                        {btn.icon && <span className="icon">{btn.icon}</span>}
                        {btn.text && (
                          <span className="text-regular">{btn.text}</span>
                        )}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            )
          : buttons.filter(Boolean).map(({ className = "", ...btn }, i) => {
              return (
                <React.Fragment key={i}>
                  {btn?.isStyled ? (
                    btn?.icon
                  ) : (
                    <button className={`button ${className}`} {...btn}>
                      {btn.icon && <span className="icon">{btn.icon}</span>}
                      {btn.text && (
                        <span className="text-regular">{btn.text}</span>
                      )}
                    </button>
                  )}
                </React.Fragment>
              );
            })}
      </div>
    </div>
  );
};

export default PageHeader;
