import React from 'react'
import ReadableTable from '../readableTable/ReadableTable';

const NocLogicalFramework = ({NocLogicalFramework = [], ...props}) => {
  const header = [
    "Narrative Summary",
    "Expected Result Type",
    "Expected Results",
    "Performance Measurement",
    "Assumptions And Risks"
  ];
  return (
    <div className="noc-project-summary">
    <div className="header p-0">Logical Framework</div>

    <div className=" d-flex flex-column gap-2 col-md-12 px-5 py-3">
      <div className=" col-md-12">
        <ReadableTable
          label="Logical Framework"
          headers={header}
          data={
            NocLogicalFramework?.map(({id, ...rest}) => rest) ?? []
          }
        />
      </div>
    </div>
  </div>
  )
}

export default NocLogicalFramework