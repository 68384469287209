import React from 'react'
import ReadableTable from '../readableTable/ReadableTable';

const BeneficiariesList = ({ beneficiariesList = [], ...props }) => {
  const header = [
    'Sector',
    'District',
    'Households',
    'No. Of Males',
    'No. Of Females',
    'Total Beneficiaries',
  ];



  return (

    <div className="noc-project-summary">
      <div className="header p-0">Beneficiaries List</div>

      <div className="body col-md-12 pe-4 ps-4 pb-4">
        <div className="project-detail col-md-12">

          <ReadableTable
            label="Beneficiaries Detailed Breakdown"
            headers={header}
            // data={tabelData}


            data={
              beneficiariesList?.projectBeneficiaries?.map(({ id, districtId, clusterId,districtName, clusterName, noOfMales, noOfFemales,houseHolds, ...rest }) => ({
                clusterName,
                districtName,
                houseHolds,
                noOfMales,
                noOfFemales,
                totalBeneficiaries: noOfMales + noOfFemales
              })) ?? []
            }
          />
        </div>
      </div>
    </div>

  )
}

export default BeneficiariesList