import React from "react";
import { useLocation } from "react-router-dom";
import {
  PieChartAgeData,
  PieChartDisabilityData,
  PieChartGenderData,
} from "../../components/apex/ApexSampleData";
import BarChartApex from "../../components/apex/BarChartApex";
import BarChartHorizontalApex from "../../components/apex/BarChartHorizontalApex";
import PieChartApex from "../../components/apex/PieChartApex";
import SearchList from "../../components/search-list/SearchList";

export const CHART_TYPE = {
  Bar_CHART: "chart",
  Donut_CHART: "donut",
  Vertical_bar_CHART: "vertical",
};

const ChartDetail = () => {
  const location = useLocation();
  const type = location.state.type;
  return (
    <>
      <div className="row col-md-12 justify-content-center">
        <SearchList searchFieldName="Title" placeholder="Search Project" />
        <SearchList searchFieldName="Title" placeholder="Sort" />
        <SearchList searchFieldName="Title" placeholder="Filter" />
      </div>
      <div>
        {" "}
        {type == CHART_TYPE.Bar_CHART && (
          <BarChartHorizontalApex innerHeight={true} />
        )}
        {type == CHART_TYPE.Vertical_bar_CHART && (
          <BarChartApex innerHeight={true} />
        )}
        {type == CHART_TYPE.Donut_CHART && (
          <div className="row m-5 p-5" style={{ backgroundColor: "#fff" }}>
            <div className="col-md-4">
              <PieChartApex data={PieChartAgeData} innerHeight={true} />
            </div>
            <div className="col-md-4">
              <PieChartApex data={PieChartGenderData} innerHeight={true} />
            </div>
            <div className="col-md-4">
              <PieChartApex data={PieChartDisabilityData} innerHeight={true} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChartDetail;
