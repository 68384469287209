import React from "react";
import "./NocProjectSummary.scss";
import { getFormatedDate } from "../../Utility";
import nodata_Img from "../../assets/images/no-data.png";
import { useSelector } from "react-redux";
const NocProjectSummary = ({ projectData = [], ...props }) => {
  const { monthlyProgressReport = [] } = useSelector(
    (state) => state?.records?.current
  );
  return (
    <div className="noc-project-summary">
      <div className="header p-0">Project Summary</div>

      <div className="body col-md-12 pb-2">
        <div className="project-detail col-md-8">
          <div className="project-detail-heading p-1 mb-2">Project</div>
          <div className="project-detail-data col-md-12 py-4 px-2">
            <div className="d-flex flex-row gap-2  align-items-center p-2">
              <div className="d-flex flex-row gap-2  align-items-center col-md-6 ">
                <span className="bold-header">Project Nature:</span>
                <span className="text-data">
                  {projectData?.projectClassifications?.map((item) => {
                    return (
                      <span className="text-data  d-flex" key={item?.id}>
                        <span className="col-md-8">
                          <div className="badge bg-success">
                            {item?.interventionType}
                          </div>
                        </span>
                        <span className="col-md-8">
                          <div className="badge bg-success">
                            {item?.projetcNature}
                          </div>
                        </span>
                      </span>
                    );
                  })}
                </span>
              </div>
              <div className="d-flex flex-row gap-2 col-md-6 ">
                <span className="bold-header">Project Plan:</span>

                <span className="text-data">
                  {projectData?.responsePlans?.map((item) => {
                    return (
                      <span className="text-data  d-flex gap-2" key={item?.id}>
                        {item?.plan}
                      </span>
                    );
                  })}
                </span>
              </div>
            </div>
            <div className="d-flex flex-row gap-3  align-items-center p-2">
              <div className="d-flex flex-row gap-2 col-md-6 ">
                <span className="bold-header">Project Cluster:</span>
                <span className="text-data">
                  {projectData?.applicationClusters?.map((item) => {
                    return (
                      <span className="text-data  d-flex gap-2" key={item?.id}>
                        {item?.clusterName}
                      </span>
                    );
                  })}
                </span>
              </div>
              <div className="d-flex flex-row gap-2 col-md-6 ">
                {/* TODO Dont know what to put here */}
                <span className="bold-header">Geo Location:</span>
                <span className="text-data">
                  {projectData?.geographicLocations &&
                    Array.isArray(projectData?.geographicLocations) &&
                    projectData?.geographicLocations
                      .map((loc) => loc?.districtName)
                      .join(",")}
                </span>
              </div>
            </div>

            <div className="d-flex flex-row gap-3  align-items-center p-2">
              <div className="d-flex flex-row gap-2 col-md-6 ">
                <span className="bold-header">Thematic Area:</span>
                <span className="text-data">
                  {projectData?.thematicAreas?.map((item) => {
                    return (
                      <span className="text-data  d-flex gap-2" key={item?.id}>
                        {item?.areaName}
                      </span>
                    );
                  })}
                </span>
              </div>
              <div className="d-flex flex-row gap-2 col-md-6 ">
                <span className="bold-header">Project Cost:</span>
                <span className="text-data">
                  {projectData?.projectCost?.amountinUSD}
                  {projectData?.projectCost?.fundCurrencyName}
                </span>
              </div>
            </div>

            <div className="d-flex flex-row gap-3  align-items-center p-2">
              <div className="d-flex flex-row gap-2 col-md-6 ">
                <span className="bold-header">Start Date:</span>
                <span className="text-data">
                  {getFormatedDate(projectData?.projectStartDate)}
                </span>
              </div>
              <div className="d-flex flex-row gap-2 col-md-6 ">
                <span className="bold-header">End Date:</span>
                <span className="text-data">
                  {getFormatedDate(projectData?.projectEndDate)}
                </span>
              </div>
            </div>

            <div className="d-flex flex-row gap-3  align-items-center p-2">
              <div className="d-flex flex-row gap-2 col-md-6 ">
                {/* TODO Missing from API */}
                {/* <span className="bold-header">Extension:</span> */}
                {/* <span className="text-data">May 2, 2024 - June 4, 2024</span> */}
              </div>
            </div>
          </div>
        </div>

        <div className="project-report col-md-4">
          {/* TODO Missing from API */}
          <div className="project-report-heading p-1 mb-2">Project Reports</div>
          <div className="project-report-data col-md-12">
            {monthlyProgressReport?.length > 0 ? (
              <div className="d-flex flex-column">
                <div className="d-flex flex-row col-md-12 ">
                  <div className="col-md-6 project-report-data-header">
                    Report Month
                  </div>
                  <div className="col-md-6 project-report-data-header">
                    Status
                  </div>
                </div>
                {monthlyProgressReport?.map((data, key) => {
                  return (
                    <div className="d-flex flex-row col-md-12 " key={key}>
                      <div className="col-md-6 project-report-data-body">
                        {data?.reportMonth}
                      </div>
                      <div className="col-md-6 project-report-data-body">
                        {data?.progressReportStatus}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="d-flex flex-row  justify-content-center h-100 align-items-center fw-bolder fs-5 gap-2">
                <img src={nodata_Img} alt="no_data" />
                No Data
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NocProjectSummary;
