import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Form from "../../components/form/Form";
import { clearForm, createRecord } from "../../redux/reducers";
import { getFieldsByCategory } from "../../utils/util";
import { LOGO } from "../../assets/GlobalMediaAssets";
import LoginTemplate from "../../components/login-signup/LoginTemplate";
import toast from "react-hot-toast";

const ForgetPassword = () => {
  const { pathname } = useLocation();

  const val = useSelector((state) => state.records?.response?.data);
  const { error } = useSelector((state) => state.records);

  const { formFields } = useSelector((state) => state.fields);
  const { response } = useSelector((state) => state.records);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { values } = useSelector((state) => state.form);
  const { created } = useSelector((state) => state.records);
 


  const user_id = values?.id?.value ?? null;
  const user_email = values?.email?.value ?? "";
  const forgotPasswordData = {
    resetPasswordToken: values?.resetPasswordToken?.value ?? "",
    password: values?.password?.value ?? "",
  }


  useEffect(() => {
    if (created ) {
  pathname === '/forget' ?    navigate('/reset') : navigate('/login')
      
    }
  }, [created]);

  useEffect(() => {
    dispatch({ type: clearForm.type });
  }, [formFields, error]);

  const resetForm = [
    {
      category: "first",
      name: "email",
      inputType: "email",
      placeholder: "Enter Email Address",
      required: true,
      type: "email",
      wrapperClass: "form-outline mb-2 col-12",
      inForm: true,
    },

    {
      category: "second",
      name: "id",
      inputType: "text",
      label: "Enter Reset Password Verification ID",
      placeholder: "Reset Password Verification ID",
      required: true,
      type: "text",
      wrapperClass: "form-outline mb-1 col-12",
      inForm: true,
    },
    {
      category: "second",
      name: "resetPasswordToken",
      id: "resetPasswordToken",
      inputType: "text",
      label: "Enter Reset Password Verification Code",
      placeholder: "Reset Password Verification Code",
      required: true,
      type: "text",
      wrapperClass: "form-outline mb-1 col-12",
      inForm: true,
    },
    {
      category: "second",
      name: "password",
      inputType: "password",
      label: "New Password",
      placeholder: "New Password",
      required: true,
      type: "password",
      wrapperClass: "form-outline mb-1 col-12",
      inForm: true,
    },
    {
      category: "second",
      name: "confirmPassword",
      inputType: "password",
      label: "Confirm Password",
      placeholder: "Confirm Password",
      required: true,
      type: "password",
      wrapperClass: "form-outline mb-1 col-12",
    },
    {
      category: "first",
      name: "continue",
      inputType: "button",
      buttonType: "submit",
      children: "Continue",
      wrapperClass: "btn-wrapper col-12",
    },
    {
      category: "second",
      name: "resetPassword",
      inputType: "button",
      buttonType: "submit",
      children: "Reset Password",
      wrapperClass: "mt-2 btn-wrapper col-6 mx-auto text-center",
    },
  ];


  const emailSubmit = () => {
    dispatch({
      type: createRecord.type,
      payload: {
        email: user_email,
        url: "/User/ForgotPassword",
        message: "Reset Password Email Sent Sucessfully",
        isFormatted: true,
      },

    });
    // navigate('/reset')

  };


  const passwordSubmit = () => {

    //  if(values?.password.value !== values?.confirmPassword?.value)
    //   {
    dispatch({
      type: createRecord.type,
      payload: {
        ...forgotPasswordData,
        url: `/User/${user_id}/ResetPassword`,
        message: "Password Reset Sucessful",
        isFormatted: true,
      },
    });
    // }
    // else{
    //  toast.error("Password and Confirm Password does not match");
    // }

    !Object.keys(error).length == 0 && navigate("/login");
  };

  const meta = {
    headerTitle: "Forgot Password",
    headerDescription: "Enter your email to reset your password.",
    footerText: "Want to login instead?",
    footerLink: "/login",
    footerLinkText: "Login",

  };
  return (

    <>
      <LoginTemplate meta={meta} forgetpass>
        <div className=" col-12">
          {pathname == "/forget" ? (
            <>
              <Form
                formData={getFieldsByCategory(resetForm, "first")}
                onSubmit={emailSubmit}
              />
            </>
          ) : (
            <div className=" col-12">

              <Form
                formData={getFieldsByCategory(resetForm, "second")}
                onSubmit={passwordSubmit}
              />
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-secondary col-sm-2 mt-3"
                  onClick={() => navigate(-1)}
                >
                  Go Back
                </button>
              </div>


            </div>
          )}


        </div>
      </LoginTemplate>
    </>

  );
};

export default ForgetPassword;
